.recipel-title {
  font-size: 0.4rem;
  color: #0B1523;
  background: #FFFFFF;
  font-weight: bold;
  padding: 0.48rem 0 0.133333rem 0.4rem;
}

.add-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  color: #F34848;
  height: 2.133333rem;
}

.user-phone {
  line-height: 1.066667rem;
  padding: 0 0 0.373333rem 0.373333rem;
  background: #FFFFFF;
  font-size: 0.346667rem;
}

.recipel-item {
  display: flex;
  flex-direction: row;
}

.recipel-item :nth-child(1) {
  width: 1.786667rem;
}

.baseill-info {
  width: 100%;
  height: 3.333333rem;
  background: #FFFFFF;
  padding: 0.373333rem;
}

.baseill-info > div {
  display: flex;
  flex-direction: row;
  margin-top: 0.4rem;
}

.online-recipel {
  width: 100%;
  background: #FFFFFF;
}

.recipel-content1 p:nth-child(1) {
  margin: 0.666667rem 0 0.266667rem 0.426667rem;
}

.recipel-content1 p:not(:nth-child(1)) {
  margin-left: 0.4rem;
  line-height: 0.666667rem;
}

.recipel-content1 input {
  border: none;
  margin-left: 0.266667rem;
}

.recipel-content1 .upload-img {
  margin: 0.133333rem 0 0.133333rem 0.426667rem;
  width: 2.48rem;
  height: 2.48rem;
}

.recipel-content2 {
  padding: 0.4rem 0.533333rem 0 0.426667rem;
}

.recipel-content2 p {
  line-height: 0.666667rem;
}

.recipel-content2 .upload-img {
  margin: 0.266667rem 0.533333rem 2.666667rem 0;
  width: 2.48rem;
  height: 2.48rem;
}