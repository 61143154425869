.flexr1 {
  display: flex;
  justify-content: space-between;
  height: 0.813333rem;
  line-height: 0.813333rem;
  opacity: 1;
  background-color: #fff;
  margin-top: 0.133333rem;
  margin-bottom: 0.053333rem;
  font-weight: 600;
  border-radius: 0.2rem 0.2rem 0 0;
}

.flexr1 > div {
  width: 50%;
  text-align: center;
}

.flexr1 .adress-checked1 {
  border-radius: 0.2rem 0 0 0;
  background-color: #1F87FF;
  color: #fff;
}

.flexr1 .adress-checked2 {
  border-radius: 0 0.2rem 0 0;
  background-color: #1F87FF;
  color: #fff;
}

.set-adress {
  padding: 0.213333rem 0.4rem;
  position: relative;
  background: #FFFFFF;
  align-items: center;
  justify-content: space-between;
}

.set-adress .no-adress-outer {
  display: flex;
  justify-content: space-between;
}

.set-adress .no-adress-outer .no-adress {
  color: #666666;
  font-size: 0.346667rem;
}

.set-adress .no-adress-outer > a {
  width: 0.666667rem;
  margin-right: 0.266667rem;
  margin-top: 0.266667rem;
}

.set-adress .no-adress-outer > a > img {
  width: 0.533333rem;
}

.set-adress .no-adress-own-news {
  display: flex;
}

.set-adress .no-adress-own-news > p {
  display: flex;
  flex-direction: column;
  margin-right: 0.4rem;
  margin-top: 0.133333rem;
  padding-bottom: 0.266667rem;
}

.set-adress .no-adress-own-news > p > span {
  display: flex;
  margin-top: 0.266667rem;
  color: #666666;
}

.set-adress .no-adress-own-news > p > span > input {
  border: none;
  margin-right: 0.133333rem;
  color: #666666;
}

.set-adress .no-adress-own-news > p > span > img {
  width: 0.373333rem;
}

.set-adress .adress-switch {
  height: 0.613333rem;
  position: relative;
}

.set-adress .adress-switch > div {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.set-adress .adress-switch > div > div {
  color: white;
  font-size: 0.346667rem;
}

.set-adress .adress-switch > div > div.adress-checked {
  color: #1F87FF;
}

.set-adress .adress-switch .am-switch .checkbox {
  border-radius: 0.306667rem;
  background-color: #1F87FF;
}

.set-adress .adress-switch .am-switch .checkbox::after {
  border-radius: 0.306667rem;
}

.set-adress .adress-area {
  font-size: 0.346667rem;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.set-adress .adress-area > div {
  width: 0.8rem;
  height: 0.506667rem;
  font-size: 0.266667rem;
  line-height: 0.4rem;
  color: #ffffff;
  background: #F34848;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  margin-right: 0.133333rem;
}

.set-adress .adress-street {
  color: #414141;
  font-weight: bold;
  font-size: 0.453333rem;
  width: 80%;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.set-adress .adress-edit {
  position: absolute;
  top: 50%;
  right: 0.613333rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.set-adress > span {
  font-size: 0.32rem;
}

.set-adress .adress-person-info {
  font-size: 0.133333rem;
  color: #6B6C73;
  margin-top: 0.266667rem;
}

.fillorder-warn {
  width: 100%;
  position: fixed;
  bottom: 1.306667rem;
  height: 0.88rem;
  background: #F4E3E5;
  color: #F34848;
  line-height: 0.88rem;
  font-size: 0.346667rem;
  padding-left: 0.426667rem;
}

.tijiao-bottom {
  width: 100vw;
  position: absolute;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
}

.tijiao-bottom > p {
  line-height: 1.333333rem;
  margin-left: 0.4rem;
  font-size: 0.426667rem;
}

.tijiao-bottom > button {
  height: 1.333333rem;
  width: 3.333333rem;
  border: none;
  font-size: 0.426667rem;
  background-color: #aaa;
  color: #fff;
}

.fill-order > p {
  padding: 0.4rem 0 0 0.4rem;
  font-size: 0.346667rem;
  color: #666666;
}

.fill-order .fill-order-med {
  flex-direction: row;
  width: 100%;
}

.fill-order .fill-order-med .cart-med {
  margin: 0.4rem;
  justify-content: space-between;
}

.fill-order .fill-order-med .cart-med > div {
  height: 2rem;
}

.fill-order .fill-order-med .cart-med .fill-order-img {
  width: 2rem;
  max-height: 2rem;
  margin-right: 0.266667rem;
}

.fill-order .fill-order-med .cart-med .cart-med-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fill-order .fill-order-med .cart-med .fill-order-name {
  font-size: 0.373333rem;
  color: #666666;
}

.fill-order .fill-order-med .cart-med .fill-order-name1 {
  font-size: 0.346667rem;
  font-family: SourceHanSansCN-Regular;
  line-height: 0.666667rem;
  color: #333333;
}

.fill-order .fill-order-med .cart-med .fill-order-name1 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fill-order .fill-order-med .cart-med .fill-order-name1 > span {
  color: #999999;
}

.fill-order .fill-order-med .cart-med .fill-order-price {
  font-size: 0.32rem;
  color: #F34848;
}

.fill-order .fill-order-med .cart-med .money {
  font-size: 0.346667rem;
  font-family: ArialMT;
  color: #333333;
  line-height: 0.933333rem;
}

.fill-order .fill-order-med .cart-med .money .origin-price {
  font-size: 0.346667rem;
  text-decoration: line-through;
  color: #999;
}

.fill-order .fill-order-med .cart-med .count {
  font-size: 0.346667rem;
  font-family: ArialMT;
  color: #999999;
  text-align: right;
}

.fill-screen {
  bottom: 2.213333rem!important;
}

.fill-screen .am-list-line .am-list-content,
.fill-screen .am-list-line .am-list-extra {
  color: #666666 !important;
  font-size: 0.346667rem!important;
}

.fill-screen .price-red .am-list-extra {
  color: #F34848 !important;
}

.pifa-pay {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.4rem;
  color: #666;
  font-size: 0.4rem;
}

.pifa-pay > select {
  color: #666;
  border: none;
}

.dgzz {
  height: 2.933333rem;
  padding: 0.4rem;
  border-top: 0.026667rem solid #EEF1F5;
  border-bottom: 0.266667rem solid #EEF1F5;
}

.dgzz > h2 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.533333rem;
  color: #333333;
}

.dgzz > p {
  font-family: MicrosoftYaHei;
  line-height: 0.533333rem;
  color: #333333;
}

.all-address-outer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 78vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.all-address-outer .all-address1 {
  position: fixed;
  width: 10rem;
  height: 5.333333rem;
  overflow: scroll;
  z-index: 100;
  background-color: #fff;
  bottom: 2.133333rem;
  padding-top: 0.266667rem;
  border-top-left-radius: 0.266667rem;
  border-top-right-radius: 0.266667rem;
}

.all-address-outer .all-address1 .all-address-in {
  margin-bottom: 0.266667rem;
  display: flex;
  padding: 0.133333rem 0.4rem;
  height: 1.333333rem;
  line-height: 0.533333rem;
}

.all-address-outer .all-address1 .all-address-in > input {
  margin-right: 0.266667rem;
  margin-top: 0.266667rem;
}

.all-address-outer .all-address1 .all-address-in > input .all-address-phone .address {
  line-height: 0.666667rem;
}

.pifa-fapiao {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 1.066667rem;
  line-height: 1.066667rem;
  padding: 0 0.4rem;
  color: #666;
  border-bottom: 0.013333rem solid #ddd;
}

.pifa-fapiao > select {
  color: #666;
  border: none;
}

.am-list-item .am-input-label {
  color: #666;
  font-size: 0.346667rem;
  margin-left: 0;
  margin-right: 0.066667rem;
}

.am-list-item .am-input-control input {
  font-size: 0.226667rem;
  flex: 1 1;
}

.outer-kc {
  position: absolute;
  width: 6.666667rem;
  background-color: #fff;
  border: 0.026667rem solid #6B6C73;
  padding: 0.4rem;
  margin-left: 50%;
  margin-top: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  border-radius: 0.266667rem;
}

.outer-kc > p {
  line-height: 0.8rem;
}

.outer-kc > button {
  padding: 0.133333rem 0.4rem;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.266667rem;
  background-color: #F34848;
  border: none;
  border-radius: 0.066667rem;
  color: #fff;
}

.am-modal-alert-content,
.am-modal-propmt-conten {
  font-size: 0.453333rem !important;
  margin-bottom: -0.4rem;
}

.del-address-outer {
  position: absolute;
  z-index: 9999;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.del-address-outer .del-address {
  width: 6.666667rem;
  height: 3.466667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address-outer .del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address-outer .del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address-outer .del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address-outer .del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.add-taitou-outer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.add-taitou-outer .add-taitou {
  position: fixed;
  z-index: 9999;
  width: 8rem;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.4rem;
}

.add-taitou-outer .add-taitou .add-taitou-name {
  text-align: center;
}

.add-taitou-outer .add-taitou .add-taitou-all {
  display: flex;
  justify-content: space-between;
  line-height: 0.8rem;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #414141;
}

.add-taitou-outer .add-taitou .add-taitou-all > input {
  margin-right: 0.4rem;
  border: none;
  width: 2.666667rem;
  text-align: right;
}

.add-taitou-outer .add-taitou .add-taitou-all .one {
  margin-right: 0.4rem;
}

.add-taitou-outer .add-taitou .add-taitou-all1 {
  margin-top: 0.133333rem;
  margin-bottom: 0.4rem;
}

.add-taitou-outer .add-taitou .fapiao-save {
  background-color: #1F87FF;
  text-align: center;
  width: 3.146667rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
  color: #fff;
  background: #1F87FF;
  border-radius: 0.066667rem;
  margin-left: 28%;
}

.P-body2-title {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.4rem;
  padding-bottom: 0;
}

.P-body2-title > p {
  font-size: 0.4rem;
}

.P-body2-title > p > span {
  font-size: 0.32rem;
  color: #666666;
  margin-left: 0.266667rem;
}

.P-body2-title > img {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.133333rem;
}

.own-ready {
  display: flex;
  justify-content: space-between;
  padding: 0.266667rem 0.4rem;
  color: #666666;
}

.own-ready > input {
  margin-right: 0.133333rem;
}

.write-jifen {
  height: 1.333333rem;
  line-height: 1.333333rem;
  text-align: center;
}

.write-jifen > input {
  width: 2.666667rem;
  text-align: center;
}

.write-jifen1 > p {
  height: 0.8rem;
  line-height: 0.8rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1.066667rem;
}

.write-jifen1 > p > input {
  margin-right: 0.266667rem;
}

.can-use {
  text-align: center;
  color: #0076FF;
  margin-top: 0.266667rem;
}

.can-use1 {
  text-align: center;
  color: #999;
  margin-top: 0.266667rem;
  padding: 0 0.4rem;
}

.coupon-all {
  padding: 0.266667rem 0.4rem;
  position: absolute;
  overflow: scroll;
}

.coupon-all .coupons-title {
  width: 92vw;
  display: flex;
  justify-content: space-between;
}

.coupon-all .coupons-title > img {
  width: 0.4rem;
  height: 0.4rem;
  margin-top: 0.266667rem;
}

.coupon-all .coupons-title > p {
  font-size: 0.373333rem;
  font-family: SourceHanSansCN-Bold;
  line-height: 1.066667rem;
  color: #333333;
}

.coupon-all .coupons-title > p > span {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 1.066667rem;
  color: #666666;
  margin-left: 0.133333rem;
}

.coupon-all .coupons-body {
  margin-bottom: 0.4rem;
  width: 92vw;
}

.coupon-all .coupons-body .coupons-body-top {
  display: flex;
  justify-content: space-between;
}

.coupon-all .coupons-body .coupons-body-top > img {
  width: 1.333333rem;
  height: 1.333333rem;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-center > h2 {
  font-size: 0.373333rem;
  font-family: SourceHanSansCN-Bold;
  line-height: 0.933333rem;
  color: #333333;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-center > p {
  font-size: 0.32rem;
  font-family: SourceHanSansCN-Regular;
  color: #666666;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-right {
  width: 20%;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-right .coupons-body-minus {
  color: #FF5050;
  text-align: center;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-right .coupons-body-minus > span {
  font-size: 0.64rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.933333rem;
}

.coupon-all .coupons-body .coupons-body-top .coupons-body-right .coupons-body-full {
  color: #FF5050;
  text-align: center;
  font-size: 0.32rem;
  font-family: SourceHanSansCN-Regular;
}

.coupon-all .coupons-body .coupons-body-top > input {
  margin-top: 0.533333rem;
}

.coupon-all .coupons-body .coupons-body-bottom {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 0.266667rem;
}

.fapiao-all {
  padding: 0.266667rem 0.4rem;
  position: absolute;
  overflow: scroll;
}

.fapiao-all .fapiao-title {
  width: 92vw;
  display: flex;
  justify-content: space-between;
}

.fapiao-all .fapiao-title > img {
  width: 0.4rem;
  height: 0.4rem;
  margin-top: 0.266667rem;
}

.fapiao-all .fapiao-title > p {
  font-size: 0.373333rem;
  font-family: SourceHanSansCN-Bold;
  line-height: 1.066667rem;
  color: #333333;
}

.fapiao-all .no-fapiao {
  margin-top: 0.266667rem;
  display: flex;
}

.fapiao-all .no-fapiao > input {
  margin-right: 0.266667rem;
}

.fapiao-all .fapiao-cu {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.8rem;
  color: #666666;
  margin-bottom: 0.133333rem;
}

.fapiao-all .fapiao-blue {
  background: #D6E9FF;
  border: 0.026667rem solid #1F87FF;
  padding: 0.04rem 0.266667rem;
  opacity: 1;
  border-radius: 0.266667rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1F87FF;
}

.fapiao-all .fapiao-shuoming {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.4rem;
  color: #C2C4CB;
  margin: 0.266667rem 0;
}

.fapiao-all .fapiao-head {
  margin: 0.266667rem 0;
  display: flex;
  justify-content: space-between;
}

.fapiao-all .fapiao-head > p {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.4rem;
  color: #666666;
}

.fapiao-all .fapiao-head > p > span {
  color: red;
  margin-right: 0.066667rem;
}

.fapiao-all .fapiao-head > input {
  border: none;
}

.fapiao-all .fapiao-save {
  width: 100%;
  height: 1.093333rem;
  background: #1F87FF;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 1.093333rem;
  color: #FFFFFF;
  text-align: center;
}