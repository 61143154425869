.preferentialPromotion-top {
  width: 100%;
  height: 10vh;
  text-align: center;
  line-height: 10vh;
  font-size: 0.453333rem;
  position: fixed;
  top: 0;
  border-bottom: 0.013333rem solid #EEF1F5;
  background-color: white;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.preferentialPromotion-top img {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  left: 0.266667rem;
  margin: auto;
  top: 0;
  bottom: 0;
}

.scroller {
  position: fixed;
  top: 10vh;
  bottom: 0;
  width: 100vw;
  height: 90vh;
  background-color: #fff;
  padding: 0.266667rem 4vw;
  overflow: scroll;
}

.scroller .item {
  width: 100%;
  border-bottom: 0.013333rem solid #EDEDEE;
}

.scroller .item .preferentialPromotion-outer-bottom {
  padding-bottom: 0.266667rem;
}

.scroller .item .preferentialPromotion-outer-bottom .preferentialPromotion-outer-bottom-top {
  width: 100%;
  margin-top: 0.266667rem;
  display: flex;
  justify-content: space-between;
}

.scroller .item .preferentialPromotion-outer-bottom .preferentialPromotion-outer-bottom-top > span:first-child {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.4rem;
}

.scroller .item .preferentialPromotion-outer-bottom .preferentialPromotion-outer-bottom-top > span:last-child {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

.scroller .item .preferentialPromotion-outer-bottom > p:last-child {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.4rem;
  color: #999999;
}