.setbox {
  width: 100%;
  height: 100vh;
  position: relative;
}

.setbox .box-top {
  width: 100%;
  height: 1.613333rem;
  text-align: center;
  line-height: 1.613333rem;
  font-size: 0.426667rem;
  position: relative;
  border-bottom: 0.026667rem solid #EEF1F5;
  background-color: white;
}

.setbox .box-top img {
  position: absolute;
  width: 0.533333rem;
  height: 0.533333rem;
  left: 0.266667rem;
  margin: auto;
  top: 0;
  bottom: 0;
}

.setbox .setprescribed {
  width: 9.466667rem;
  border-radius: 0.066667rem;
  padding: 0.266667rem 0.4rem;
  padding-bottom: 0;
  background-color: #fff;
  margin-top: 0.266667rem;
  margin-left: 0.266667rem;
  margin-right: 0.266667rem;
}

.setbox .setprescribed .info-1 {
  width: 100%;
  background-color: white;
  padding: 0.48rem 0.16rem;
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
}

.setbox .setprescribed .info-1 .container-box {
  width: 100%;
  background-color: white;
  border-radius: 0.133333rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 0.066667rem;
}

.setbox .setprescribed .info-1 .container-box img {
  width: 1.066667rem;
  height: 1.066667rem;
  margin-right: 0.133333rem;
}

.setbox .setprescribed .info-1 .container-box .box-text {
  width: 8rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-name {
  margin-bottom: 0.226667rem;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-name span:first-child {
  font-size: 0.4rem;
  font-weight: 600;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-name span {
  font-size: 0.293333rem;
  margin-right: 0.266667rem;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title {
  margin-bottom: 0.226667rem;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .sj,
.setbox .setprescribed .info-1 .container-box .box-text .box-title .yy {
  margin-right: 0.266667rem;
  font-size: 0.293333rem;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .sj {
  width: 0.6rem;
  height: 0.373333rem;
  background-color: #1F87FF;
  line-height: 0.373333rem;
  font-size: 0.24rem;
  padding: 0.066667rem;
  border-radius: 0.066667rem;
  color: white;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .guanzhu {
  width: 1.6rem;
  height: 0.533333rem;
  line-height: 0.533333rem;
  background: #E8F3FF;
  opacity: 1;
  border-radius: 0.386667rem;
  position: absolute;
  right: 0.8rem;
  text-align: center;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .guanzhu > img {
  width: 0.293333rem;
  height: 0.293333rem;
  margin-left: 0.066667rem;
  margin-top: 0.106667rem;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .guanzhu > span {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1F87FF;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .guanzhu1 {
  width: 1.6rem;
  height: 0.533333rem;
  line-height: 0.533333rem;
  background: #F1F1F1;
  opacity: 1;
  border-radius: 0.386667rem;
  position: absolute;
  right: 0.8rem;
  text-align: center;
}

.setbox .setprescribed .info-1 .container-box .box-text .box-title .guanzhu1 > span {
  font-size: 0.293333rem;
  background: #F1F1F1;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

.setbox .setprescribed .info-1 .box-evaluate {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0.266667rem;
}

.setbox .setprescribed .info-1 .box-evaluate div {
  margin-right: 0.266667rem;
}

.setbox .setprescribed .info-1 .box-evaluate div img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.setbox .setprescribed .info-1 .box-introduce {
  margin-bottom: 0.066667rem;
  line-height: 0.533333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.setbox .setprescribed .info-1 .box-introduce span {
  color: #1F87FF;
}