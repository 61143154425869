.hospital-listtab {
  width: 100%;
  height: 1.2rem;
  color: #666666;
  background: #FFFFFF;
  font-size: 0.373333rem;
  font-weight: bold;
  border-bottom: solid 0.013333rem #EDEDEE;
}

.hospital-listtab > div {
  margin: 0 1.013333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hospital-item {
  width: 100%;
  height: 3.28rem;
  background: #FFFFFF;
  padding: 0.4rem 0.453333rem 0 0.4rem;
  color: #666666;
  font-size: 0.32rem;
  border-bottom: solid 0.013333rem #EDEDEE;
  position: relative;
}

.hospital-item .hospital-item-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.266667rem;
}

.hospital-item .hospital-item-title > span {
  color: #666666;
  font-weight: bold;
  font-size: 0.4rem;
  margin-right: 0.293333rem;
}

.hospital-item .hospital-item-detail {
  height: 0.533333rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hospital-item .hospital-item-detail > i {
  color: #1F87FF;
  font-size: 0.32rem;
}

.hospital-item .hospital-item-detail > span {
  color: #666666;
  font-size: 0.32rem;
  margin-left: 0.133333rem;
}

.hospital-item .hospital-item-heal {
  color: #C2C5CB;
  font-size: 0.293333rem;
  line-height: 1.04rem;
}

.hospital-item .hospital-item-heal > span {
  color: #1F87FF;
}

.hospital-item .fllow-hospital {
  position: absolute;
  top: 50%;
  right: 0.426667rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hospital-doctorlist {
  margin-top: 0.133333rem;
  background: #FFFFFF;
}