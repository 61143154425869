.col-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.activity-title {
  width: 100%;
  height: 0.8rem;
  font-size: 0.48rem;
  background-color: #fff;
}

.shopcart-back-page {
  z-index: 9;
}

.shopcart-back-page > i {
  font-size: 0.533333rem;
}

.viewrecord-med-detail > div {
  width: 70vw;
  margin-bottom: 0.2rem;
}

.colectitem {
  width: 100%;
  height: 2.933333rem;
  background-color: #FFFFFF;
  padding: 0.32rem 0.4rem;
  display: flex;
  flex-direction: row;
}

.colectitem .colectitem-img > img {
  width: 2.266667rem;
}

.colectitem .colectitem-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.32rem;
  width: 100%;
  margin-left: 0.533333rem;
}

.colectitem .colectitem-text > div > h1 {
  font-size: 0.346667rem;
}

.colectitem .colectitem-text > div > span {
  font-size: 0.24rem;
}

.colectitem .colectitem-text > span {
  font-size: 0.346667rem;
}

.contain-item {
  width: 100%;
  height: 100%;
  background-color: #EEF1F5;
}

.itm {
  width: 100%;
  height: 0.8rem;
  background-color: blue;
}

.no-more {
  text-align: center;
  color: #C2C5CB;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}