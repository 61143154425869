.color-block2 {
  position: absolute;
  height: 3.253333rem;
  width: 100%;
  background: linear-gradient(180deg, #FF5555 0%, #FF5555 100%);
  border-radius: 0;
  top: 0;
  left: 0;
  z-index: -1;
}

.screen-home-top {
  width: 100%;
  height: 6.666667rem;
  padding: 0.533333rem;
  box-sizing: border-box;
  background-color: #FF5555;
}

.screen-home-top .top-swiper {
  width: 100%;
  height: 2.746667rem;
}

.screen-home-top .top-search {
  width: 100%;
  margin-bottom: 0.4rem;
}

.screen-home-top .top-search input {
  width: 100%;
  height: 1.066667rem;
  padding-left: 1.066667rem;
  border-radius: 0.533333rem;
  border: none;
  background-image: url("../../static/img/home/search.png");
  background-size: 0.466667rem;
  background-repeat: no-repeat;
  background-position: 0.4rem center;
}

.screen-home-top .screen-home-top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.screen-home-top .screen-home-top-title h2 {
  width: 30%;
  font-size: 0.506667rem;
  color: #FFFFFF;
}

.screen-home-top .screen-home-top-title .top-title-box {
  width: 70%;
  text-align: right;
}

.screen-home-top .screen-home-top-title .top-title-box img {
  width: 0.586667rem;
  height: 0.586667rem;
  margin-left: 0.4rem;
}

.single-color-block {
  position: absolute;
  height: 2.066667rem;
  width: 100%;
  background: #FF5555;
  top: 0;
  left: 0;
  z-index: -1;
}

.single-title {
  padding: 0.533333rem 0 0 0.533333rem;
  color: white;
}

.single-title > span:first-child {
  font-weight: bold;
  font-size: 0.506667rem;
}

.single-title > span:last-child {
  margin-left: 0.266667rem;
  font-size: 0.346667rem;
}

.single-search {
  display: flex;
  align-items: center;
  width: 8.8rem;
  height: 1.08rem;
  margin: 0.4rem auto;
  padding-left: 0.64rem;
  font-size: 0.373333rem;
  color: #BFBFBF;
  background: #FFFFFF;
  box-shadow: 0 0.04rem 0.08rem rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 0.666667rem;
}

.single-search > i {
  font-size: 0.533333rem;
}

.home-downpart-headernav {
  width: 100%;
  padding-bottom: 0.533333rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-downpart-headernav .home-downpart-whiteback {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  background-color: #FFFFFF;
}

.home-downpart-headernav .main-use {
  height: 1.866667rem;
  width: 100%;
  padding: 0 0.4rem;
}

.home-downpart-headernav .main-use .function {
  width: 4.4rem;
  height: 100%;
  border-radius: 0.266667rem;
  background-color: #FFFFFF;
  box-shadow: 0 0.106667rem 0.16rem rgba(196, 206, 230, 0.3);
}

.home-downpart-headernav .main-use .function img {
  width: 1.333333rem;
  margin-right: 0.266667rem;
}

.home-downpart-headernav .main-use .function div :first-child {
  font-size: 0.48rem !important;
  font-weight: bold;
  line-height: 0.8rem;
}

.home-downpart-headernav .main-use .function div :last-child {
  font-size: 0.346667rem !important;
}

.home-downpart-headernav .other-use2 {
  width: 100%;
  margin-top: -0.533333rem;
  background-color: #FFFFFF;
  border-top-left-radius: 0.533333rem;
  border-top-right-radius: 0.533333rem;
  padding: 0 0.266667rem;
}

.home-downpart-headernav .other-use2 ul {
  height: 1.333333rem;
  padding-top: 0.533333rem;
}

.home-downpart-headernav .other-use2 ul li {
  float: left;
  text-align: center;
  width: 25%;
  height: 2.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-downpart-headernav .other-use2 ul li img {
  width: 1.173333rem;
  margin-bottom: 0.213333rem;
}

.home-downpart-headernav .other-use2 ul li span {
  font-size: 0.373333rem;
}

.home-downpart-headernav .swiper-part {
  width: 100%;
  height: 2.506667rem;
  background-color: #fff;
  margin: 0.266667rem 0 0.453333rem 0;
}

.home-downpart-headernav .swiper-part .swiper-img {
  width: 9.333333rem;
  height: 2.506667rem;
  overflow: hidden;
}

.home-downpart-headernav .flex-activity {
  padding: 0 0.4rem;
}

.home-downpart-headernav .flex-activity .flex-actie-row {
  text-align: center;
}

.home-downpart-headernav .flex-activity .flex-actie-row img {
  width: 100%;
}

.home-downpart-headernav .area-part {
  width: 100%;
  height: 1.066667rem;
  padding: 0 0.2rem;
}

.home-downpart-headernav .area-part > div {
  width: 2.253333rem;
  height: 100%;
  border-radius: 0.066667rem;
  color: #FFFFFF;
  padding: 0 0.133333rem 0 0.2rem;
}

.home-downpart-headernav .area-part .area-part-cardword :first-child {
  font-size: 0.173333rem;
}

.home-downpart-headernav .area-part .area-part-cardword :last-child {
  font-size: 0.24rem;
  line-height: 0.4rem;
}

.home-downpart-headernav .area-part .area-part-card1 {
  background-size: 100%;
}

.home-downpart-headernav .area-part .area-part-card1 > img {
  width: 0.533333rem;
}

.home-downpart-headernav .area-part .area-part-card2 {
  background-size: 100%;
}

.home-downpart-headernav .area-part .area-part-card2 > img {
  width: 0.746667rem;
}

.home-downpart-headernav .timelimit-part {
  width: 100%;
  margin: 0.533333rem 0 0 0;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line {
  width: 100%;
  padding: 0 0.266667rem 0 0.4rem;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title {
  align-items: center;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timelimit-seckill {
  width: 1.706667rem;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timelimit-seckill > img {
  width: 100%;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timimg {
  margin-left: 0.4rem;
  align-items: center;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timimg > div {
  line-height: 0.373333rem;
  text-align: center;
  font-size: 0.32rem;
  border: 0.026667rem solid #2086FE;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timimg > div:first-child {
  color: white;
  background-color: #2086FE;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-title .timimg > div:last-child {
  width: 1.466667rem;
  color: #2086FE;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-more span {
  font-size: 0.32rem;
  color: #353A42;
}

.home-downpart-headernav .timelimit-part .timelimit-title-line .timelimit-more i {
  font-size: 0.32rem;
}

.home-downpart-headernav .timelimit-part .med-part {
  width: 100%;
}

.home-downpart-headernav .timelimit-part .med-part .am-tabs > .am-tabs-tab-bar-wrap * {
  font-size: 0.373333rem;
}

.home-downpart-headernav .single-all-goods {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
  font-size: 0.346667rem;
  color: #333333;
}

.shop-around {
  width: 100%;
  margin-top: 0.4rem;
  background-color: #FFFFFF;
}

.shop-around .shop-around-title {
  font-size: 0.24rem;
  margin: 0.066667rem 0 0.133333rem 0;
}

.cost-dagstore {
  width: 100%;
  margin-top: 0.266667rem;
  background-color: #FFFFFF;
}

.cost-dagstore .dragstore-title-line {
  width: 100%;
  padding: 0.426667rem 0.266667rem 0.426667rem 0.373333rem;
}

.cost-dagstore .dragstore-title-line .dragstore-title {
  align-items: center;
}

.cost-dagstore .dragstore-title-line .dragstore-title > span {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.8rem;
  color: #0b231f;
}

.cost-dagstore .dragstore-title-line .dragstore-more span {
  font-size: 0.32rem;
  color: #353A42;
}

.cost-dagstore .dragstore-title-line .dragstore-more i {
  font-size: 0.32rem;
}

.single-recommend-med {
  padding-top: 0.133333rem;
  margin-top: -0.133333rem;
  background-color: white;
}

.single-recommend-med .single-med-list {
  margin-top: 0.4rem;
  padding-bottom: 1.2rem;
}

.single-recommend-med .single-med-list .single-med-area {
  width: 9.2rem;
  margin: 0 auto;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem {
  width: 100%;
  margin-bottom: 0.666667rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-img {
  width: 3.106667rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-img img {
  width: 100%;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info {
  justify-content: space-between;
  flex: 1 1;
  margin-left: 0.266667rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-name {
  font-size: 0.373333rem;
  font-weight: bold;
  color: #353535;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-info-company,
.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-info-sold {
  color: #666666;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-sale-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8.666667rem;
  margin: 0.4rem auto;
  padding: 0.266667rem;
  font-size: 0.32rem;
  color: #666666;
  background-color: #FFECEC;
  border-radius: 0.066667rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-sale-time .sale-delivery-time div:first-child {
  margin-bottom: 0.266667rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-sale-time .sale-delivery-time div span {
  color: #D23333;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-sale-time .single-sold {
  font-weight: bold;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-sale-time .single-sold span {
  color: #D23333;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row {
  font-weight: bold;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-price-fontsize30 {
  font-size: 0.4rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-price-fontsize39 {
  font-size: 0.52rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-price {
  color: #F03737;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-price span {
  font-size: 0.32rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-spike {
  margin-left: 0.226667rem;
  font-size: 0.36rem;
  color: #C9C9C9;
  text-decoration: line-through;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-meditem-row .single-spike span {
  font-size: 0.2rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-add-car .am-button {
  width: 2.133333rem;
  height: 0.706667rem;
  line-height: 0.706667rem;
  font-size: 0.32rem;
}

.single-recommend-med .single-med-list .single-med-area .single-meditem .single-info .single-med-price .single-add-car .am-button-warning {
  background-color: #E42626;
}

.recommend-med .recommend-med-title > div::after {
  display: none;
}

.recommend-med {
  width: 100%;
}

.recommend-med .recommend-med-title {
  display: flex;
  width: 100%;
  height: 1.546667rem;
  justify-content: center;
}

.recommend-med .recommend-med-title .top-med-title {
  background-color: #FF5555;
  width: 9.2rem;
  background-image: url("../../static/img/home/fire.png");
  background-repeat: no-repeat;
  background-size: 0.24rem 0.32rem;
  background-position: 0.266667rem center;
  box-sizing: border-box;
  padding-left: 0.666667rem;
  height: 0.853333rem;
  line-height: 0.853333rem;
  color: #FFFFFF;
  font-size: 0.4rem;
}

.recommend-med .recommend-med-title .top-med-title ::after {
  display: none;
}

.recommend-med .recommend-med-title > div {
  position: relative;
  color: #0B231F;
  font-weight: bold;
  font-size: 0.4rem;
}

.recommend-med .recommend-med-title > div ::after {
  display: none;
}

.recommend-med .single-hot-sale {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 9.2rem;
  height: 0.853333rem;
  font-size: 0.373333rem;
  color: white;
  background: linear-gradient(180deg, #FF5959 0%, #FF6D57 53%, #FF7E29 100%);
  border-radius: 0.066667rem;
}

.recommend-med .single-hot-sale > div:first-child {
  margin-left: 0.213333rem;
  margin-right: 0.133333rem;
  width: 0.253333rem;
}

.recommend-med .single-hot-sale > div:first-child img {
  width: 100%;
}

.bgbox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(196, 206, 230, 0.8);
}

.bgbox .bgbox-main {
  box-sizing: border-box;
  height: 8rem;
  width: 8rem;
  border-radius: 0.266667rem;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
}

.bgbox .bgbox-main .ab {
  position: absolute;
  right: 0.666667rem;
  top: 4.666667rem;
  font-size: 0.4rem;
  color: #0079FF;
}

.bgbox .bgbox-main .main-top {
  height: 1.12rem;
  width: 100%;
  padding: 0.32rem;
  background-color: #0079FF;
  border-top-left-radius: 0.266667rem;
  border-top-right-radius: 0.266667rem;
  text-align: center;
}

.bgbox .bgbox-main .main-top p {
  color: white;
  font-size: 0.346667rem;
}

.bgbox .bgbox-main .title {
  font-size: 0.293333rem;
  color: #999999;
  box-sizing: border-box;
  padding-left: 0.8rem;
  margin-top: 0.266667rem;
  background-image: url("../../static/img/home/success.png");
  background-repeat: no-repeat;
  background-size: 0.306667rem 0.306667rem;
  background-position: 0.4rem;
}

.bgbox .bgbox-main .main-text {
  line-height: 0.8rem;
  margin-top: 0.266667rem;
  width: 100%;
  text-align: center;
}

.bgbox .bgbox-main .main-text p {
  text-align: left;
  font-size: 0.346667rem;
  box-sizing: border-box;
  padding-left: 0.4rem;
}

.bgbox .bgbox-main .main-text input {
  border: none;
  border-bottom: 0.026667rem solid gray;
}

.bgbox .bgbox-main .main-text span {
  margin-right: 0.133333rem;
}

.bgbox .bgbox-main .main-bottom {
  padding-top: 0.68rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.bgbox .bgbox-main .main-bottom button {
  width: 50%;
  height: 1.12rem;
  box-sizing: border-box;
  display: block;
  font-size: 0.426667rem;
  border: none;
}

.bgbox .bgbox-main .main-bottom button:last-child {
  color: white;
  border-top: 0.026667rem solid #EEF1F5;
  border-left: 0.026667rem solid #EEF1F5;
  border-bottom-right-radius: 0.266667rem;
  background-color: #1F87FF;
}

.bgbox .bgbox-main .main-bottom button:first-child {
  background-color: white;
  border-top: 0.026667rem solid #EEF1F5;
  border-bottom-left-radius: 0.266667rem;
}