.stbox {
  width: 400pt;
  height: 720pt;
  box-sizing: border-box;
  border: 0.04rem solid black;
  margin: 0 auto;
}

.stbox > div {
  border-bottom: 0.013333rem solid black;
  box-sizing: border-box;
}

.stbox .stbox-top {
  height: 36pt;
  padding-top: 3pt;
  padding-left: 0.133333rem;
}

.stbox .stbox-top img {
  height: 30pt;
}

.stbox .stbox-num {
  height: 60pt;
  font-size: 50pt;
  text-align: center;
}

.stbox .stbox-title {
  height: 40pt;
}

.stbox .stbox-title div:first-child {
  font-weight: bolder;
  font-size: 20pt;
  line-height: 40pt;
  padding-left: 0.266667rem;
}

.stbox .stbox-shou {
  height: 72pt;
  display: flex;
  flex-wrap: nowrap;
  font-weight: bolder;
  justify-content: space-between;
}

.stbox .stbox-shou div:first-child {
  width: 10%;
  height: 100%;
  text-align: center;
  padding-top: 15pt;
  line-height: 20pt;
  border-right: 0.013333rem solid black;
}

.stbox .stbox-shou div:last-child {
  width: 90%;
  height: 100%;
  text-align: left;
  padding: 1%;
}

.stbox .stbox-ji {
  height: 48pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.stbox .stbox-ji div:first-child {
  width: 10%;
  height: 100%;
  text-align: center;
  padding-top: 4pt;
  line-height: 20pt;
  border-right: 0.013333rem solid black;
}

.stbox .stbox-ji div:last-child {
  width: 90%;
  height: 100%;
  font-size: 0.133333rem;
  text-align: left;
  padding: 1%;
}

.stbox .stbox-ma1 {
  height: 100pt;
}

.stbox .stbox-ma1 .box-ma1-top {
  text-align: center;
  padding-top: 0.266667rem;
}

.stbox .stbox-ma1 p {
  text-align: center;
  font-size: 20pt;
}

.stbox .stbox-qian {
  height: 90pt;
  border-bottom: 0.173333rem solid black;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.stbox .stbox-qian div:first-child {
  width: 40%;
  padding: 8pt;
  height: 100%;
  font-size: 10pt;
  border-right: 0.013333rem solid black;
}

.stbox .stbox-qian div:last-child {
  width: 60%;
  padding: 8pt;
  height: 100%;
}

.stbox .stbox-qian div:last-child p:first-child {
  margin-bottom: 30pt;
}

.stbox .stbox-qian div:last-child p:last-child .qian-span {
  float: right;
}

.stbox .stbox-ma2 {
  height: 56pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.stbox .stbox-ma2 .stbox-ma2-left {
  width: 50%;
  height: 100%;
  padding-top: 8pt;
  padding-left: 0.266667rem;
}

.stbox .stbox-ma2 .stbox-ma2-left img {
  height: 40pt;
}

.stbox .stbox-ma2 .stbox-ma2-right {
  width: 50%;
  padding: 0.133333rem;
}

.stbox .stbox-ma2 .stbox-ma2-right p {
  text-align: center;
}

.stbox .stbox-bottom {
  height: 120pt;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
}

.stbox .stbox-bottom .stbox-bottom-left {
  width: 10%;
  height: 100%;
  border-right: 0.013333rem solid black;
  text-align: center;
  line-height: 40pt;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  letter-spacing: 0.266667rem;
}

.stbox .stbox-bottom .stbox-bottom-right {
  width: 90%;
  height: 100%;
  padding: 0.133333rem;
  line-height: 25pt;
}

.stbox .stbox-bottom span {
  position: absolute;
  bottom: 0.266667rem;
  right: 0.266667rem;
}