.coupon-no-data {
  margin-top: 2.666667rem;
}

.coupon-dragstore {
  width: 100%;
  padding: 0 0.4rem;
  background-color: #fff;
  margin-top: 0.266667rem;
}

.coupon-dragstore > div:nth-child(1) {
  width: 100%;
  height: 0.586667rem;
  margin: 0.373333rem 0 0.32rem 0;
}

.coupon-dragstore .coupon-dragstore-title > div {
  height: 0.586667rem;
  width: 1.2rem;
  display: flex;
  align-items: center;
  margin-left: 0.133333rem;
}

.coupon-dragstore .coupon-dragstore-title > div img {
  width: 1.066667rem;
}

.coupon-dragstore .coupon-dragstore-title > p {
  color: #0B1523;
  font-size: 0.346667rem;
  font-weight: bold;
  margin-left: 0.266667rem;
}

.coupon-outer {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.3);
}

.coupon-tiket {
  background-color: #fff;
  margin: 0 0.133333rem;
  border-radius: 0.066667rem;
  height: 2.666667rem;
  border: 0.013333rem solid #eef1f5;
  margin-bottom: 0.266667rem;
  position: relative;
}

.coupon-tiket .coupon-new {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 0.906667rem solid red;
  border-left: 0.906667rem solid transparent;
}

.coupon-tiket .coupon-newword {
  position: absolute;
  top: 0.106667rem;
  right: 0.106667rem;
  color: #FFFFFF;
  font-size: 0.293333rem;
}

.coupon-tiket .coupon-pic {
  width: 1.333333rem;
  height: 1.333333rem;
  margin-top: 0.666667rem;
}

.coupon-tiket .coupon-detail {
  height: 100%;
  width: 100%;
}

.coupon-tiket .coupon-detail .coupon-detail-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.coupon-tiket .coupon-detail .coupon-detail-top .manjian-date {
  line-height: 0.533333rem;
  margin-top: 0.266667rem;
  margin-left: 0.266667rem;
  color: #999999;
}

.coupon-tiket .coupon-detail .coupon-detail-top .manjian-date .manjian-pharmacy-date {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0B1523;
}

.coupon-tiket .coupon-detail .coupon-detail-top .coupon-tiket-left {
  color: #F34848;
}

.coupon-tiket .coupon-detail .coupon-detail-top .coupon-tiket-left > p {
  font-size: 0.32rem;
  margin-top: 0.133333rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.coupon-tiket .coupon-detail .coupon-detail-top .coupon-tiket-left .coupon-num > span:nth-child(1) {
  font-size: 0.4rem;
}

.coupon-tiket .coupon-detail .coupon-detail-top .coupon-tiket-left .coupon-num > span:nth-child(2) {
  font-size: 0.64rem;
}

.coupon-tiket .coupon-detail .coupon-tiket-name {
  align-items: center;
  margin-top: 0.24rem;
}

.coupon-tiket .coupon-detail .coupon-tiket-name > span {
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.4rem;
}

.coupon-tiket .coupon-detail > span {
  color: #999999;
  font-size: 0.293333rem;
  margin-top: 0.4rem;
}

.coupon-tiket .coupon-detail .coupon-divide-line {
  width: 100%;
  height: 0;
  border-bottom: 0.013333rem dashed #999999;
  margin-top: 0.266667rem;
  margin-left: 0.266667rem;
}

.coupon-tiket .coupon-detail .coupon-last-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.266667rem;
  padding-right: 0.266667rem;
  color: #999;
  line-height: 0.466667rem;
  padding-top: 0.133333rem;
}

.contain-item {
  width: 100%;
  height: 100%;
  background-color: #EEF1F5;
}