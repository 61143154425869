.adresslist {
  align-items: center;
  padding: 0.133333rem 0;
}

.adresslist .adressitem {
  width: 100%;
  height: 2.56rem;
  background: #ffffff;
  opacity: 1;
  margin-top: 0.266667rem;
  border-radius: 0.133333rem;
  padding: 0.133333rem 0 0 0.346667rem;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #666666;
}

.adresslist .adressitem .adress-area {
  font-size: 0.346667rem;
  align-items: center;
}

.adresslist .adressitem .adress-area > div {
  width: 0.8rem;
  height: 0.48rem;
  font-size: 0.266667rem;
  line-height: 0.4rem;
  color: #ffffff;
  background: #F34848;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  margin-right: 0.133333rem;
}

.adresslist .adressitem .adress-street {
  color: #414141;
  font-weight: bold;
  font-size: 0.453333rem;
  height: 0.8rem;
  line-height: 0.8rem;
  width: 7.466667rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adresslist .adressitem .adress-edit {
  position: absolute;
  top: 50%;
  right: 0.613333rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.adresslist .adressitem .adress-edit > i {
  font-size: 0.533333rem;
}

.adresslist .adressitem > span {
  font-size: 0.32rem;
}

.adresslist .adressitem .adress-person-info {
  font-size: 0.133333rem;
  color: #6B6C73;
  margin-top: 0.133333rem;
}