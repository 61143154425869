.search-search {
  background-color: white;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.search-search .col-line {
  margin-top: 0.4rem;
}

.label-part {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.133333rem 0 0.426667rem;
}

.label-part .label-item {
  padding: 0 0.186667rem;
  margin: 0.133333rem 0.24rem 0.133333rem 0;
  height: 0.586667rem;
  border-radius: 0.133333rem;
  background: #EEF1F5;
  color: #666666;
  font-size: 0.32rem !important;
  position: relative;
}

.label-part .label-item .delete-icon {
  position: absolute;
  right: -0.213333rem;
  top: -0.213333rem;
}