.swiper {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0.053333rem;
  overflow: hidden;
}

.swiper-view img {
  width: 101%;
  height: 101%;
}