.goodsrejected-top {
  background-color: #fff;
}

.goodsrejected-top .pifa-goodsrejected {
  height: 1.706667rem;
  display: flex;
}

.goodsrejected-top .pifa-goodsrejected > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.goodsrejected-top .pifa-goodsrejected > p {
  line-height: 1.706667rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: 3.333333rem;
}

.goodsrejected-top .goodsrejected-tishi {
  height: 1.546667rem;
  background: linear-gradient(135deg, #FF8870 0%, #FF5555 100%);
  padding: 0.266667rem 0.4rem;
}

.goodsrejected-top .goodsrejected-tishi > p {
  line-height: 0.56rem;
  color: #fff;
  font-family: MicrosoftYaHei;
}

.goodsrejected-top .goodsrejected-tishi :first-child {
  font-size: 0.373333rem;
}

.goodsrejected-top .cgs-news {
  padding: 0.4rem;
}

.goodsrejected-top .cgs-news > p {
  line-height: 0.533333rem;
}

.goodsrejected-details {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.goodsrejected-details > p {
  line-height: 0.666667rem;
}

.goodsrejected-details .sh-xiangqing {
  height: 0.733333rem;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 0.4rem;
  color: #333333;
  margin-bottom: 0.266667rem;
  border-bottom: 0.026667rem solid #EEF1F5;
}

.goodsrejected-details > img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.266667rem;
}

.tuihui-address {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.tuihui-address > p {
  line-height: 0.6rem;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
}

.tuihui-address :first-child {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 0.8rem;
}

.tuihui {
  height: 1.133333rem;
  line-height: 1.133333rem;
  text-align: center;
  color: #fff;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  background-color: #FF5555;
  margin-top: 0.466667rem;
}

.fillLogistics {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

.fillLogistics .fillLogistics-up {
  width: 7.52rem;
  height: 8.266667rem;
  background: #FFFFFF;
  border: 0.013333rem solid #707070;
  border-radius: 0.266667rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.fillLogistics .fillLogistics-up .fillLogistics-up-title {
  padding: 0.533333rem 0.4rem;
  font-size: 0.453333rem;
  font-family: MicrosoftYaHei-Bold;
  line-height: 0.693333rem;
  color: #414141;
}

.fillLogistics .fillLogistics-up > p .danhao {
  margin-left: 0.4rem;
}

.fillLogistics .fillLogistics-up > p > input {
  width: 5.6rem;
  height: 1.173333rem;
  background: #FFFFFF;
  border: 0.013333rem solid #C8C8C8;
  border-radius: 0.066667rem;
  margin-bottom: 0.533333rem;
  padding-left: 0.266667rem;
}

.fillLogistics .fillLogistics-up > p > select {
  width: 5.6rem;
  height: 1.173333rem;
  background: #FFFFFF;
  border: 0.013333rem solid #C8C8C8;
  border-radius: 0.066667rem;
  margin-bottom: 0.533333rem;
  padding-left: 0.266667rem;
  font-size: 0.4rem;
}

.fillLogistics .fillLogistics-up .fillLogistics-up-button {
  border-top: 0.026667rem solid #EEF1F5;
  display: flex;
}

.fillLogistics .fillLogistics-up .fillLogistics-up-button > span {
  width: 3.76rem;
  text-align: center;
  height: 1.266667rem;
  line-height: 1.266667rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FF5555;
}

.fillLogistics .fillLogistics-up .fillLogistics-up-button :first-child {
  border-right: 0.026667rem solid #EEF1F5;
  color: #353A42;
}