.box-top1 {
  position: fixed !important;
  top: 0;
}

.risk-title {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  margin-top: 1.6rem;
  line-height: 0.8rem;
  color: #656565;
  text-align: center;
}

.risk-right {
  line-height: 0.666667rem;
  text-align: right;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #707070;
}

.risk-body {
  padding: 0.4rem;
  background-color: #fff;
}

.risk-body > p {
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #707070;
  margin-bottom: 0.4rem;
}

.risk-body > p .blod {
  font-weight: bold;
  color: #333;
}

.risk-body > p .blod1 {
  font-weight: bold;
  color: #333;
  text-decoration: underline;
}

.box-top > img {
  width: 0.8rem !important;
  height: 0.8rem !important;
}