.evaluate-container {
  padding-bottom: 1.6rem;
  background-color: #EEF1F5;
}

.evaluate-container .evaluate-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.466667rem 0.266667rem;
  background-color: white;
}

.evaluate-container .evaluate-header i {
  font-size: 0.533333rem;
}

.evaluate-container .evaluate-header .evaluate-title {
  font-size: 0.48rem;
  color: #414141;
}

.evaluate-container .evaluate-header .evaluate-zhanwei {
  width: 0.32rem;
}

.evaluate-container .evaluate-sort {
  width: 100%;
  padding: 0.266667rem 0 0.933333rem 0;
  justify-content: center;
  background-color: white;
}

.evaluate-container .evaluate-sort > div {
  width: 90%;
  justify-content: space-around;
}

.evaluate-container .evaluate-sort > div > div {
  font-size: 0.32rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.133333rem;
  color: #414141;
  background-color: #E7F2FF;
  border: 0.026667rem solid #E7F2FF;
  border-radius: 0.053333rem;
}

.evaluate-container .evaluate-sort > div > div.evaluate-active {
  color: #1F87FF;
  border: 0.026667rem solid #1F87FF;
}

.evaluate-container .med-evaluate {
  padding: 0.48rem 0.266667rem;
  margin-top: 0.133333rem;
  background-color: white;
}

.evaluate-container .med-evaluate .user-evaluate {
  align-items: flex-end;
  justify-content: space-between;
}

.evaluate-container .med-evaluate .user-evaluate .user-avatar > div:first-child {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.evaluate-container .med-evaluate .user-evaluate .user-avatar > div:first-child > img {
  width: 100%;
}

.evaluate-container .med-evaluate .user-evaluate .user-name {
  height: 0.8rem;
  margin-left: 0.133333rem;
  font-size: 0.32rem!important;
  align-items: flex-start;
  justify-content: space-between;
}

.evaluate-container .med-evaluate .user-evaluate .user-name .star-rating {
  color: #FFB740;
}

.evaluate-container .med-evaluate .user-evaluate .evaluate-time {
  font-size: 0.32rem;
  color: #C2C5CB;
}

.evaluate-container .med-evaluate .evaluate-text {
  margin-top: 0.4rem;
  font-size: 0.373333rem;
  color: #353A42;
}

.evaluate-container .med-evaluate .evaluate-text .evaluate-img {
  justify-content: space-between;
  flex-wrap: wrap;
}

.evaluate-container .med-evaluate .evaluate-text .evaluate-img:after {
  content: '';
  width: 30%;
  border: 0.026667rem solid transparent;
}

.evaluate-container .med-evaluate .evaluate-text .evaluate-img div {
  margin-top: 0.266667rem;
  width: 32%;
  overflow: hidden;
}

.evaluate-container .med-evaluate .evaluate-text .evaluate-img div img {
  height: 2.266667rem;
  width: 2.266667rem;
}

.evaluate-container .med-evaluate .shangjia-huifu {
  margin-top: 0.266667rem;
  background-color: #F8F8F8;
  padding: 0.266667rem 0;
}

.evaluate-container .med-evaluate .shangjia-huifu .shangjia-huifu-top {
  display: flex;
  justify-content: space-between;
}

.evaluate-container .med-evaluate .shangjia-huifu .shangjia-huifu-top > span {
  color: #333333;
}

.evaluate-container .med-evaluate .shangjia-huifu .shangjia-text {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #666666;
  margin-top: 0.133333rem;
}