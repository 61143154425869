.SHintop {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
}

.SHintop img {
  width: 0.64rem;
  height: 0.64rem;
}

.SHintop h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.SHinpic {
  width: 100%;
  height: 5.333333rem;
  text-align: center;
  background-color: #EEEEEE;
  position: relative;
}

.SHinpic .changepic {
  position: absolute;
  width: 1.066667rem;
  height: 0.533333rem;
  color: white;
  background-color: #7070dd;
  border: none;
  border-radius: 0.066667rem;
  top: 0.266667rem;
  left: 0.266667rem;
}

.SHinpic img {
  height: 100%;
  width: 45%;
}

.inputall {
  width: 100%;
}

.inputall .alltime {
  line-height: 0.8rem;
}

.inputall .alltime input {
  vertical-align: middle;
  height: 0.533333rem;
}

.inputall div {
  box-sizing: border-box;
  padding-left: 0.266667rem;
  line-height: 1.066667rem;
}

.inputall div span {
  display: inline-block;
  width: 30%;
  text-align: right;
  font-size: 0.346667rem;
  margin-right: 0.133333rem;
}

.inputall div input {
  margin: 0;
  padding: 0;
  height: 0.8rem;
  width: 4.666667rem;
}

.SHbtn {
  width: 8.8rem;
  height: 1.146667rem;
  border: none;
  border-radius: 0.573333rem;
  background-color: #FF5555;
  color: #FFFFFF;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1.066667rem;
}

.card {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 2.666667rem;
  background-color: rgba(211, 211, 211, 0.9);
}

.card .card-img {
  width: 90%;
  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto;
  position: relative;
  padding: 0.266667rem;
  text-align: center;
}

.card .card-img img {
  width: 80%;
}

.card .card-img button {
  width: 0.8rem;
  height: 0.8rem;
  background-image: url("../../../../static/img/mine/xxx.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0.133333rem;
  right: 0.133333rem;
  border: none;
  border-radius: 50%;
}