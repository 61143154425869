.middledetails-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.middledetails-top img {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.133333rem;
}

.middledetails-top h2 {
  font-size: 0.48rem;
  width: 80%;
  margin-left: 0.266667rem;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.middledetails-body {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  border-top: 0.026667rem solid #E5E5E5;
}

.middledetails-body > h2 {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 0.4rem;
}

.middledetails-body > div {
  text-indent: 2em;
  line-height: 0.666667rem;
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
}

.middledetails-body > div > img {
  height: 0.266667rem;
  margin-top: 0.533333rem;
}

.middledetails-body > div > span {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.middledetails-body .look-wenjian {
  margin-left: 0.4rem;
  margin-top: 0.266667rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #364AFF;
}

.middledetails-body .push-time {
  text-align: right;
  margin-right: 0.4rem;
  margin-top: 0.266667rem;
  margin-bottom: 0.266667rem;
}