.yd-box {
  width: 400pt;
  height: 720pt;
  box-sizing: border-box;
  border: 0.04rem solid black;
  font-weight: bolder;
  margin: 0 auto;
}

.yd-box > div {
  border-bottom: 0.013333rem solid black;
  box-sizing: border-box;
}

.yd-box .yd-box-title {
  height: 44pt;
  line-height: 44pt;
  text-align: right;
  padding-right: 0.266667rem;
  font-size: 28pt;
  border: none;
}

.yd-box .yd-box-3ma {
  height: 56pt;
  text-align: center;
  font-size: 40pt;
}

.yd-box .yd-box-jiadress {
  height: 40pt;
  line-height: 40pt;
  padding-left: 0.266667rem;
  font-size: 20pt;
}

.yd-box .yd-box-jiadress span {
  font-size: 20pt;
}

.yd-box .yd-box-shou {
  height: 72pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.yd-box .yd-box-shou .yd-box-shou-left {
  width: 10%;
  height: 100%;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  line-height: 40pt;
  text-align: center;
  letter-spacing: 0.266667rem;
  border-right: 0.013333rem solid black;
}

.yd-box .yd-box-shou .yd-box-shou-right {
  width: 90%;
  height: 100%;
  padding: 2%;
  font-size: 25pt;
}

.yd-box .yd-box-shou .yd-box-shou-right p {
  margin-bottom: 0.266667rem;
}

.yd-box .yd-box-ji {
  height: 52pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.yd-box .yd-box-ji .yd-box-ji-left {
  width: 10%;
  height: 100%;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  line-height: 40pt;
  text-align: center;
  letter-spacing: 0.133333rem;
  border-right: 0.013333rem solid black;
}

.yd-box .yd-box-ji .yd-box-ji-right {
  width: 90%;
  height: 100%;
  padding: 2%;
  font-size: 25pt;
}

.yd-box .yd-box-ji .yd-box-ji-right p {
  margin-bottom: 0.133333rem;
}

.yd-box .yd-box-ma1 {
  height: 108pt;
}

.yd-box .yd-box-ma1 .box-ma1-top {
  width: 100%;
  height: 75%;
  padding-top: 0.133333rem;
  text-align: center;
}

.yd-box .yd-box-ma1 p {
  text-align: center;
  font-size: 20pt;
}

.yd-box .yd-box-qian {
  height: 68pt;
  padding-left: 35pt;
}

.yd-box .yd-box-qian p {
  line-height: 34pt;
}

.yd-box .yd-box-ma2 {
  height: 60pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.yd-box .yd-box-ma2 .yd-box-ma2-left {
  width: 35%;
  height: 100%;
  border-right: 0.013333rem solid black;
}

.yd-box .yd-box-ma2 .yd-box-ma2-right {
  width: 65%;
  height: 100%;
}

.yd-box .yd-box-ma2 .yd-box-ma2-right div:first-child {
  text-align: center;
  padding-top: 0.133333rem;
}

.yd-box .yd-box-ma2 .yd-box-ma2-right div:last-child {
  text-align: center;
}

.yd-box .yd-box-shou2 {
  height: 48pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.yd-box .yd-box-shou2 .yd-box-shou2-left {
  width: 10%;
  height: 100%;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  line-height: 40pt;
  text-align: center;
  letter-spacing: 0.133333rem;
  padding-top: 0.066667rem;
  border-right: 0.013333rem solid black;
}

.yd-box .yd-box-shou2 .yd-box-shou2-right {
  width: 90%;
  height: 100%;
  padding: 2%;
  font-size: 25pt;
}

.yd-box .yd-box-shou2 .yd-box-shou2-right p {
  margin-bottom: 0.133333rem;
}

.yd-box .yd-box-ji2 {
  height: 44pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.yd-box .yd-box-ji2 .yd-box-ji2-left {
  width: 10%;
  height: 100%;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  line-height: 40pt;
  text-align: center;
  letter-spacing: 0.066667rem;
  border-right: 0.013333rem solid black;
}

.yd-box .yd-box-ji2 .yd-box-ji2-right {
  width: 90%;
  height: 100%;
  padding: 1%;
  font-size: 25pt;
}

.yd-box .yd-box-ji2 .yd-box-ji2-right p {
  margin-bottom: 0.133333rem;
}

.yd-box .yd-box-bottom {
  height: 128pt;
  border: none;
  padding: 0.266667rem;
  position: relative;
}

.yd-box .yd-box-bottom span {
  position: absolute;
  right: 0.266667rem;
  bottom: 0.266667rem;
}