.box2 {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  height: 100vh;
  background-color: white;
  padding-top: 0.493333rem;
  position: relative;
}

.box2 .top1 {
  width: 100%;
  text-align: center;
  font-size: 0.48rem;
  color: #414141;
  margin-bottom: 1.426667rem;
}

.box2 .top1 button {
  width: 0.64rem;
  height: 0.64rem;
  border: none;
  float: left;
  background-color: white;
  background-image: url("../../static/img/login/fanhui.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.box2 .topp {
  font-size: 0.533333rem;
  text-align: left;
  line-height: 0.8rem;
  margin-bottom: 0.266667rem;
  color: #333333;
}

.box2 input {
  width: 100%;
  margin-bottom: 0.133333rem;
  height: 0.8rem;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0.026667rem solid #E5E5E5;
}

.box2 .btn,
.box2 .btn2 {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.426667rem;
  line-height: 1.12rem;
  border-radius: 0.56rem;
}

.box2 .btn p,
.box2 .btn2 p {
  color: #FFFFFF;
}

.box2 .btn {
  background-color: #a6c1Fa;
}

.box2 .btn2 {
  background-color: #2086FE;
}