.invoice-top {
  background-color: #fff;
}

.invoice-top .pifa-invoice {
  height: 1.706667rem;
  display: flex;
}

.invoice-top .pifa-invoice > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.invoice-top .pifa-invoice > p {
  line-height: 1.706667rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: 2.666667rem;
}

.invoice-top .selsect-reason {
  height: 1.266667rem;
  padding: 0.4rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.invoice-top .selsect-reason > span {
  font-size: 0.373333rem;
}

.invoice-pic {
  height: 6rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  padding: 0.4rem;
}

.invoice-pic .shuoming {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-bottom: 0.266667rem;
}

.invoice-pic .miaoshu {
  padding-top: 0.266667rem;
  padding-left: 0.266667rem;
  border: none;
  background-color: #F8F8F8;
}

.invoice-pic .upvoucher-in {
  height: 4rem;
}

.tijiao {
  position: fixed;
  bottom: 0.266667rem;
}

.tijiao > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.tijiao > span {
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.333333rem;
}

.details {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.details .xiangguan {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #414141;
}

.details .details-outer {
  display: flex;
  height: 2.4rem;
  padding-top: 0.266667rem;
}

.details .details-outer > img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.266667rem;
}

.details .details-outer .details-in {
  padding: 0.266667rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.details .details-outer .details-in > h2 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #353A42;
}

.details .details-outer .details-in > p {
  display: flex;
}

.details .details-outer .details-in > p > label {
  display: flex;
  margin-right: 0.4rem;
}