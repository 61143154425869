.patientinfo-card {
  width: 9.306667rem;
  height: 2.773333rem;
  background: #ffffff;
  border-radius: 0.4rem;
  margin: 0.266667rem auto;
  padding: 0.4rem 0.4rem 0.426667rem 0.853333rem;
}

.patientinfo-card span.patient-name {
  font-size: 0.426667rem;
  color: #414141;
}

.patientinfo-card span {
  color: #656565;
  font-size: 0.293333rem;
}

.patientinfo-card i {
  color: #F34848;
}

.patientinfo-card > div:first-child {
  line-height: 0.4rem;
  margin-bottom: 0.24rem;
}

.patientinfo-card > div:nth-child(2) {
  margin: 0.24rem 0 0.346667rem 0;
  display: flex;
  flex-direction: row-reverse;
}

.patientinfo-add {
  width: 9.306667rem;
  height: 1.386667rem;
  background: #ffffff;
  border-radius: 0.4rem;
  margin: 0.266667rem auto;
}

.patientinfo-add i {
  color: #1F87FF;
}

.patientinfo-add span {
  color: #414141;
  font-size: 0.426667rem;
  margin-left: 0.266667rem;
}