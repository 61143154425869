.order-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.viewrecord-part {
  padding: 0.266667rem 0.266667rem 1.2rem 0.266667rem;
  background-color: #FFFFFF;
}

.viewrecord-part .vrecord-box {
  width: 100%;
}

.viewrecord-part .viewrecord-day {
  line-height: 1.066667rem;
  color: #414141;
  font-size: 0.453333rem;
}

.viewrecord-part .viewrecord-med {
  width: 100%;
  height: 2rem;
  margin: 0.4rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewrecord-part .viewrecord-med .viewrecord-space {
  width: 0.266667rem;
  height: 100%;
}

.viewrecord-part .viewrecord-med .viewrecord-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.293333rem;
  justify-content: center;
  align-items: center;
}

.viewrecord-part .viewrecord-med .viewrecord-img > img {
  max-width: 2rem;
  overflow: hidden;
}

.viewrecord-part .viewrecord-med .collection-img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.293333rem;
  justify-content: center;
  align-items: center;
}

.viewrecord-part .viewrecord-med .collection-img > img {
  width: 100%;
}

.viewrecord-part .viewrecord-med .viewrecord-item-select {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.066667rem;
}

.viewrecord-part .viewrecord-med .viewrecord-med-detail {
  height: 100%;
  justify-content: space-between;
}

.viewrecord-part .viewrecord-med .viewrecord-med-detail > div p {
  color: #354242;
  font-size: 0.346667rem;
  line-height: 0.533333rem;
}

.viewrecord-part .viewrecord-med .viewrecord-med-detail > div span {
  color: #353A42;
  margin-top: 0.133333rem;
}

.viewrecord-part .viewrecord-med .viewrecord-med-detail > p {
  color: #666666;
  margin-bottom: 0.213333rem;
  font-size: 0.32rem;
}

.viewrecord-part .viewrecord-med .viewrecord-buy {
  width: 0.613333rem;
  height: 0.613333rem;
  border-radius: 50%;
  background-color: #FFEAEA;
  color: #F56969;
}

.viewrecord-part .viewrecord-med .viewrecord-buy > i {
  font-size: 0.4rem;
}

.edit-items {
  width: 100%;
  height: 1.36rem;
  padding: 0 0.426667rem 0 0;
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0 0 1.066667rem rgba(168, 174, 188, 0.3);
}