.header1 {
  width: 100%;
  position: relative;
  background-color: #F4F5F7;
}

.header1 .drawback-header {
  padding-top: 0.2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.header1 .drawback-header .back-icon {
  position: absolute;
  width: 0.64rem;
  height: 0.64rem;
  margin-left: 0.266667rem;
  align-items: center;
  top: 0.6rem;
}

.header1 .drawback-header .back-icon i {
  font-size: 0.533333rem;
  color: #666;
}

.header1 .drawback-header .order-kefu {
  position: relative;
  left: 8.933333rem;
  height: 0.533333rem;
  top: 0.4rem;
}

.header1 .drawback-header .order-kefu > img {
  height: 0.533333rem;
}

.header1 .drawback {
  position: relative;
  top: 0.933333rem;
  padding: 0.4rem;
  font-size: 0.533333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0B1523;
}

.header1 .teshu-drawback {
  position: relative;
  top: 1.066667rem;
  height: 3.333333rem !important;
  background-color: #fff;
  padding: 0.4rem;
}

.header1 .teshu-drawback .teshu-drawback-select {
  display: flex;
  justify-content: start;
  align-items: center;
}

.header1 .teshu-drawback .teshu-drawback-select > input {
  margin-right: 0.133333rem;
}

.header1 .teshu-drawback .teshu-drawback-title {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  line-height: 0.533333rem;
}

.header1 .teshu-drawback .teshu-drawback-explain {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
}

.header1 .teshu-drawback .xian {
  width: 9.44rem;
  height: 0.026667rem;
  background: #EEF1F5;
  margin: 0.373333rem 0;
}

.header1 .contact {
  position: relative;
  top: 1.333333rem;
  height: 1.426667rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
}

.header1 .contact > img {
  width: 0.56rem;
  height: 0.533333rem;
}

.header1 .contact > span {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.4rem;
  color: #0B1523;
  margin-top: 0.133333rem;
}

.header1 .pharmacy {
  position: relative;
  top: 1.6rem;
  width: 10rem;
  padding: 0.4rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
}

.header1 .pharmacy .phname {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.header1 .pharmacy .pharmacy-name {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

.header1 .pharmacy .pharmacy-name .flexr .flexr-right {
  padding-top: 0.4rem;
  height: 0.666667rem;
  line-height: 0.466667rem;
}

.header1 .pharmacy .pharmacy-name .flexr .flexr-right > p {
  width: 6.4rem;
  display: flex;
  justify-content: space-between;
}

.header1 .pharmacy .pharmacy-name .flexr .flexr-right > p .price {
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}

.header1 .pharmacy .xian {
  height: 0.026667rem;
  background: #EEF1F5;
}

.header1 .pharmacy .all-select {
  display: flex;
  justify-content: space-between;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.header1 .pharmacy .all-select .total {
  font-size: 0.453333rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}