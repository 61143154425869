.S-box {
  position: absolute;
  width: 100%;
  top: 2.133333rem;
  text-align: center;
  left: 0;
}

.S-box input {
  height: 1.173333rem;
  width: 7.733333rem;
  border-radius: 0.586667rem;
  border: none;
  background-image: url("../../static/img/icon/search2.png");
  background-repeat: no-repeat;
  background-size: 0.4rem;
  background-position: 0.4rem center;
  padding-left: 1.066667rem;
}