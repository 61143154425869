.shop-backimg {
  width: 100%;
  height: 4.453333rem;
  background: #020920;
  border: 0.013333rem solid #707070;
  background-size: 100%;
  position: absolute;
  z-index: -92;
  overflow: hidden;
  top: 0;
}

.backimg-mask {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4.453333rem;
  background: rgba(2, 9, 32, 0.7);
}

.shop-content {
  width: 100%;
  height: 2.8rem;
}

.shop-content .shop-header {
  width: 100%;
  height: 1.733333rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.213333rem;
}

.shop-content .shop-header .shop-header-zhanwei {
  width: 0.64rem;
}

.shop-content .shop-header .shop-header-zhanwei > i {
  font-size: 0.533333rem;
  color: #FFFFFF;
}

.shop-content .shop-header .shop-search {
  width: 7.573333rem;
  height: 0.853333rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  padding: 0 0.4rem;
  background: rgba(236, 236, 236, 0.5);
  color: #FFFFFF;
  align-items: center;
}

.shop-content .shop-header .shop-search input {
  width: 100%;
  font-size: 0.373333rem;
  font-weight: normal;
  border: none;
  background: none;
  color: #FFFFFF;
  margin-left: 0.24rem;
}

.shop-content .shop-header .shop-search input::-webkit-input-placeholder {
  color: #FFFFFF;
}

.shop-content .shop-header .shop-search input:-ms-input-placeholder {
  color: #FFFFFF;
}

.shop-content .shop-header .shop-search input::placeholder {
  color: #FFFFFF;
}

.shop-content .shop-header .shop-search i {
  color: #FFFFFF;
  font-size: 0.48rem;
}

.shop-content .shop-header .shop-search .shop-search-btn {
  width: 1.066667rem;
  line-height: 0.853333rem;
  font-size: 0.373333rem;
  text-align: center;
}

.shop-content .shop-introduce {
  width: 100%;
  height: 1.066667rem;
  color: #FFFFFF;
}

.shop-content .shop-introduce .shop-brank {
  width: 100%;
  height: 1.066667rem;
  padding: 0 0.453333rem;
  position: relative;
}

.shop-content .shop-introduce .shop-brank .shop-logo {
  width: 1.066667rem;
  height: 100%;
  border-radius: 0.066667rem;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.shop-content .shop-introduce .shop-brank .shop-logo img {
  width: 1.066667rem;
}

.shop-content .shop-introduce .shop-brank .shop-title {
  height: 100%;
  margin-left: 0.32rem;
  justify-content: space-between;
  align-items: flex-start;
}

.shop-content .shop-introduce .shop-brank .shop-title > p {
  width: 6.666667rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.4rem;
}

.shop-content .shop-introduce .shop-brank .shop-title > span {
  font-size: 0.32rem;
}

.shop-content .shop-introduce .shop-brank .shop-fllow {
  width: 1.466667rem;
  line-height: 0.48rem;
  border-radius: 0.4rem;
  background-color: #00B7F5;
  position: absolute;
  bottom: 0;
  right: 0.453333rem;
  color: white;
}

.shop-tab-contain {
  position: fixed;
  left: 0;
  right: 0;
  top: 2.8rem;
  bottom: 1.306667rem;
}

.shop-tab-contain .am-tabs-tab-bar-wrap {
  padding-right: 1.333333rem;
}

.shop-tab-contain .shop-classify-body {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.shop-tab-contain .shop-classify-body .menu {
  flex: 0 0 2.613333rem;
  width: 2.613333rem;
  background-color: #EEF1F5;
  position: relative;
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll {
  height: 70vh;
  padding-bottom: 1.333333rem;
  overflow-y: auto;
  background-color: transparent;
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll .item {
  display: flex;
  box-sizing: border-box;
  height: 1.626667rem;
  padding: 0 0.133333rem 0 0.213333rem;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll .item.active {
  background-color: #fff;
  position: relative;
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll .item.active::before {
  content: '' !important;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.106667rem;
  height: 0.533333rem;
  background-color: #1F87FF;
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll .item span {
  font-size: 0.373333rem;
  font-weight: 300;
  color: #333;
}

.shop-tab-contain .shop-classify-body .menu .menu-scroll .item .count {
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.shop-tab-contain .shop-classify-body .detail {
  overflow-y: auto;
  flex: 1 1;
  width: 0;
  height: 70vh;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 3.733333rem;
}

.shop-tab-contain .shop-classify-body .detail .item {
  display: flex;
  padding: 0 0.8rem 0 1.066667rem;
  height: 2.666667rem;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.shop-tab-contain .shop-classify-body .detail .item .img {
  flex: 0 0 1.973333rem;
  height: 1.973333rem;
  margin-right: 0.533333rem;
}

.shop-tab-contain .shop-classify-body .detail .item .img img {
  width: 1.973333rem;
  height: 1.973333rem;
  content: none !important;
}

.shop-tab-contain .shop-classify-body .detail .item .title {
  flex: 1 1;
  font-size: 0.373333rem;
  color: #333;
}

.shop-tab-contain .shop-classify-body .detail .item .count {
  flex: 0 0 1.6rem;
  width: 1.6rem;
  text-align: right;
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.shop-tab-contain .shop-classify-body .detail .item.active .title,
.shop-tab-contain .shop-classify-body .detail .item.active .count {
  color: #1F87FF;
}

.ill-item1 {
  margin-top: 0.266667rem;
  width: 100%;
  height: 2.666667rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.ill-item1 .img {
  width: 2.666667rem;
  height: 2.666667rem;
  overflow: hidden;
}

.ill-item1 .img > img {
  width: 2.666667rem;
  height: auto;
  overflow: hidden;
  margin-top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ill-item1 .cf-medicition {
  position: absolute;
  z-index: 999;
  background-color: #1F87FF;
  width: 0.933333rem;
  height: 0.426667rem;
  line-height: 0.426667rem;
  color: #fff;
  text-align: center;
  font-size: 0.266667rem;
  border-radius: 0.266667rem;
}

.ill-item1 .ill-item-details {
  width: 60%;
  padding: 0.133333rem;
}

.ill-item1 .ill-item-details .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 0.666667rem;
  color: #666666;
}

.ill-item1 .ill-item-details .ill-item-yueshou {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-bottom: 0.266667rem;
}

.ill-item1 .ill-item-details .ill-all-full {
  width: 90%;
  height: 0.533333rem;
  line-height: 0.533333rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ill-item1 .ill-item-details .ill-all-full .ill-item-full {
  border: 0.013333rem solid #FFB7B7;
  opacity: 1;
  border-radius: 0.066667rem;
  font-size: 0.24rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FF4D4D;
  padding: 0 0.133333rem;
  margin-right: 0.133333rem;
}

.ill-item1 .ill-item-details .ill-item-money {
  margin-top: 0.2rem;
}

.ill-item1 .ill-item-details .ill-item-money .mow-price {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FC4343;
}

.ill-item1 .ill-item-details .ill-item-money .origin-price {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #C2C2C2;
  margin-left: 0.266667rem;
  text-decoration: line-through;
}

.ill-item1 .ill-item-details .ill-item-money .shop-all {
  position: relative;
  left: 68%;
  width: 1.266667rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  bottom: 0.4rem;
}

.ill-item1 .ill-item-details .ill-item-money .shop-all .shop-jian,
.ill-item1 .ill-item-details .ill-item-money .shop-all .shop-jia {
  width: 0.426667rem;
  height: 0.4rem;
  line-height: 0.373333rem;
  text-align: center;
  border-radius: 50%;
  background-color: #1F87FF;
  font-size: 0.4rem;
  color: #fff;
}

.ill-item1 .ill-item-details .ill-item-money .shop-all .shop-count {
  width: 0.426667rem;
  text-align: center;
}

.ill-item1 .ill-item-details .ill-item-money .shop-all1 {
  position: relative;
  left: 80%;
  width: 0.533333rem;
  bottom: 0.4rem;
}

.ill-item1 .ill-item-details .ill-item-money .shop-all1 .shop-jia {
  width: 0.466667rem;
  height: 0.466667rem;
  line-height: 0.466667rem;
  text-align: center;
  border-radius: 50%;
  background-color: #1F87FF;
  font-size: 0.4rem;
  color: #fff;
}

.shop-contain-item {
  width: 100%;
  height: 100%;
  padding-top: 0.4rem;
  background-color: #EEF1F5;
  overflow: scroll;
  padding-bottom: 1.066667rem;
}

.shop-sort {
  width: 100%;
  height: 1.653333rem;
  padding-right: 3.36rem;
  padding-left: 0.293333rem;
  color: #FFFFFF;
}

.shop-sort .shop-sort-item {
  width: 1.76rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.373333rem;
}

.shop-sort .shopSortActive {
  background: #ffffff;
  color: #00B7F5;
  border-radius: 0.4rem;
}

.shop-footer {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 1.066667rem rgba(168, 174, 188, 0.3);
}

.shop-footer ul {
  height: 1.28rem;
  padding-top: 0.173333rem;
}

.shop-footer ul li {
  float: left;
  text-align: center;
  width: 25%;
}

.shop-footer ul li > div {
  position: relative;
}

.shop-footer ul li > div .p-move {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  background-color: #FF5555;
  border-radius: 50%;
  right: 0.666667rem;
  top: -0.133333rem;
  font-size: 0.186667rem;
  color: white;
}

.shop-footer ul li > div > i {
  display: block;
  font-size: 0.533333rem;
}

.shop-footer ul li > div span {
  font-size: 0.32rem;
  color: #7F7F7F;
}

.shop-footer ul li .shop-telphone {
  display: block;
}

.shop-footer ul li .shop-telphone > i {
  display: block;
  font-size: 0.533333rem;
}

.shop-footer ul li .shop-telphone span {
  font-size: 0.32rem;
  color: #7F7F7F;
}

.shopFootActive {
  color: #00B7F5;
}

.shopFootActive i {
  color: #00B7F5;
}

.shop-activity {
  width: 100%;
  margin: 0.533333rem 0 0.266667rem 0;
}

.shop-activity > img {
  width: 9.066667rem;
  height: auto;
  margin: auto;
}

.shopmed-input input {
  color: #666666;
}

.shopmed-input input::-webkit-input-placeholder {
  color: #C2C5CB !important;
}

.shopmed-input input:-ms-input-placeholder {
  color: #C2C5CB !important;
}

.shopmed-input input::placeholder {
  color: #C2C5CB !important;
}

.into-shop {
  color: #666666;
  position: absolute;
  right: 0.4rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.into-shop i {
  color: #151515 !important;
  font-size: 0.426667rem;
}

.am-tabs-default-bar-top .am-tabs-default-bar-underline,
.am-tabs-default-bar-bottom .am-tabs-default-bar-underline {
  border: 0.08rem solid #1F87FF !important;
}

.tabs-title {
  margin-top: 0.533333rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.4rem;
}

.tabs-title .tabs-out {
  width: 50vw;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.tabs-title .tabs-out .title {
  color: #fff;
  font-size: 0.48rem;
  margin-bottom: 0.066667rem;
}

.tabs-title .tabs-out .cline1 {
  width: 1.6rem;
  height: 0.133333rem;
  margin-top: -1.066667rem;
  margin-left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.tabs-title .tabs-out .cline {
  width: 1.6rem;
  height: 0.133333rem;
  background-color: #1F87FF;
  margin-top: -1.066667rem;
  margin-left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}