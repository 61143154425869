.select-wrapper {
  width: 100%;
  height: 1.146667rem;
  display: flex;
  flex-direction: row;
  border-bottom: 0.013333rem solid #EDEDEE;
  background: #FFFFFF;
  position: relative;
  overflow: visible;
}

.select-wrapper .select-item {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.select-wrapper .select-item span {
  color: #666666;
  font-size: 0.373333rem;
}

.select-wrapper .select-item i {
  color: #151515;
}

.select-wrapper .select-selected span {
  color: #1f87ff;
}

.select-wrapper .select-selected i {
  color: #1f87ff;
}

.select-tab {
  position: absolute;
  top: 1.146667rem;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 998;
  display: flex;
  flex-direction: column;
}

.select-tab .select-tabline {
  width: 100%;
  height: 1.066667rem;
  border-bottom: 0.013333rem solid #EDEDEE;
  background: #FFFFFF;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 1.066667rem;
  z-index: 999;
  padding: 0 0.426667rem;
}