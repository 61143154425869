.med-tab1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.533333rem;
}

.med-tab1 > p {
  font-size: 0.346667rem;
  line-height: 0.48rem;
  margin-right: 0.266667rem;
  width: 0.933333rem;
  color: #0B1523;
  font-weight: bold;
}

.med-tab1 > span {
  font-size: 0.346667rem;
  width: 100%;
  line-height: 0.48rem;
}

.med-tab2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.266667rem;
}

.med-tab2 > p {
  font-size: 0.32rem;
  line-height: 0.48rem;
  margin-right: 0.266667rem;
  width: 2.266667rem;
  color: #0B1523;
  font-weight: bold;
}

.med-tab2 > span {
  font-size: 0.32rem;
  width: 100%;
  line-height: 0.48rem;
}

.guoqi {
  padding-bottom: 0.266667rem;
}

.med-tab3 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.066667rem;
}

.med-tab3 > p {
  font-size: 0.346667rem;
  line-height: 0.48rem;
  margin-right: 0.266667rem;
  width: 0.933333rem;
  color: #0B1523;
  font-weight: bold;
}

.med-tab3 > span {
  font-size: 0.346667rem;
  width: 100%;
  line-height: 0.48rem;
}

#med-header {
  width: 100%;
  height: 1.68rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.266667rem 0 0;
  position: fixed;
  z-index: 999;
  margin-bottom: 0.266667rem;
}

#med-header * {
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
}

#med-header .med-header-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0 0.266667rem;
}

#med-header .med-header-icon .round-icon {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #C2C5CB;
  border-radius: 50%;
  color: #FFFFFF;
}

#med-header .med-header-icon .round-icon > i {
  font-size: 0.533333rem;
}

#med-header .med-header-icon .round-icon > i.icon-fenxiang {
  font-size: 0.4rem;
}

#med-header .header-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 0 2.666667rem;
  opacity: 0;
}

#med-header .header-tab > div {
  z-index: 9;
  margin: 0 0.266667rem;
  position: relative;
}

#med-header .header-tab > div > span {
  font-size: 0.453333rem !important;
}

#med-header .header-tab > div .active-line {
  position: absolute;
  bottom: -0.426667rem;
  left: 50%;
  width: 0.64rem;
  height: 0.16rem;
  background-color: #1F87FF;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#med-img {
  width: 100%;
  height: 10rem;
}

#med-introduce {
  width: 100%;
  background-color: white;
  color: #353A42;
  padding: 0.373333rem 0.373333rem 0 0.373333rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.266667rem;
}

#med-introduce > div {
  width: 100%;
}

#med-introduce .med-brand {
  padding-bottom: 0.266667rem;
}

#med-introduce .med-brand .med-brand-fee {
  align-items: flex-end;
  position: relative;
}

#med-introduce .med-brand .med-brand-fee > p {
  font-size: 0.586667rem;
  color: #F34848;
}

#med-introduce .med-brand .med-brand-fee .discount-img {
  width: 100vw;
  background-image: url('../../../static/img/discount.png');
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0.266667rem;
  color: #fff;
}

#med-introduce .med-brand .med-brand-fee .discount-img > span {
  margin-left: 0.133333rem;
  text-decoration: line-through;
}

#med-introduce .med-brand .med-brand-fee .discount-hui {
  position: absolute;
  z-index: 999;
  right: 0.4rem;
}

#med-introduce .med-brand .med-brand-fee .discount-hui > p {
  line-height: 0.533333rem;
  text-align: center;
}

#med-introduce .med-brand .med-brand-fee .price {
  font-size: 0.32rem;
  color: #666;
  text-decoration: line-through;
  margin-left: 0.2rem;
  margin-bottom: 0.066667rem;
}

#med-introduce .med-brand .yaopin-all {
  display: flex;
  justify-content: space-between;
}

#med-introduce .med-brand .yaopin-all .yaopin-name {
  margin-top: 0.4rem;
  width: 80%;
}

#med-introduce .med-brand .yaopin-all .yaopin-name .chufang {
  min-width: 1.133333rem;
  height: 0.453333rem;
  margin-top: 0.466667rem;
  margin-right: 0.133333rem;
  padding: 0 0.133333rem 0 0.133333rem;
  background-color: #1F87FF;
  color: #FFFFFF;
}

#med-introduce .med-brand .yaopin-all .yaopin-name .xiangqing {
  height: 1.12rem;
  font-size: 0.426667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.8rem;
  color: #0B1523;
  margin-top: 0.266667rem;
  margin-bottom: -0.4rem;
}

#med-introduce .med-brand .yaopin-all .med-brand-title {
  width: 100%;
  box-sizing: border-box;
  height: 1.066667rem;
  margin-top: 0.266667rem;
  position: absolute;
  padding-left: 83%;
}

#med-introduce .med-brand .yaopin-all .med-brand-title > p {
  font-size: 0.373333rem;
  font-weight: bold;
  line-height: 0.56rem;
  color: #0b1523;
  font-family: Microsoft YaHei;
}

#med-introduce .med-brand .yaopin-all .med-brand-title .med-collet {
  font-size: 0.586667rem;
  color: #0B1523;
}

#med-introduce .med-brand .yaopin-all .med-brand-title > div.collected > .med-collet {
  color: #f34848;
}

#med-introduce .med-brand .yaopin-all .med-brand-title > div > p {
  font-size: 0.32rem;
  color: #87888D;
}

#med-introduce .med-vip {
  width: 9.173333rem;
  height: 0.64rem;
  background: linear-gradient(180deg, #2f3237 0%, #141518 100%);
  opacity: 1;
  color: #E6C3A0;
  padding: 0 0.266667rem;
  margin: 0.426667rem 0;
}

#med-introduce .med-vip span {
  font-size: 0.32rem;
}

#med-introduce .recipel-warn {
  width: 9.173333rem;
  height: 1.28rem;
  color: #777A80;
  font-size: 0.346667rem;
  line-height: 0.48rem;
}

#med-introduce .code-factory {
  width: 100%;
  height: 0.96rem;
}

#med-introduce .code-factory > p {
  font-size: 0.346667rem;
  color: #353A42;
}

#med-introduce .code-factory > span {
  font-size: 0.346667rem;
  color: #C2C5CB;
}

#med-save {
  width: 100%;
  background-color: white;
  color: #353A42;
  padding: 0.373333rem 0.373333rem 0.133333rem 0.373333rem;
  position: relative;
  margin-bottom: 0.266667rem;
}

#med-save > div {
  width: 100%;
}

#med-specs {
  overflow: hidden;
  width: 100%;
  height: 0.8rem;
  box-sizing: border-box;
  margin-bottom: 0.266667rem;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

#med-specs .med-tab2 > p {
  line-height: 0.8rem;
  margin-left: 0.4rem;
  margin-right: 0.266667rem;
}

#med-specs .med-tab2 > span {
  line-height: 0.8rem;
}

#med-specs .med-tab2 .have-youhui {
  margin-right: 0.266667rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

#med-specs .med-tab2 .have-youhui > span {
  margin-right: 0.266667rem;
  color: #FF3636;
}

#med-specs .med-tab2 > img {
  width: 1.8rem;
  margin-right: -0.533333rem;
}

#med-comment {
  width: 100%;
  min-height: 1.2rem;
  max-height: 5.826667rem;
  overflow: hidden;
  background-color: white;
  color: #87888D;
  padding: 0.4rem;
  margin-top: 0.266667rem;
  margin-bottom: 0.266667rem;
}

#med-comment .med-comment-title {
  width: 100%;
}

#med-comment .med-comment-title > div > span {
  font-size: 0.373333rem;
  color: #666666;
}

#med-comment .med-comment-title > div > p {
  font-size: 0.373333rem;
  color: #353A42;
  font-weight: bold;
}

#med-comment .med-comment-title > div > span {
  font-size: 0.32rem;
  color: #7A7A7A;
}

#med-comment .med-comment-title > div > i {
  font-size: 0.16rem;
  color: #7A7A7A;
}

#med-comment .med-comment-sort {
  width: 100%;
  margin-top: 0.373333rem;
}

#med-comment .med-comment-sort > div {
  width: auto;
  color: #353A42;
  margin-right: 0.266667rem;
  font-size: 0.32rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.133333rem;
  height: 0.586667rem;
  background: #E7F2FF;
  opacity: 1;
  border-radius: 0.053333rem;
}

#med-comment .med-customer-commit {
  width: 100%;
  margin-top: 0.373333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#med-comment .med-customer-commit .customer-head {
  margin-bottom: 0.4rem;
  height: 0.8rem;
}

#med-comment .med-customer-commit .customer-head .customer-img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  background-color: #87888D;
  margin-right: 0.266667rem;
}

#med-comment .med-customer-commit .customer-head .customer-img > img {
  width: 0.8rem;
  height: 0.8rem;
}

#med-comment .med-customer-commit .customer-head .customer-info {
  height: 100%;
  justify-content: space-around;
}

#med-comment .med-customer-commit .customer-head .customer-info > p {
  font-size: 0.346667rem;
  color: #353a42;
}

#med-comment .med-customer-commit .customer-head .customer-info > span {
  font-size: 0.266667rem;
  color: #c2c5cb;
}

#med-comment .med-customer-commit > p {
  margin-bottom: 0.533333rem;
  font-size: 0.32rem;
}

#med-belongshop {
  width: 100%;
  height: 2.493333rem;
  background: #ffffff;
  padding: 0.333333rem 0.266667rem;
  margin-bottom: 0.053333rem;
}

#med-belongshop > img {
  width: 1.866667rem;
  height: 1.866667rem;
  margin-right: 0.266667rem;
}

#med-belongshop > div > p {
  margin-bottom: 0.066667rem;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #0B1523;
}

#med-belongshop > div > span {
  font-size: 0.266667rem;
  color: #C2C5CB;
}

#med-belongshop-recommend {
  width: 100%;
  background: #ffffff;
  margin-bottom: 0.266667rem;
  padding: 0.346667rem 0.4rem;
}

#med-belongshop-recommend > p {
  font-size: 0.373333rem;
  color: #353A42;
  font-weight: bold;
  line-height: 0.8rem;
}

#med-desc {
  width: 100%;
  background-color: white;
  margin-top: 0.266667rem;
  padding: 0.346667rem 0.4rem 0.4rem 0.4rem;
}

#med-desc > p {
  font-size: 0.373333rem;
  color: #353A42;
  font-weight: bold;
  line-height: 0.8rem;
}

#med-desc > span {
  font-size: 0.373333rem;
  color: #353A42;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.8rem;
}

#med-desc > img {
  width: 9.24rem;
  height: 6.386667rem;
}

#med-desc .med-html-details img {
  display: block;
  width: 100%;
  height: auto;
}

.reback {
  position: fixed;
  bottom: 1.4rem;
  background-color: #FFD8D8;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FF3636;
  width: 100vw;
  line-height: 0.666667rem;
}

.reback > span {
  display: inline-block;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.med-buy-tab {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 0 0.026667rem #e1e1e1;
  display: flex;
  flex-direction: row-reverse;
}

.med-buy-tab .med-buy-btn {
  display: flex;
  flex: 1 1;
  flex-direction: row-reverse;
}

.med-buy-tab .med-buy-btn .med-down {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 0.373333rem;
  background-color: #C2C5CB;
}

.med-buy-tab .med-buy-btn1 {
  display: flex;
  justify-content: space-between;
}

.med-buy-tab .med-buy-btn1 .price {
  padding-top: 0.133333rem;
}

.med-buy-tab .med-buy-btn1 .price > p {
  line-height: 0.666667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
}

.med-buy-tab .med-buy-btn1 .price > p > span {
  color: #F34848;
}

.med-buy-tab .med-buy-btn1 .gouwucar {
  width: 2rem;
}

.med-buy-tab .med-buy-btn1 .gouwucar > img {
  margin-left: 0.266667rem;
  margin-top: 0.4rem;
  width: 0.8rem;
  height: 0.8rem;
}

.med-buy-tab .med-buy-btn1 .gouwucar > span {
  position: absolute;
  background-color: #F34848;
  min-width: 0.466667rem;
  min-height: 0.466667rem;
  line-height: 0.466667rem;
  text-align: center;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  border-radius: 50%;
  color: #fff;
  top: 0;
  margin-left: -0.266667rem;
}

.med-buy-tab .med-buy-btn1 .med-down {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 0.373333rem;
  background-color: #C2C5CB;
}

.med-buy-tab .med-button1,
.med-buy-tab .med-button2 {
  width: 2.826667rem;
  height: 1.493333rem;
  color: #FFFFFF;
}

.med-buy-tab .med-button1 > span,
.med-buy-tab .med-button2 > span {
  font-size: 0.373333rem;
}

.med-buy-tab .med-button1 {
  background-color: #2086FE;
}

.med-buy-tab .med-button2 {
  background-color: #FFB740;
}

.med-buy-tab .med-button3 {
  width: 2.826667rem;
  height: 1.493333rem;
  color: #FFFFFF;
  background-color: #f36d1f;
}

.med-buy-tab .med-button3 > span {
  font-size: 0.373333rem;
}

.med-buy-tab .med-button5 {
  width: 2.826667rem;
  height: 1.493333rem;
  color: #FFFFFF;
  background-color: #F34848;
}

.med-buy-tab .med-button5 > span {
  font-size: 0.373333rem;
}

.med-buy-tab .med-icon {
  width: 4.586667rem;
}

.med-buy-tab .med-icon ul {
  height: 1.333333rem;
  padding-top: 0.173333rem;
  width: 100%;
}

.med-buy-tab .med-icon ul li {
  float: left;
  text-align: center;
  width: 33%;
  color: #7F7F7F;
  font-size: 0.213333rem;
}

.med-buy-tab .med-icon ul li i {
  display: block;
  font-size: 0.533333rem;
  color: #7F7F7F;
}

.med-buy-tab .med-icon ul li span {
  font-size: 0.266667rem;
}

.youhuijuan {
  position: fixed;
  padding: 0.4rem;
  overflow: scroll;
}

.youhuijuan .weeks {
  font-size: 0.373333rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.youhuijuan .youhuiquan-nouse {
  width: 92vw;
  padding: 0.266667rem;
  margin-bottom: 0.266667rem;
  background-image: url('../../../static/img/nouse.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
}

.youhuijuan .youhuiquan-nouse .money {
  color: #fff;
  line-height: 1.2rem;
  width: 1.2rem;
}

.youhuijuan .youhuiquan-nouse .money > span {
  font-size: 0.666667rem;
}

.youhuijuan .youhuiquan-nouse .date-show {
  display: flex;
  flex-direction: column;
  width: 65%;
  text-align: center;
}

.youhuijuan .youhuiquan-nouse .date-show .date-show-top {
  margin-bottom: 0.266667rem;
  text-align: center;
  line-height: 0.4rem;
  color: #fff;
  font-size: 0.346667rem;
  letter-spacing: 0.026667rem;
}

.youhuijuan .youhuiquan-nouse .date-show .date-show-bottom {
  color: #fff;
}

.youhuijuan .youhuiquan-nouse .quan-click {
  line-height: 1.2rem;
  color: #fff;
  font-size: 0.4rem;
  width: 2.4rem;
  margin-right: -0.133333rem;
  text-align: center;
}

.youhuijuan .youhuiquan-nouse1 {
  background-image: url('../../../static/img/alreadyuse.svg');
}