.nav-footer {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 1.066667rem rgba(168, 174, 188, 0.3);
  display: flex;
  justify-content: space-evenly;
}

.nav-footer-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1.28rem;
  width: 1.066667rem;
  position: relative;
}

.nav-footer-tab .nav-footer-tab-p {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 50%;
  top: -0.133333rem;
  right: 0;
  background-color: #FF5555;
  color: white;
  font-size: 0.213333rem;
  text-align: center;
}

.nav-footer-tab > span {
  font-size: 0.16rem !important;
  color: #7F7F7F;
}

.nav-footer-tab div {
  height: 0.533333rem;
  width: 0.533333rem;
}

.nav-footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1.28rem;
}

.nav-footer-inner .nav-3 {
  width: 0.72rem;
  height: 0.72rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: 0 auto;
  background-image: url('../../static/img/nav/3.png');
}

.nav-footer ul {
  height: 1.28rem;
  padding-top: 0.173333rem;
}

.nav-footer ul li {
  float: left;
  text-align: center;
  width: 20%;
}

.nav-footer ul li a {
  font-size: 0.266667rem;
}

.nav-footer ul li i {
  display: block;
  font-size: 0.533333rem;
  color: #7F7F7F;
}

.nav-footer ul li span {
  font-size: 0.32rem;
  color: #7F7F7F;
}

.selectednav {
  color: #1f87ff;
  position: relative;
}

.selectednav i {
  color: #1f87ff !important;
}

.nav-1 div,
.nav-2 div,
.nav-4 div,
.nav-5 div,
.nav-a div,
.nav-b div,
.nav-d div,
.nav-e div {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 0.133333rem;
}

.nav-a div {
  background-image: url('../../static/img/nav/a.png');
}

.nav-b div {
  background-image: url('../../static/img/nav/b.png');
}

.nav-d div {
  background-image: url('../../static/img/nav/d.png');
}

.nav-e div {
  background-image: url('../../static/img/nav/e.png');
}

.nav-1 div {
  background-image: url('../../static/img/nav/1.png') !important;
}

.nav-2 div {
  background-image: url('../../static/img/nav/2.png');
}

.nav-4 div {
  background-image: url('../../static/img/nav/4.png');
}

.nav-5 div {
  background-image: url('../../static/img/nav/5.png');
}