body {
  margin: 0;
  padding: 0;
  background-color: #EEF1F5;
}

body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.32rem;
}

input {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-fix:after {
  display: table;
  content: '';
  clear: both;
}

.active {
  color: #ff5555;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.flex-apart-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-apart-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flexr {
  display: flex;
  flex-direction: row;
}

.flexc {
  display: flex;
  flex-direction: column;
}

.flexr-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexc-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-msg {
  color: #f50;
  padding-left: 0.133333rem;
  padding-top: 0.133333rem;
}

.white-bg {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0.64rem;
  background-color: #EEF1F5;
  overflow: scroll;
}

.screen-home {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0.64rem;
  background-color: #EEF1F5;
  overflow: scroll;
}

.screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.946667rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.col-line {
  height: 0.013333rem;
  width: 100%;
  margin: 0 0.066667rem;
  background-color: #DDDFE5;
}

.quanquan {
  width: 0.2rem;
  height: 0.2rem;
  border: 0.026667rem solid #666666;
  border-radius: 50%;
}

.bottom-button {
  position: fixed;
  width: 100%;
  height: 0.653333rem;
  bottom: 0;
  left: 0;
  color: #FFFFFF;
  font-size: 0.213333rem;
  background: #1F87FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.am-toast-notice .am-toast-text div {
  font-size: 0.373333rem;
}