.select-address {
  width: 8.8rem;
  height: 1.133333rem;
  margin: 0.533333rem 0.6rem;
}

.select-address > img {
  width: 8.8rem;
  height: 1.133333rem;
}

.select-address .shouhuo-address {
  text-align: center;
  margin-top: -0.8rem;
}

.select-address .shouhuo-address > span {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2086FE;
  margin-right: 0.266667rem;
}

.select-address .shouhuo-address > img {
  width: 0.133333rem;
  height: 0.2rem;
}

.sex {
  position: absolute;
  z-index: 99;
  left: 6.666667rem;
  top: 3.733333rem;
}

.sex > input {
  margin-right: 0.133333rem;
}

.sex .write-sex2 {
  margin-left: 0.4rem;
}

.sex2 {
  position: absolute;
  z-index: 99;
  left: 6.666667rem;
  top: 2.866667rem;
}

.sex2 > input {
  margin-right: 0.133333rem;
}

.sex2 .write2-sex2 {
  margin-left: 0.4rem;
}

.xian {
  height: 0.026667rem;
  background: #EEF1F5;
  width: 9.333333rem;
}

.addr-default {
  width: 100%;
  z-index: 10;
  top: 14.266667rem;
  height: 2.666667rem;
  padding: 0 0.4rem;
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.addr-default .addr-tip {
  color: #353A42;
}

.addr-default .addr-tip > div:first-child {
  font-size: 0.373333rem;
}

.addr-default .addr-tip > div:last-child {
  margin-top: 0.266667rem;
  font-size: 0.32rem;
}

.addr-default .am-list-body {
  height: 1.333333rem !important;
  width: 100%;
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  position: fixed;
  width: 100%;
  z-index: 100;
  bottom: 0.4rem;
  display: flex;
  flex-direction: column;
}

.btn > div {
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
}

.btn > div:first-child {
  width: 93%;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #ff0000;
  border: 0.013333rem solid #ddd;
}

.btn > div:last-child {
  width: 93%;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #1F87FF;
  color: #fff;
}

.am-list .am-list-body {
  width: 10rem;
  margin-left: 0;
  height: 80vh;
}

.am-list .am-list-body .am-list-item .am-input-label {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  padding-left: 0.133333rem;
}

.am-list .am-list-body .am-list-item .am-input-control > input {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #D8D8D8;
}

.del-address-outer2 {
  position: absolute;
  width: 100vw;
  height: 27.466667rem;
  z-index: 998;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.del-address-outer2 .del-address {
  position: absolute;
  z-index: 999;
  width: 6rem;
  height: 2.933333rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address-outer2 .del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address-outer2 .del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address-outer2 .del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address-outer2 .del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.moren {
  width: 100%;
  height: 2.666667rem;
  margin-top: 1.333333rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
}

.cun {
  width: 8.8rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 5;
  top: 18.666667rem;
  height: 1.146667rem;
  border-radius: 0.573333rem;
  line-height: 1.146667rem;
  text-align: center;
  color: white;
  font-size: 0.4rem;
  background-color: #2086FE;
}

.chioseaddress {
  width: 100%;
  padding: 0.4rem;
}

.chioseaddress select {
  width: 25%;
  margin-right: 0.066667rem;
}