.medlist-container {
  padding-top: 1.68rem;
  width: 100%;
  box-sizing: border-box;
  width: 100vw;
}

.medlist-container #common-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.medlist-container .am-tabs-pane-wrap {
  width: auto;
}

.medlist-container .am-tabs .am-tabs-tab-bar-wrap * {
  font-size: 0.373333rem;
}

.medlist-container .ill-info {
  width: 100%;
  height: 3.2rem;
  background: #1F87FF;
  color: #FFFFFF;
  padding: 0.32rem 0.72rem;
}

.medlist-container .ill-info p {
  font-size: 0.373333rem;
  line-height: 0.8rem;
}

.medlist-container .ill-info span {
  line-height: 0.533333rem;
  font-size: 0.32rem;
}