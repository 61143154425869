.outer1 {
  width: 10rem;
  padding-bottom: 1.333333rem;
  background-color: #F3F3F3;
}

.outer1 > header {
  width: 10rem;
  height: 3.013333rem;
  background: #FF5555;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.466667rem 0;
}

.outer1 > header > h2 {
  font-size: 0.506667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}

.outer1 > header .step {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
}

.outer1 > header .step > span {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.6;
}

.outer1 > header .step .cg-news {
  opacity: 1;
}

.outer1 > header .step .xian {
  width: 0.8rem;
  height: 0;
  border: 0.026667rem solid #FFFFFF;
  opacity: 0.6;
  margin: 0 0.133333rem;
  margin-top: 0.2rem;
}

.outer1 .certificates {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-top: 0.666667rem;
  text-align: center;
}

.outer1 .license,
.outer1 .drug-business-license,
.outer1 .food-business-license,
.outer1 .medical-business-license,
.outer1 .Network-sales-of-medical-devices,
.outer1 .medical-device-business,
.outer1 .purchasing-personnel-idcard,
.outer1 .purchase-power-of-attorney {
  width: 9.2rem;
  height: 5.24rem;
  background: #FFFFFF;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  padding: 0.4rem;
}

.outer1 .license .business,
.outer1 .drug-business-license .business,
.outer1 .food-business-license .business,
.outer1 .medical-business-license .business,
.outer1 .Network-sales-of-medical-devices .business,
.outer1 .medical-device-business .business,
.outer1 .purchasing-personnel-idcard .business,
.outer1 .purchase-power-of-attorney .business {
  margin-bottom: 0.2rem;
}

.outer1 .license .business .must,
.outer1 .drug-business-license .business .must,
.outer1 .food-business-license .business .must,
.outer1 .medical-business-license .business .must,
.outer1 .Network-sales-of-medical-devices .business .must,
.outer1 .medical-device-business .business .must,
.outer1 .purchasing-personnel-idcard .business .must,
.outer1 .purchase-power-of-attorney .business .must {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF5555;
  margin: 0.133333rem;
  margin-left: -0.266667rem;
}

.outer1 .license .business .dobusiness,
.outer1 .drug-business-license .business .dobusiness,
.outer1 .food-business-license .business .dobusiness,
.outer1 .medical-business-license .business .dobusiness,
.outer1 .Network-sales-of-medical-devices .business .dobusiness,
.outer1 .medical-device-business .business .dobusiness,
.outer1 .purchasing-personnel-idcard .business .dobusiness,
.outer1 .purchase-power-of-attorney .business .dobusiness {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
}

.outer1 .license .am-list-item.am-input-item,
.outer1 .drug-business-license .am-list-item.am-input-item,
.outer1 .food-business-license .am-list-item.am-input-item,
.outer1 .medical-business-license .am-list-item.am-input-item,
.outer1 .Network-sales-of-medical-devices .am-list-item.am-input-item,
.outer1 .medical-device-business .am-list-item.am-input-item,
.outer1 .purchasing-personnel-idcard .am-list-item.am-input-item,
.outer1 .purchase-power-of-attorney .am-list-item.am-input-item {
  height: 0.8rem !important;
}

.outer1 .license .am-list-item,
.outer1 .drug-business-license .am-list-item,
.outer1 .food-business-license .am-list-item,
.outer1 .medical-business-license .am-list-item,
.outer1 .Network-sales-of-medical-devices .am-list-item,
.outer1 .medical-device-business .am-list-item,
.outer1 .purchasing-personnel-idcard .am-list-item,
.outer1 .purchase-power-of-attorney .am-list-item {
  border: 0.013333rem solid #ddd;
  min-height: 0.4rem !important;
  margin: 0.066667rem 0;
}

.outer1 .license .am-list-item .am-list-line .am-input-control > input,
.outer1 .drug-business-license .am-list-item .am-list-line .am-input-control > input,
.outer1 .food-business-license .am-list-item .am-list-line .am-input-control > input,
.outer1 .medical-business-license .am-list-item .am-list-line .am-input-control > input,
.outer1 .Network-sales-of-medical-devices .am-list-item .am-list-line .am-input-control > input,
.outer1 .medical-device-business .am-list-item .am-list-line .am-input-control > input,
.outer1 .purchasing-personnel-idcard .am-list-item .am-list-line .am-input-control > input,
.outer1 .purchase-power-of-attorney .am-list-item .am-list-line .am-input-control > input {
  font-size: 0.346667rem;
}

.outer1 .license .begin-end-time,
.outer1 .drug-business-license .begin-end-time,
.outer1 .food-business-license .begin-end-time,
.outer1 .medical-business-license .begin-end-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time,
.outer1 .medical-device-business .begin-end-time,
.outer1 .purchasing-personnel-idcard .begin-end-time,
.outer1 .purchase-power-of-attorney .begin-end-time {
  display: flex;
}

.outer1 .license .begin-end-time .begin-time,
.outer1 .drug-business-license .begin-end-time .begin-time,
.outer1 .food-business-license .begin-end-time .begin-time,
.outer1 .medical-business-license .begin-end-time .begin-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time .begin-time,
.outer1 .medical-device-business .begin-end-time .begin-time,
.outer1 .purchasing-personnel-idcard .begin-end-time .begin-time,
.outer1 .purchase-power-of-attorney .begin-end-time .begin-time,
.outer1 .license .begin-end-time .end-time,
.outer1 .drug-business-license .begin-end-time .end-time,
.outer1 .food-business-license .begin-end-time .end-time,
.outer1 .medical-business-license .begin-end-time .end-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time .end-time,
.outer1 .medical-device-business .begin-end-time .end-time,
.outer1 .purchasing-personnel-idcard .begin-end-time .end-time,
.outer1 .purchase-power-of-attorney .begin-end-time .end-time {
  width: 2.92rem;
  height: 0.786667rem;
  margin-top: 0.133333rem;
  background: #FFFFFF;
  border: 0.013333rem solid #C8C8C8;
  border-radius: 0.066667rem;
}

.outer1 .license .begin-end-time .begin-time .select-btn,
.outer1 .drug-business-license .begin-end-time .begin-time .select-btn,
.outer1 .food-business-license .begin-end-time .begin-time .select-btn,
.outer1 .medical-business-license .begin-end-time .begin-time .select-btn,
.outer1 .Network-sales-of-medical-devices .begin-end-time .begin-time .select-btn,
.outer1 .medical-device-business .begin-end-time .begin-time .select-btn,
.outer1 .purchasing-personnel-idcard .begin-end-time .begin-time .select-btn,
.outer1 .purchase-power-of-attorney .begin-end-time .begin-time .select-btn,
.outer1 .license .begin-end-time .end-time .select-btn,
.outer1 .drug-business-license .begin-end-time .end-time .select-btn,
.outer1 .food-business-license .begin-end-time .end-time .select-btn,
.outer1 .medical-business-license .begin-end-time .end-time .select-btn,
.outer1 .Network-sales-of-medical-devices .begin-end-time .end-time .select-btn,
.outer1 .medical-device-business .begin-end-time .end-time .select-btn,
.outer1 .purchasing-personnel-idcard .begin-end-time .end-time .select-btn,
.outer1 .purchase-power-of-attorney .begin-end-time .end-time .select-btn {
  display: inline-block;
  height: 0.786667rem;
  line-height: 0.786667rem;
  width: 2.92rem;
  padding-left: 0.4rem;
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #D8D8D8;
}

.outer1 .license .begin-end-time .begin-time .select-time,
.outer1 .drug-business-license .begin-end-time .begin-time .select-time,
.outer1 .food-business-license .begin-end-time .begin-time .select-time,
.outer1 .medical-business-license .begin-end-time .begin-time .select-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time .begin-time .select-time,
.outer1 .medical-device-business .begin-end-time .begin-time .select-time,
.outer1 .purchasing-personnel-idcard .begin-end-time .begin-time .select-time,
.outer1 .purchase-power-of-attorney .begin-end-time .begin-time .select-time,
.outer1 .license .begin-end-time .end-time .select-time,
.outer1 .drug-business-license .begin-end-time .end-time .select-time,
.outer1 .food-business-license .begin-end-time .end-time .select-time,
.outer1 .medical-business-license .begin-end-time .end-time .select-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time .end-time .select-time,
.outer1 .medical-device-business .begin-end-time .end-time .select-time,
.outer1 .purchasing-personnel-idcard .begin-end-time .end-time .select-time,
.outer1 .purchase-power-of-attorney .begin-end-time .end-time .select-time {
  margin-top: 0.16rem;
  font-size: 0.346667rem;
  margin-left: 0.4rem;
}

.outer1 .license .begin-end-time .time-xian,
.outer1 .drug-business-license .begin-end-time .time-xian,
.outer1 .food-business-license .begin-end-time .time-xian,
.outer1 .medical-business-license .begin-end-time .time-xian,
.outer1 .Network-sales-of-medical-devices .begin-end-time .time-xian,
.outer1 .medical-device-business .begin-end-time .time-xian,
.outer1 .purchasing-personnel-idcard .begin-end-time .time-xian,
.outer1 .purchase-power-of-attorney .begin-end-time .time-xian {
  width: 0.8rem;
  height: 0;
  border: 0.026667rem solid #D8D8D8;
  margin: 0 0.133333rem;
  margin-top: 0.466667rem;
}

.outer1 .license .begin-end-time .long-time,
.outer1 .drug-business-license .begin-end-time .long-time,
.outer1 .food-business-license .begin-end-time .long-time,
.outer1 .medical-business-license .begin-end-time .long-time,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time,
.outer1 .medical-device-business .begin-end-time .long-time,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time {
  line-height: 0.8rem;
  width: 1.866667rem;
}

.outer1 .license .begin-end-time .long-time .am-list-item,
.outer1 .drug-business-license .begin-end-time .long-time .am-list-item,
.outer1 .food-business-license .begin-end-time .long-time .am-list-item,
.outer1 .medical-business-license .begin-end-time .long-time .am-list-item,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time .am-list-item,
.outer1 .medical-device-business .begin-end-time .long-time .am-list-item,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time .am-list-item,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time .am-list-item {
  padding-left: 0;
}

.outer1 .license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .drug-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .food-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .medical-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .medical-device-business .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time .am-list-item .am-list-thumb:first-child,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time .am-list-item .am-list-thumb:first-child {
  margin-right: 0;
}

.outer1 .license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .drug-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .food-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .medical-business-license .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .medical-device-business .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time .am-list-item .am-list-thumb:first-child .am-checkbox-wrapper {
  margin-left: 0.133333rem;
}

.outer1 .license .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .drug-business-license .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .food-business-license .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .medical-business-license .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .medical-device-business .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time .am-list-item .am-list-line,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time .am-list-item .am-list-line {
  margin-left: 0.133333rem;
}

.outer1 .license .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .drug-business-license .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .food-business-license .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .medical-business-license .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .Network-sales-of-medical-devices .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .medical-device-business .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .purchasing-personnel-idcard .begin-end-time .long-time .am-list-item.am-list-item-active,
.outer1 .purchase-power-of-attorney .begin-end-time .long-time .am-list-item.am-list-item-active {
  background-color: #fff;
}

.outer1 .license .sendcomment-photo,
.outer1 .drug-business-license .sendcomment-photo,
.outer1 .food-business-license .sendcomment-photo,
.outer1 .medical-business-license .sendcomment-photo,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo,
.outer1 .medical-device-business .sendcomment-photo,
.outer1 .purchasing-personnel-idcard .sendcomment-photo,
.outer1 .purchase-power-of-attorney .sendcomment-photo {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  margin: 0.4rem;
  margin-top: 0.666667rem;
}

.outer1 .license .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .food-business-license .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker .am-image-picker-list,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker .am-image-picker-list {
  padding: 0;
}

.outer1 .license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .food-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: -0.266667rem;
  left: -0.4rem;
}

.outer1 .license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .food-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item {
  background: #F8F8F8 url('../../../static/img/app/addpic.png') no-repeat center;
  background-size: 30%;
  position: absolute;
  z-index: 99;
  background-color: transparent;
}

.outer1 .license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .food-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input {
  width: 1.6rem;
  height: 1.6rem;
}

.outer1 .license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .food-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .drug-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .food-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .medical-business-license .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .medical-device-business .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .purchasing-personnel-idcard .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .purchase-power-of-attorney .sendcomment-photo .am-image-picker-list .am-image-picker-upload-btn:after {
  display: none;
}

.outer1 .license .sendcomment-photo > p,
.outer1 .drug-business-license .sendcomment-photo > p,
.outer1 .food-business-license .sendcomment-photo > p,
.outer1 .medical-business-license .sendcomment-photo > p,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo > p,
.outer1 .medical-device-business .sendcomment-photo > p,
.outer1 .purchasing-personnel-idcard .sendcomment-photo > p,
.outer1 .purchase-power-of-attorney .sendcomment-photo > p {
  position: absolute;
  z-index: 100;
  top: 0.933333rem;
  left: -0.266667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  font-family: MicrosoftYaHei;
  color: #333333;
  height: 0.32rem;
}

.outer1 .license .sendcomment-photo-back,
.outer1 .drug-business-license .sendcomment-photo-back,
.outer1 .food-business-license .sendcomment-photo-back,
.outer1 .medical-business-license .sendcomment-photo-back,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back,
.outer1 .medical-device-business .sendcomment-photo-back,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  margin: 0.4rem;
  top: -3.066667rem;
  left: 2.133333rem;
}

.outer1 .license .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker .am-image-picker-list,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker .am-image-picker-list {
  padding: 0;
}

.outer1 .license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 0.8rem;
  left: -0.4rem;
}

.outer1 .license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item {
  background: #F8F8F8 url('../../../static/img/app/addpic.png') no-repeat center;
  background-size: 30%;
  position: absolute;
  z-index: 99;
  background-color: transparent;
}

.outer1 .license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input {
  width: 1.6rem;
  height: 1.6rem;
}

.outer1 .license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:before,
.outer1 .license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .drug-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .food-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .medical-business-license .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .medical-device-business .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back .am-image-picker-list .am-image-picker-upload-btn:after {
  display: none;
}

.outer1 .license .sendcomment-photo-back > p,
.outer1 .drug-business-license .sendcomment-photo-back > p,
.outer1 .food-business-license .sendcomment-photo-back > p,
.outer1 .medical-business-license .sendcomment-photo-back > p,
.outer1 .Network-sales-of-medical-devices .sendcomment-photo-back > p,
.outer1 .medical-device-business .sendcomment-photo-back > p,
.outer1 .purchasing-personnel-idcard .sendcomment-photo-back > p,
.outer1 .purchase-power-of-attorney .sendcomment-photo-back > p {
  position: absolute;
  z-index: 100;
  top: 1.933333rem;
  left: -0.266667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  font-family: MicrosoftYaHei;
  color: #333333;
  height: 0.32rem;
}

.outer1 .purchase-power-of-attorney {
  height: 4.186667rem;
}

.outer1 .upload-word {
  width: 2.666667rem;
  margin-left: 2rem;
  margin-top: -2.266667rem;
}

.outer1 .upload-word > p {
  line-height: 0.466667rem;
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.outer1 .upload-word1 {
  width: 2.666667rem;
  margin-left: 4rem;
  margin-top: -4.266667rem;
}

.outer1 .upload-word1 > p {
  line-height: 0.466667rem;
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.outer1 .next {
  margin-top: 1.333333rem;
  height: 1.133333rem;
}

.outer1 .next > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.outer1 .next > span {
  position: absolute;
  left: 4.533333rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.Modal-Portal .datepicker-modal {
  position: fixed !important;
}