.passbox {
  width: 100%;
  height: 100vh;
  background-color: white;
}

.passbox .passbox-top {
  width: 100%;
  height: 1.64rem;
  line-height: 1.64rem;
  margin-bottom: 0.533333rem;
  border-bottom: 0.013333rem solid #d6d5d5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passbox .passbox-top img {
  width: 0.64rem;
  height: 0.64rem;
  vertical-align: middle;
  position: absolute;
  left: 0.066667rem;
}

.passbox .passbox-top span {
  display: inline-block;
  font-size: 0.48rem;
}

.passbox .passbox-text {
  width: 80%;
  height: 1.533333rem;
  margin: 0 auto;
  border-bottom: 0.013333rem solid #EEF1F5;
  padding: 0.266667rem;
  position: relative;
}

.passbox .passbox-text div {
  width: 100%;
  position: absolute;
  bottom: 0.24rem;
}

.passbox .passbox-text div img {
  width: 0.533333rem;
  height: 0.466667rem;
  position: absolute;
  right: 0.266667rem;
  bottom: 0;
}

.passbox .passbox-text ::-webkit-input-placeholder {
  color: #D8D8D8;
  font-size: 0.346667rem;
}

.passbox .passbox-text input {
  border: none;
  height: 0.533333rem;
  width: 60%;
  overflow: scroll;
}

.passbox .passbox-text span {
  display: inline-block;
  width: 25%;
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.passbox .passbox-btn1 {
  width: 8.8rem;
  display: block;
  height: 1.12rem;
  border-radius: 0.56rem;
  background-color: #a6c1Fa;
  margin: 0 auto;
  border: none;
  color: #FFFFFF;
  font-size: 0.426667rem;
}

.passbox .passbox-btn2 {
  width: 8.8rem;
  display: block;
  height: 1.12rem;
  border-radius: 0.56rem;
  background-color: #2086FE;
  color: #FFFFFF;
  font-size: 0.426667rem;
  margin: 0 auto;
  border: none;
}

.passbox .Rpass {
  position: absolute;
  color: #2086FE;
  right: 0.933333rem;
  top: 7.2rem;
}