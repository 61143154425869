.search-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
  z-index: 2;
}

.to-bottom {
  height: 100%;
}

.search-part {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.search-part .search-title {
  width: 100%;
  padding: 0.346667rem 0.426667rem 0.08rem 0.426667rem;
  color: #666666;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-part .search-title i {
  font-size: 0.48rem;
  color: #333333;
}

.search-part .search-title > span {
  font-size: 0.426667rem !important;
}