.infortop {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.infortop img {
  width: 0.64rem;
  height: 0.64rem;
}

.infortop h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.infortext {
  width: 100%;
  height: 1.306667rem;
  box-sizing: border-box;
  padding-left: 0.4rem;
  line-height: 1.306667rem;
  color: #333333;
  font-size: 0.373333rem;
  border-bottom: 0.013333rem solid #F3F3F3;
  background-color: #FFFFFF;
}

.infortext span:first-child {
  display: inline-block;
  width: 15%;
  margin-right: 0.8rem;
}

.inforbottom {
  height: 1.986667rem;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-left: 0.4rem;
  padding-right: 0.693333rem;
  border-bottom: 0.013333rem solid #F3F3F3;
  background-color: #FFFFFF;
  flex-wrap: nowrap;
  align-items: center;
}

.inforbottom p:first-child {
  width: 18%;
  margin-right: 0.8rem;
}

.inforbottom p:last-child {
  line-height: 0.56rem;
}