#result {
  position: absolute;
  top: 1.866667rem;
  left: 0;
}

#result input {
  height: 0.666667rem;
  box-sizing: border-box;
  margin-right: 0.133333rem;
}

#result button {
  height: 0.666667rem;
  box-sizing: border-box;
}