.am-whitespace.am-whitespace-md {
  height: 0;
}

.order-whole {
  height: 5.466667rem;
  background-color: #fff;
  margin: 0 0.133333rem;
  margin-top: 0.266667rem;
  border-radius: 0.133333rem;
  position: relative;
  padding: 0.133333rem 0.4rem;
}

.order-whole .order-alone {
  width: 100%;
  padding: 0.266667rem 0;
  display: flex;
  flex-direction: column;
  color: #353A42;
}

.order-whole .order-alone .order1-left {
  display: flex;
  flex-direction: column;
  height: 3.6rem;
}

.order-whole .order-alone .order1-left .order-shopname {
  height: 1.333333rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #EEF1F5;
}

.order-whole .order-alone .order1-left .order-shopname > img {
  width: 1.066667rem;
  height: 1.066667rem;
  background-color: #efefef;
  border-radius: 50%;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left {
  margin-left: -1.333333rem;
  width: 5.333333rem;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in {
  display: flex;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in > p {
  height: 1.066667rem;
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 1.066667rem;
  color: #0B1523;
  max-width: 50vw;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in i {
  width: 0.146667rem;
  height: 0.266667rem;
  line-height: 1.066667rem;
  color: #0B1523;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in1 {
  display: flex;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in1 > p {
  height: 0.666667rem;
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #0B1523;
  width: 50vw;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .order-shopname-in1 i {
  width: 0.146667rem;
  height: 0.266667rem;
  line-height: 0.8rem;
  color: #0B1523;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left > span {
  display: inline-block;
  height: 0.453333rem;
  margin-top: 0.133333rem;
  padding: 0.026667rem 0.133333rem;
  border: 0.013333rem solid #FF341D;
  border-radius: 0.026667rem;
  color: #FF341D;
}

.order-whole .order-alone .order1-left .order-shopname .order-shopname-left .dayang {
  display: inline-block;
  height: 0.453333rem;
  margin-top: 0.133333rem;
  padding: 0.026667rem 0.133333rem;
  border: 0.013333rem solid #aaa;
  border-radius: 0.026667rem;
  color: #aaa;
}

.order-whole .order-alone .order1-left .flexr::-webkit-scrollbar {
  display: none;
}

.order-whole .order-alone .order1-left .flexr {
  overflow: auto;
}

.order-whole .order-alone .order1-left .flexr .med-col {
  margin-top: 0.213333rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend {
  display: flex;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left {
  display: flex;
  justify-content: start;
  overflow-y: hidden;
  width: 87%;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left .myorder-img {
  width: 2rem;
  height: 2rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left .myorder-img img {
  width: 100%;
  height: 100%;
  margin-right: 0.133333rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left .med-detail {
  justify-content: center;
  margin-left: 0.266667rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left .med-detail > p {
  font-size: 0.346667rem;
  line-height: 0.4rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .myorder-contend-left .med-detail > span {
  font-size: 0.32rem;
  line-height: 0.4rem;
  margin-top: 0.106667rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .order-show-num {
  width: 13%;
  position: absolute;
  right: 0.4rem;
  background: #FFFFFF;
  opacity: 0.8;
  height: 1.946667rem;
  justify-content: center;
  color: #666666;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .order-show-num > p {
  font-size: 0.373333rem;
  margin-bottom: 0.16rem;
  color: #353A42;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend .order-show-num > span {
  font-size: 0.32rem;
  color: #666;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 {
  margin-right: 0.4rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 .myorder-contend-left1 {
  width: 1.333333rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 .myorder-contend-left1 .myorder-img {
  width: 1.333333rem;
  height: 1.333333rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 .myorder-contend-left1 .myorder-img img {
  width: 100%;
  height: 100%;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 .myorder-contend-left1 .med-detail {
  margin-top: 0.133333rem;
  width: 1.333333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.order-whole .order-alone .order1-left .flexr .med-col .myorder-contend1 .myorder-contend-left1 .med-detail > span {
  font-size: 0.32rem;
  line-height: 0.4rem;
  margin-top: 0.133333rem;
}

.order-whole .order-alone .order1-left .flexr .med-col .order-show-num1 {
  position: absolute;
  right: 0;
  top: 1.866667rem;
  width: 1.786667rem;
  height: 2.066667rem;
  text-align: center;
  padding-top: 0.666667rem;
  background: #FFFFFF;
  opacity: 0.3;
  height: 1.946667rem;
  justify-content: center;
  color: #666666;
}

.order-whole .order-alone .order1-left .flexr .med-col .order-show-num1 > p {
  font-size: 0.373333rem;
  margin-bottom: 0.16rem;
  color: #353A42;
}

.order-whole .order-alone .order1-left .flexr .med-col .order-show-num1 > span {
  font-size: 0.32rem;
  -webkit-transform: scale(0.88);
  transform: scale(0.88);
  color: #666666;
}

.order-whole .order-alone .order-right {
  height: 2.746667rem;
  justify-content: space-between;
  align-items: flex-end;
}

.order-whole .order-alone .order-right .order-state-row {
  display: flex;
  flex-direction: row-reverse;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.order-whole .order-alone .order-right .order-state-row > i {
  font-size: 0.533333rem;
}

.order-whole .order-alone .order-right .order-state-row > span {
  color: #FF8D30;
  line-height: 1.066667rem;
}

.order-whole .order-alone .order-right .order-state-row .quxiao {
  color: #999;
}

.order-whole .order-alone .order-right .order-state-row .order-state {
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 1.066667rem;
  border: 0.013333rem solid #BDBEC9;
  color: #BDBEC9;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.order-whole .order-alone .order-right .order-state-row .order-state .order-state-star {
  padding: 0 0.266667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.order-whole .order-alone .order-right .order-state-row .order-state i {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.order-whole .order-alone .order-right .order-state-row .order-state .order-state-line {
  width: 0.48rem;
  height: 0.053333rem;
  margin-top: 0.053333rem;
  background-color: #BDBEC9;
}

.order-whole .order-alone .order-right .order-state-row .refund-apply {
  color: #f50;
}

.order-whole .order-fuction {
  width: 100%;
  height: 1.36rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.133333rem;
  padding-right: 0.4rem;
}

.order-whole .order-fuction .order-fuction-contend {
  width: 100%;
  display: flex;
}

.order-whole .order-fuction .order-fuction-contend > p {
  width: 50%;
  margin-top: 0.266667rem;
  color: #999999;
}

.order-whole .order-fuction .order-fuction-contend > div {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.order-whole .order-fuction .order-fuction-contend > div p {
  margin-left: 0.666667rem;
  width: 1.6rem;
  height: 0.64rem;
  line-height: 0.613333rem;
  text-align: center;
  color: #353A42;
  border-radius: 0.066667rem;
  margin-right: -0.4rem;
}

.order-whole .order-fuction .order-fuction-contend > div .a {
  border: 0.013333rem solid #BBBBBB;
}

.order-whole .order-fuction .order-fuction-contend > div .b {
  background: linear-gradient(133deg, #55A4FE 0%, #297FE3 100%);
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.order-whole .order-fllow {
  width: 100%;
  height: 1.066667rem;
  align-items: center;
  padding: 0 0.24rem;
}

.order-whole .order-fllow > h1 {
  font-size: 0.346667rem;
}

.order-whole .order-fllow > span {
  font-size: 0.24rem;
}

.order-whole1 {
  background-color: #fff;
  margin: 0 0.133333rem;
  top: 0;
  margin-top: 0.266667rem;
  border-radius: 0.133333rem;
  position: relative;
  padding: 0.133333rem 0.4rem;
}

.order-whole1 .order-alone1 {
  width: 100%;
  padding: 0.266667rem 0;
  display: flex;
  flex-direction: column;
  color: #353A42;
}

.order-whole1 .order-alone1 .order1-left1 {
  display: flex;
  flex-direction: column;
  height: 3.6rem;
}

.order-whole1 .order-alone1 .order1-left1 .order-shopname1 {
  height: 0.8rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #EEF1F5;
}

.order-whole1 .order-alone1 .order1-left1 .order-shopname1 .order-shopname1-l {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.order-whole1 .order-alone1 .order1-left1 .order-shopname1 .order-right1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF5555;
}

.order-whole1 .order-alone1 .order1-left1 .flexr::-webkit-scrollbar {
  display: none;
}

.order-whole1 .order-alone1 .order1-left1 .flexr-pifa {
  padding: 0.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2.4rem;
  border-bottom: 0.026667rem solid #E5E5E5;
}

.order-whole1 .order-alone1 .order1-left1 .flexr-pifa > p {
  display: flex;
  justify-content: space-between;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div {
  display: flex;
  justify-content: space-between;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div .right-pifa {
  width: 3.466667rem;
  display: flex;
  justify-content: space-between;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div .a {
  width: 1.6rem;
  height: 0.64rem;
  border: 0.013333rem solid #DCDCDC;
  border-radius: 0.066667rem;
  margin-top: -0.186667rem;
  line-height: 0.64rem;
  text-align: center;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div .ac {
  width: 2.133333rem;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div .b {
  width: 1.6rem;
  height: 0.64rem;
  background: linear-gradient(133deg, #FF8E8E 0%, #EB4B4B 100%);
  border-radius: 0.066667rem;
  margin-top: -0.186667rem;
  line-height: 0.64rem;
  text-align: center;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  margin-left: 0.266667rem;
}

.order-whole1 .order-alone1 .order-fuction1 .order-fuction-contend1 > div .bc {
  width: 2.133333rem;
}

.upvoucher1 {
  position: absolute;
  width: 10rem;
  height: 100vh;
  top: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  margin-left: -0.4rem;
}

.upvoucher1 .upvoucher-in1 {
  position: absolute;
  width: 8rem;
  z-index: 999;
  min-height: 5.066667rem;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background-color: #fff;
}

.upvoucher1 .upvoucher-in1 > p .one1 {
  width: 2.666667rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
  text-align: center;
  margin-left: 30%;
  border: 0.013333rem #666 solid;
  padding: 0.066667rem 0.133333rem;
}

.upvoucher1 .upvoucher-in1 > p .two1 {
  margin-left: 0.066667rem;
}

.del-address {
  position: absolute;
  z-index: 999;
  width: 6.666667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.del-address .contact {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.del-address .contact > img {
  width: 0.8rem;
  margin-bottom: 0.133333rem;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.del-address .contact > span {
  margin-bottom: 0.666667rem;
}

.del-address .del1-bottom {
  position: absolute;
  z-index: 1000;
  right: 0.266667rem;
  top: 0.266667rem;
}

.del-address1 {
  position: absolute;
  z-index: 9999;
  width: 6.666667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 60%;
  left: 150%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address1 .del-sure {
  height: 2rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address1 .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address1 .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address1 .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.del-phone0 {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 100001 !important;
  top: 0;
}