.add-user {
  width: 100%;
  height: 1.573333rem;
  background: #FFFFFF;
  font-size: 0.346667rem;
}

.add-user > span:first-child {
  font-size: 0.533333rem;
  font-weight: bold;
}

.user-item {
  width: 100%;
  height: 2.4rem;
  background: #FFFFFF;
  padding: 0.346667rem 0.373333rem;
}

.user-item div:first-child > span {
  line-height: 0.533333rem;
}

.user-item .my-radio {
  width: 0.8rem;
}