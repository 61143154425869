.lingshou-top {
  height: 1.706667rem;
  display: flex;
  background-color: #fff;
}

.lingshou-top > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.lingshou-top > p {
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  line-height: 1.733333rem;
  color: #414141;
  margin-left: 44%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.refund-process-top {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
}

.refund-process-top > h2 {
  font-size: 0.426667rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.933333rem;
  color: #333333;
}

.refund-process-top > p {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #333333;
}

.tuikuan-price {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
}

.tuikuan-price .tuikuan-price-top {
  display: flex;
  justify-content: space-between;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #333333;
}

.tuikuan-price .tuikuan-price-top > span {
  font-size: 0.346667rem;
}

.tuikuan-price .tuikuan-price-oragin {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #333333;
}

.refund-process {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
}

.refund-process > h2 {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.933333rem;
  color: #333333;
}

.refund-process > p {
  height: 0.933333rem;
  line-height: 0.933333rem;
}

.refund-process > p > a > img {
  width: 0.466667rem;
  height: 0.466667rem;
  margin-right: 0.2rem;
  margin-top: 0.066667rem;
}

.refund-process > p > a > span {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.4rem;
  color: #FF8D30;
}

.specific-refund-process {
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
  display: flex;
}

.specific-refund-process .refund-process-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.666667rem;
  margin-left: 0.666667rem;
}

.specific-refund-process .refund-process-left .refund-process-left-kong {
  width: 0.266667rem;
  height: 0.266667rem;
  border: 0.053333rem solid #1F87FF;
  border-radius: 50%;
}

.specific-refund-process .refund-process-left .refund-process-left-shu {
  width: 0;
  height: 2.213333rem;
  border: 0.013333rem solid #999999;
  margin-left: 0.106667rem;
  margin-top: 0.066667rem;
  margin-bottom: 0.066667rem;
}

.specific-refund-process .refund-process-left .refund-process-left-shi {
  width: 0.133333rem;
  height: 0.133333rem;
  background: #999999;
  border-radius: 50%;
  margin-left: 0.053333rem;
}

.specific-refund-process .refund-process-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.specific-refund-process .refund-process-right .refund-process-right-shnagjia,
.specific-refund-process .refund-process-right .refund-process-right-user {
  display: flex;
  flex-direction: column;
}

.specific-refund-process .refund-process-right .refund-process-right-shnagjia > span,
.specific-refund-process .refund-process-right .refund-process-right-user > span {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #999999;
}

.specific-refund-process .refund-process-right .refund-process-right-shnagjia .shangjia-tital,
.specific-refund-process .refund-process-right .refund-process-right-user .shangjia-tital {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 0.666667rem;
  color: #666666;
}

.specific-refund-process .refund-process-right .refund-process-right-shnagjia {
  margin-top: 0.266667rem;
}

.specific-refund-process .refund-process-right .refund-process-right-user {
  margin-top: 0.666667rem;
}

.refund-process-bottom {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
}

.refund-process-bottom > h2 {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.933333rem;
  color: #333333;
}

.refund-process-bottom .refund-process-allmedicial {
  display: flex;
  margin-bottom: 0.4rem;
}

.refund-process-bottom .refund-process-allmedicial > img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
}

.refund-process-bottom .refund-process-allmedicial .refund-process-medicial {
  width: 56%;
}

.refund-process-bottom .refund-process-allmedicial .refund-process-medicial > h3 {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #353A42;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.refund-process-bottom .refund-process-allmedicial .refund-process-medicial > p {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #353A42;
}

.refund-process-bottom .refund-process-allmedicial .refund-process-medicial .refund-process-medicial-num {
  margin-top: 0.4rem;
}

.refund-process-bottom .refund-process-allmedicial .refund-process-medicial-price {
  position: absolute;
  right: 0.533333rem;
  margin-top: 0.4rem;
}

.refund-medicial-bottom {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
}

.refund-medicial-bottom2 {
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
  padding-bottom: 1.066667rem;
  display: flex;
  justify-content: space-between;
}