.invoice-top {
  background-color: #fff;
}

.invoice-top .pifa-invoice {
  height: 1.706667rem;
  display: flex;
}

.invoice-top .pifa-invoice > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.invoice-top .pifa-invoice > p {
  line-height: 1.706667rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: 2.666667rem;
}

.invoice-top .selsect-reason {
  height: 1.266667rem;
  padding: 0.4rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.invoice-top .selsect-reason > span {
  font-size: 0.373333rem;
}

.invoice-top .selsect-reason .gt {
  font-size: 0.48rem;
}

.invoice-pic {
  height: 6rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  padding: 0.4rem;
}

.invoice-pic .shuoming {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-bottom: 0.266667rem;
}

.invoice-pic .miaoshu {
  padding-top: 0.266667rem;
  padding-left: 0.266667rem;
  border: none;
  background-color: #F8F8F8;
}

.invoice-pic .upvoucher-in {
  height: 4rem;
}

.tijiao {
  position: absolute;
  bottom: 0.266667rem;
}

.tijiao > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.tijiao > span {
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.333333rem;
}

.select-all-reason1 {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
}

.select-all-reason1 .selsect-inner {
  width: 100%;
  height: 8.6rem;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
}

.select-all-reason1 .selsect-inner > img {
  width: 0.266667rem;
  margin-left: 8.533333rem;
}

.select-all-reason1 .selsect-inner > h2 {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.8rem;
  color: #414141;
  text-align: center;
}

.select-all-reason1 .selsect-inner > p {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
  line-height: 0.666667rem;
}

.select-all-reason1 .selsect-inner > p > span {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #414141;
}

.select-all-reason1 .selsect-inner .sure {
  width: 100%;
  margin-top: 2.4rem;
}

.select-all-reason1 .selsect-inner .sure > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.2rem;
}

.select-all-reason1 .selsect-inner .sure > span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 1.133333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}