.userinfo .am-input-control input {
  font-size: 0.346667rem !important;
}

.userinfo .am-list-item .am-input-label {
  color: #666666;
  font-size: 0.346667rem !important;
}

.userinfo .am-list-line::after {
  background-color: #FFFFFF !important;
}

.userinfo .user-sex {
  align-items: center;
  background: #FFFFFF;
  padding-left: 0.4rem;
  font-size: 0.346667rem;
  color: #666666;
}

.userinfo .user-sex > span {
  width: 2.266667rem;
}

.userinfo .baseill-info {
  height: 100%;
}

.pop-adduser-tab {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1.6rem;
  font-size: 0.373333rem;
  position: relative;
}

.pop-adduser-tab .pop-adduser-close {
  font-size: 0.64rem;
  position: absolute;
  right: 0.266667rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pop-adduser-input {
  width: 7.466667rem;
  height: 0.853333rem;
  border-radius: 0.8rem;
  background: #eef1f5;
  align-items: center;
  color: #C2C5CB;
  padding: 0 0.373333rem;
  margin: 0.8rem 0 1.333333rem 0;
}

.pop-adduser-input input {
  font-size: 0.373333rem !important;
  font-weight: normal;
  border: none;
  color: #C2C5CB;
  background: #eef1f5;
}

.pop-adduser-button {
  width: 100%;
  height: 0.773333rem;
  color: #FFFFFF;
  font-size: 0.426667rem;
  background: #1F87FF;
  display: flex;
  align-items: center;
  justify-content: center;
}