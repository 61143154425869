.active2-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
}

.active2-container .actpage2-header-zhanwei {
  position: absolute;
  top: 0.4rem;
  left: 0.266667rem;
  z-index: 99;
}

.active2-container .actpage2-header-zhanwei > i {
  font-size: 0.64rem;
  color: #fff;
}

.active2-container .actpage2-header-zhanwei > span {
  font-size: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0 0.133333rem;
}

.active2-container .actpage2-header-zhanwei .actpage2-search > i {
  color: #999999;
  font-size: 0.64rem;
  position: absolute;
  left: 32vw;
  top: 0.133333rem;
}

.active2-container .actpage2-header-zhanwei .actpage2-search > input {
  width: 6.306667rem;
  height: 0.8rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  border: none;
  padding-left: 0.933333rem;
  padding-right: 0.4rem;
}

.active2-container .actpage2-content {
  position: absolute;
  top: -0.4rem;
}

.active2-container .actpage2-content > img {
  width: 100vw;
  height: 6.106667rem;
}

.active2-container .actpage2-top {
  position: absolute;
  width: 100vw;
  top: 5.333333rem;
  background-color: #fff;
  padding-top: 0.266667rem;
  height: 82vh;
  padding-bottom: 12vh;
  overflow: scroll;
}

.active2-container .actpage2-body {
  padding: 0.266667rem 0.4rem;
  border-bottom: 0.013333rem solid #D9D9D9;
}

.active2-container .actpage2-body .actpage2-body-top {
  display: flex;
  justify-content: space-between;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.133333rem;
  overflow: hidden;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-img > img {
  width: 100%;
  height: 100%;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-right {
  width: 74vw;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-right > h2 {
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-right > p {
  line-height: 0.533333rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.active2-container .actpage2-body .actpage2-body-top .actpage2-body-right .actpage2-body-b {
  display: flex;
  justify-content: space-between;
}

.active2-container .actpage2-body .actpage2-body-manjian {
  display: flex;
}

.active2-container .actpage2-body .actpage2-body-manjian > img {
  width: 1.56rem;
  margin: 0.133333rem 0 0 1.733333rem;
  position: absolute;
}

.active2-container .actpage2-body .actpage2-body-manjian .actpage2-body-manjian-right {
  height: 0.586667rem;
  background: #FFDEDC;
  border-radius: 0 0.066667rem 0.066667rem 0;
  padding: 0 0.133333rem 0 0.533333rem;
  line-height: 0.666667rem;
  margin-top: 0.266667rem;
  margin-left: 2.8rem;
  color: #FD3E34;
}

.active2-container .actpage2-body .actpage2-body-manjian .actpage2-body-manjian-right > span {
  margin-right: 0.133333rem;
}

.active2-container .actpage2-body-bottom {
  margin-left: 16vw;
  display: flex;
  height: 4rem;
  padding: 0.266667rem 0;
  overflow: hidden;
}

.active2-container .actpage2-body-bottom .actpage2-body-bottom-all {
  margin: 0.066667rem 0.106667rem;
}

.active2-container .actpage2-body-bottom .actpage2-body-bottom-all .actpage2-body-bottom-img {
  height: 2.4rem;
  width: 2.4rem;
}

.active2-container .actpage2-body-bottom .actpage2-body-bottom-all .actpage2-body-bottom-img > img {
  width: 100%;
  height: 100%;
}

.active2-container .actpage2-body-bottom .actpage2-body-bottom-all .actpage2-body-bottom-name {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
  width: 2.266667rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.active2-container .actpage2-body-bottom .actpage2-body-bottom-all .actpage2-body-bottom-price > span:first-child {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF3939;
}