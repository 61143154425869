.patientbox {
  position: relative;
  min-height: 100vh;
  padding-bottom: 0.266667rem;
}

.patientbox .box-top {
  width: 100%;
  height: 1.706667rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.patientbox .box-top img {
  width: 0.64rem;
  height: 0.64rem;
}

.patientbox .box-top h1 {
  width: 9.333333rem;
  text-align: center;
  font-size: 0.48rem;
  padding-right: 0.266667rem;
}

.patientbox .box-tips {
  height: 0.8rem;
  padding-left: 0.4rem;
  line-height: 0.8rem;
  background-color: #DAEBFF;
  color: #3C8CEA;
  margin-bottom: 0.266667rem;
}

.patientbox .box-information {
  background-color: white;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
}

.patientbox .box-information h1 {
  font-size: 0.426667rem;
  margin-bottom: 0.266667rem;
}

.patientbox .box-information .information-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  border-bottom: 0.013333rem solid #F0F0F0;
}

.patientbox .box-information .information-item div:first-child {
  font-size: 0.373333rem;
  width: 25%;
}

.patientbox .box-information .information-item div:nth-child(2) {
  width: 75%;
}

.patientbox .box-information .information-item div:nth-child(2) input {
  border: none;
  vertical-align: middle;
  margin-right: 0.133333rem;
}

.patientbox .box-information .information-item div:nth-child(2) span {
  margin-right: 0.373333rem;
}

.patientbox .box-information .information-item .item-value {
  width: 100%;
  height: 0.88rem;
  margin-top: 0.133333rem;
}

.patientbox .box-information .information-item .item-value input {
  width: 100%;
  height: 0.88rem;
  background-color: #FAFAFA;
  border-radius: 0.133333rem;
  border: 0.013333rem solid #E5E5E5;
}

.patientbox .box-btn {
  text-align: center;
  background-color: white;
  padding: 0.4rem;
  margin-top: -0.4rem;
}

.patientbox .box-btn button {
  width: 9.2rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  border: none;
  background-color: #0076FF;
  color: white;
}