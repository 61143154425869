.base-tab-frame {
  height: 0.453333rem;
  padding: 0 0.106667rem;
  font-size: 0.346667rem;
  min-width: 0.906667rem;
  margin-left: 0.106667rem;
  white-space: nowrap;
}

.base-tab-radius {
  height: 0.506667rem;
  padding: 0 0.186667rem;
  border-radius: 0.293333rem;
  min-width: 1.226667rem;
  font-size: 0.266667rem;
  margin-right: 0.133333rem;
  white-space: nowrap;
}

.base-tab-vip {
  border-radius: 0 0.133333rem;
  width: 1.28rem;
  height: 0.346667rem;
  color: #FFE89F;
  font-size: 0.266667rem;
  background: linear-gradient(180deg, #757575 0%, #151515 100%);
}

.base-tab-discount {
  min-width: 1.386667rem;
  margin-left: 0.106667rem;
  height: 0.346667rem;
  background: #f34848;
  border-radius: 0.053333rem;
  color: #FFFFFF;
}

.base-tab-discount span {
  font-size: 0.266667rem;
}

.base-tab-activity {
  min-width: 1.386667rem;
  margin-left: 0.106667rem;
  height: 0.346667rem;
  border: 0.026667rem solid #f34848;
  color: #f34848;
  border-radius: 0 0.133333rem;
  font-size: 0.266667rem;
}

.base-tab-activity span {
  font-size: 0.266667rem;
}

.tab-active {
  color: #FFFFFF;
}

.tab-dark {
  background: #FFFFFF;
  border: 0.026667rem solid #cfd1d4;
  color: #727272;
}