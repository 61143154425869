.doctor-list .doctorlist-line {
  width: 100%;
  height: 0.026667rem;
  background: #EEF1F5;
}

.doctor-list .doctor-card {
  width: 100%;
  padding: 0.48rem 0.426667rem 0 0.426667rem;
  color: #656565;
}

.doctor-list .doctor-card > img {
  width: 1.066667rem;
  height: 1.066667rem;
}

.doctor-list .doctor-card .doctor-card-detail {
  width: 100%;
  padding-left: 0.266667rem;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-name {
  width: 100%;
  height: 0.533333rem;
  display: flex;
  align-items: center;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-name p {
  color: #0B1523;
  font-weight: bold;
  font-size: 0.4rem;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-name .doctor-tag {
  flex: 1 1 auto;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-name span {
  flex: 0 1 0.8rem;
  color: #1F87FF;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-belong {
  line-height: 0.666667rem;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-data {
  color: #C2C5CB;
  scale: 0.91;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-data p {
  color: #1F87FF;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-data i {
  font-size: 0.32rem;
  color: #FFB740;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-brief {
  margin-top: 0.186667rem;
  width: 6.666667rem;
  line-height: 0.48rem;
  color: #7D7E81;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-brief span {
  color: #333333;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek {
  width: 100%;
  height: 1.493333rem;
  color: #F34848;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek .doctor-seek-tab {
  height: 0.506667rem;
  align-items: flex-end;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek .doctor-seek-tab img {
  width: 0.506667rem;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek .doctor-seek-tab span {
  font-size: 0.4rem;
  color: #F34848;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek .doctor-seek-tab span:last-child {
  line-height: 0.4rem;
  font-size: 0.266667rem;
}

.doctor-list .doctor-card .doctor-card-detail .doctor-seek .doctor-seek-tab .doctor-seek-icon {
  width: 0.506667rem;
  height: 0.506667rem;
  border-radius: 0.506667rem;
  color: #F2F8FF;
  background-color: #1F87FF;
  display: flex;
  align-items: center;
  justify-content: center;
}