.box3 {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  height: 100vh;
  background-color: white;
  padding-top: 0.493333rem;
  position: relative;
}

.box3 .box3-top {
  margin-bottom: 1.546667rem;
}

.box3 .box3-top button {
  width: 0.64rem;
  background-color: white;
  height: 0.64rem;
  border: none;
  float: left;
  background-image: url("../../static/img/login/fanhui.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.box3 h1 {
  width: 6.4rem;
  height: 0.693333rem;
  font-size: 0.533333rem;
  font-family: MicrosoftYaHei;
  line-height: 0.306667rem;
  color: #333333;
  opacity: 1;
  margin-bottom: 1.2rem;
}

.box3 .ab {
  position: absolute;
  right: 1.066667rem;
  top: 5.466667rem;
  font-size: 0.4rem;
  color: #2086FE;
}

.box3 .btn,
.box3 .btn1 {
  width: 100%;
  margin-top: 0.8rem;
  line-height: 1.12rem;
  border-radius: 0.56rem;
  color: #FFFFFF;
}

.box3 .btn {
  background-color: #a6c1Fa;
}

.box3 .btn1 {
  background-color: #2086FE;
}