.tidings-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.546667rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.tidings-screen-page .tidings-outer {
  padding: 0.4rem;
  display: flex;
  border-bottom: 0.026667rem solid #EDEDEE;
}

.tidings-screen-page .tidings-outer .tidings-outer-img {
  height: 1.066667rem;
  width: 1.066667rem;
  margin-right: 0.266667rem;
}

.tidings-screen-page .tidings-outer .tidings-outer-img > img {
  width: 100%;
}

.tidings-screen-page .tidings-outer .tidings-outer-img > span {
  position: absolute;
  left: 1.2rem;
  width: 0.266667rem;
  height: 0.266667rem;
  background: #F34848;
  border-radius: 50%;
}

.tidings-screen-page .tidings-outer .tidings-outer-right {
  width: 85vw;
}

.tidings-screen-page .tidings-outer .tidings-outer-right .tidings-outer-right-top {
  display: flex;
  justify-content: space-between;
}

.tidings-screen-page .tidings-outer .tidings-outer-right .tidings-outer-right-top > span:first-child {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0.4rem;
}

.tidings-screen-page .tidings-outer .tidings-outer-right .tidings-outer-right-top > span:last-child {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #C2C5CB;
}

.tidings-screen-page .tidings-outer .tidings-outer-right > p:last-child {
  width: 75vw;
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #C2C5CB;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.tidings-item {
  width: 100%;
  height: 1.973333rem;
  display: flex;
  flex-direction: row;
  padding: 0.506667rem 0.4rem 0.4rem 0.4rem;
  color: #C2C5CB;
}

.tidings-item .item1 {
  width: 1.066667rem;
  height: 100%;
  position: relative;
}

.tidings-item .item1 .item1-img {
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 50%;
  overflow: hidden;
}

.tidings-item .item1 .item1-img > img {
  width: 1.066667rem;
  height: 1.066667rem;
}

.tidings-item .item1 .hongdian {
  position: absolute;
  width: 0.213333rem;
  height: 0.213333rem;
  background: #f34848;
  border-radius: 50%;
  top: 0;
  right: 0;
}

.tidings-item .item2 {
  height: 100%;
  margin-left: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}

.tidings-item .item2 :nth-child(1) {
  font-size: 0.186667rem;
  color: #333333;
  display: inline-block;
}

.tidings-item .item2 :nth-child(2) {
  height: 0.346667rem;
  width: 100%;
  font-size: 0.293333rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.tidings-item > span {
  width: 1.146667rem;
  text-align: right;
}