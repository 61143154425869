.swiper2 {
  position: relative;
  height: 10rem;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.swiper-view2 {
  height: 10rem;
  width: 100%;
  overflow: hidden;
}

.swiper-view2 img {
  margin-top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  overflow: hidden;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
  ckground-size: cover;
  -Webkit-background-size: cover;
  -o-bacackground-size: cover;
  background-position: center 0;
}