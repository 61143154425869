.post-header {
  width: 100%;
  height: 1.68rem;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.post-header * {
  color: #414141;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
}

.post-header .back-icon {
  width: 1.84rem;
  height: 0.64rem;
  text-align: left;
  margin-left: 0.133333rem;
  align-items: center;
  padding-left: 0.133333rem;
}

.post-header .back-icon i {
  font-size: 0.533333rem;
}

.post-header span {
  color: #414141;
  font-size: 0.48rem;
}

.post-header .post-icon {
  width: 1.44rem;
  height: 0.506667rem;
  border: 0.026667rem solid #1F87FF;
  border-radius: 0.053333rem;
  color: #1F87FF;
  margin-right: 0.4rem;
}

.post-area {
  border-top: 0.026667rem solid #EEF1F5;
  background-color: #EEF1F5;
}

.post-area .post-area-text .am-textarea-control textarea {
  font-size: 0.373333rem;
}

.post-bottom {
  width: 2.933333rem;
  height: 2.933333rem;
  background-color: #EEF1F5;
  display: flex;
  flex-direction: row;
  margin: 0.4rem;
}

.post-bottom .am-image-picker .am-image-picker-list {
  padding: 0;
}

.post-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item {
  width: 2.933333rem;
  min-width: 2.933333rem;
  height: 2.933333rem;
}

.post-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input {
  width: 2.933333rem;
  height: 2.933333rem;
}