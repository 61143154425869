.box {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: 0.4rem;
  padding-top: 0.493333rem;
  position: relative;
}

.box-top {
  width: 100%;
  background-color: white;
}

.box-top button {
  width: 0.64rem;
  height: 0.64rem;
  background-color: white;
  border: none;
  background-image: url("../../static/img/login/fanhui.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  position: absolute;
  width: 3.293333rem;
  height: 1.066667rem;
  top: 3.466667rem;
  left: 3.36rem;
  background-image: url("../../static/img/login/logo2x.png");
  background-repeat: no-repeat;
  background-size: 3.293333rem 1.066667rem;
}

.logo1 {
  position: absolute;
  width: 5rem;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.666667rem;
  top: 2.666667rem;
}

.allbtn {
  margin-top: 6.88rem;
  width: 100%;
}

.allbtn button {
  width: 100%;
  height: 1.12rem;
  margin-bottom: 0.413333rem;
  border: none;
  border-radius: 0.56rem;
}

.allbtn button:first-child {
  background-color: #2086FE;
  color: #FFFFFF;
  font-size: 0.426667rem;
}

.allbtn button:last-child {
  background-color: #459D38;
  line-height: 1.12rem;
}

.allbtn button:last-child p {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.426667rem;
  margin-left: 0.066667rem;
  color: #FFFFFF;
  opacity: 1;
}

.allbtn button:last-child img {
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
}

.bottom {
  width: 100%;
  text-align: left;
  line-height: 0.666667rem;
}

.bottom a {
  color: #2086FE;
}

.bottom .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #2086FE;
  background: #2086FE;
}