.screen-orderdetail {
  background-color: #F4F5F7;
  padding-bottom: 0.24rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.order-card-state {
  position: relative;
  width: 100%;
}

.order-card-state .order-fanhui {
  display: flex;
}

.order-card-state .order-fanhui .order-state-back {
  width: 100%;
  height: 1.866667rem;
  padding-left: 0.266667rem;
  padding-top: 0.613333rem;
}

.order-card-state .order-fanhui .order-state-back > i {
  font-size: 0.533333rem;
}

.order-card-state .order-fanhui > img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-top: 0.666667rem;
  margin-right: 0.4rem;
}

.order-card-state > p {
  padding-top: 0.266667rem;
  padding-left: 0.4rem;
  font-size: 0.533333rem;
  font-weight: bold;
  line-height: 0.133333rem;
}

.order-card-state > span {
  font-size: 0.373333rem;
}

.order-card-state .order-state-button {
  width: 2.613333rem;
  height: 0.773333rem;
  color: #1F87FF;
  font-size: 0.373333rem;
  background-color: #FFFFFF;
  margin-top: 0.693333rem;
  border-radius: 0.4rem;
}

.order-myadress {
  width: 100%;
  margin: 0.266667rem 0;
}

.order-myadress > i {
  font-size: 0.533333rem;
}

.order-myadress > p {
  font-size: 0.453333rem;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 80%;
}

.order-bottom-choose {
  background: #fff;
  position: fixed;
  height: 1.306667rem;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 1.066667rem rgba(168, 174, 188, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.4rem;
}

.order-bottom-choose > div {
  margin-left: 0.266667rem;
}

.order-moneysum {
  width: 100%;
  padding-bottom: 0.266667rem;
}

.heji {
  border-top: 0.026667rem solid #EEF1F5;
  padding-top: 0.266667rem;
  display: flex;
  justify-content: flex-end !important;
}

.heji .all {
  color: #333 !important;
}

.heji .price {
  font-size: 0.4rem;
  font-family: Microsoft YaHei;
  line-height: 0.4rem;
  color: #333 !important;
}

.fuzhi {
  padding: 0 0.2rem;
  margin-left: 0.266667rem;
  border: 0.013333rem solid #ccc;
  border-radius: 0.066667rem;
}

.wenzhen {
  width: 10rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  margin-top: 0.666667rem;
  padding: 0.4rem;
  margin-bottom: -0.466667rem;
}

.wenzhen > h2 {
  text-align: center;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei-Bold;
  margin-top: 0.4rem;
  color: #414141;
}

.wenzhen .wenzhen-top {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 0.266667rem;
}

.wenzhen .wenzhen-top > img {
  margin: 0 0.133333rem;
  width: 0.113333rem;
  height: 0.2rem;
}

.wenzhen .xian {
  width: 9.2rem;
  height: 0.026667rem;
  background: #EEF1F5;
}

.wenzhen > p {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
  text-align: center;
  margin-top: 0.266667rem;
  margin-bottom: 0.533333rem;
}

.wenzhen > p > span {
  color: #1F87FF;
}

.wenzhen .tianxie > img {
  width: 8.8rem;
  height: 1.133333rem;
}

.wenzhen .tianxie > span {
  position: absolute;
  text-align: center;
  left: 4.133333rem;
  margin-bottom: 0.4rem;
  line-height: 1.133333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.jujue-img {
  width: 0.213333rem;
  height: 0.373333rem;
  margin-left: 0.266667rem;
}

.order-state-explain-tongyi {
  width: 100%;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  background-color: #fff;
  position: relative;
  top: 0.733333rem;
}

.order-state-explain-tongyi .tongyi {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.order-state-explain-tongyi .tongyi-price {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: -0.533333rem;
}

.order-state-explain-tongyi .xian {
  height: 0.026667rem;
  background: #EEF1F5;
  margin-top: 0.333333rem;
  margin-bottom: 0.333333rem;
}

.order-state-explain-tongyi .tongyi-select {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  display: flex;
}

.order-state-explain-tongyi .tongyi-select > span {
  margin-left: 0.133333rem;
}

.order-state-explain-jujue {
  width: 100%;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  background-color: #fff;
  position: relative;
  top: 0.733333rem;
}

.order-state-explain-jujue .jujue {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.order-state-explain-jujue .xian {
  height: 0.026667rem;
  background: #EEF1F5;
  margin-top: -0.266667rem;
  margin-bottom: 0.4rem;
}

.order-state-explain-jujue .time {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-bottom: 0.266667rem;
}

.chuki-jujue {
  margin-top: 1rem;
  background-color: #fff;
  margin-bottom: -0.666667rem;
  padding: 0.2rem;
}

.chuki-jujue .contact {
  height: 1rem;
  flex-direction: column;
  align-items: center;
  -webkit-transform: translate(48%);
  transform: translate(48%);
}

.chuki-jujue .contact > img {
  width: 0.56rem;
}

.chuki-jujue .contact > p {
  margin-left: -0.333333rem;
  margin-top: 0.133333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0B1523;
}

.order-state-explain {
  width: 100%;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  position: relative;
  top: 0.733333rem;
}

.order-state-explain > p {
  color: #666;
  font-weight: bold;
  line-height: 0.4rem;
  padding: 0 0.053333rem;
  font-size: 0.32rem;
}

.order-state-explain .time {
  color: #414141;
  font-size: 0.426667rem;
  margin-bottom: 0.266667rem;
}

.order-state-explain .time > span {
  font-size: 0.426667rem;
  color: #1F87FF;
}

.order-state-explain .chuli {
  margin-top: 0.533333rem;
  padding: 0.266667rem 0.666667rem 0 0.666667rem;
  display: flex;
  justify-content: space-around;
}

.order-state-explain .chuli .contact {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.4rem;
  color: #0B1523;
  background-color: #fff;
  border: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-state-explain .chuli .contact > img {
  margin-bottom: 0.133333rem;
  width: 0.533333rem;
}

.order-state-explain .pay {
  margin-top: 0.4rem;
  font-size: 0.48rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.order-state-explain .sy-time {
  font-size: 0.4rem;
}

.order-state-explain .flex-row {
  margin-left: -0.533333rem;
  margin-top: 0.4rem;
}

.wancheng {
  width: 100%;
  padding: 0.666667rem 0.4rem 0.4rem 0.4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  position: absolute;
  margin-top: -2.533333rem;
  margin-left: -0.4rem;
  height: 1.866667rem;
}

.wancheng > p {
  font-size: 0.506667rem !important;
}

.tuikuan {
  top: 1.333333rem;
  position: relative;
  margin-top: -0.333333rem;
  margin-bottom: 0.6rem;
  padding: 0 0.4rem;
  width: 100%;
  height: 1.4rem;
  line-height: 1.4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.tuikuan .tuikuan-left {
  font-size: 0.453333rem;
  font-family: MicrosoftYaHei-Bold;
  color: #414141;
  font-weight: bold;
}

.tuikuan .tuikuan-right {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #FF8D30;
}

.base-card {
  position: relative;
  top: 1rem;
  margin-bottom: 0.266667rem;
}

.base-card .a .address {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #222;
}

.base-card .a > span {
  color: #222;
}

.base-card .phname {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #222;
}

.orderdetail-divide {
  height: 0.026667rem;
  background: #eef1f5;
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 0.266667rem;
}

.order-position-row {
  width: 100%;
  height: 1.173333rem;
}

.order-position-row .order-position-detail {
  width: 100%;
  height: 100%;
  margin-left: 0.32rem;
  justify-content: space-between;
}

.order-position-row .order-position-detail > p {
  color: #0B1523;
  font-size: 0.4rem;
  font-weight: bold;
}

.bottom2 {
  height: 1.6rem;
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  line-height: 2.266667rem;
  color: #999999;
  text-align: center;
}

.cha {
  width: 0.346667rem;
  position: absolute;
  top: 1rem;
  margin-left: -4.533333rem;
}

.select {
  margin-top: 0.666667rem;
  text-align: left;
  margin-left: 0.4rem;
  height: 0.56rem;
  font-weight: bold;
  font-size: 0.506667rem !important;
  font-family: MicrosoftYaHei-Bold;
  line-height: 0.4rem;
  color: #222;
  margin-bottom: 0.133333rem;
}

.commit {
  width: 92vw;
  height: 1.066667rem;
  background: #1F87FF;
  border-radius: 0.066667rem;
  overflow: hidden;
  color: #fff;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 1.066667rem;
  position: absolute;
  margin-top: 0.8rem;
  margin-left: 4vw;
  margin-bottom: 0.133333rem;
  text-align: center;
}

.commit2 {
  width: 92vw;
  height: 1.066667rem;
  background: #999999;
  border-radius: 0.066667rem;
  overflow: hidden;
  color: #fff;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 1.066667rem;
  position: absolute;
  margin-top: 2.133333rem;
  margin-left: 4vw;
  margin-bottom: 0.133333rem;
  text-align: center;
}

.refund-reasons {
  position: fixed;
  z-index: 99999;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.refund-reasons .refund-reasons-title {
  text-align: center;
  margin: 0.533333rem 0;
  font-size: 0.426667rem;
  font-weight: bold;
}

.refund-reasons .refund-reasons-all {
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.066667rem;
}

.refund-reasons .refund-reasons-all > div {
  font-size: 0.346667rem;
}

.tankuang {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 996;
  top: -0.013333rem;
  left: -0.013333rem;
  background-color: rgba(12, 12, 12, 0.5);
}

.tankuang .inner {
  width: 7.52rem;
  height: 4.266667rem;
  padding: 0.666667rem 0 0.133333rem 0;
  background: #FFFFFF;
  border: 0.013333rem solid #707070;
  border-radius: 0.266667rem;
  position: absolute;
  top: 38%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tankuang .inner .quxiao {
  font-size: 0.453333rem;
  font-family: MicrosoftYaHei-Bold;
  color: #414141;
  text-align: center;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.tankuang .inner .neirong {
  padding: 0 0.4rem 0.133333rem 0.4rem;
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  margin-bottom: 0.4rem;
  line-height: 0.506667rem;
}

.tankuang .inner .bottom {
  width: 100%;
  height: 1.066667rem;
  display: flex;
  border-top: 0.013333rem solid #eee;
}

.tankuang .inner .bottom > span {
  width: 3.76rem;
  line-height: 1.066667rem;
  color: #969696;
}

.tankuang .inner .bottom .xiugai {
  border-right: 0.013333rem solid #eee;
}

.tankuang .inner .bottom .sure {
  color: #1F87FF;
}

.tankuang > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 56%;
}

.tankuang > div > img {
  width: 0.666667rem;
}

.flex-apart-row > span {
  font-size: 0.373333rem;
}

.pifa-orderdetail {
  background-color: #F4F5F7;
  padding-bottom: 0.24rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
}

.pifa-orderdetail .order-pifa {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 0.266667rem;
}

.pifa-orderdetail .order-pifa .order-fanhui {
  display: flex;
}

.pifa-orderdetail .order-pifa .order-fanhui .pifa-fanhui {
  width: 100%;
  height: 1.866667rem;
  padding-left: 0.266667rem;
  padding-top: 0.613333rem;
}

.pifa-orderdetail .order-pifa .order-fanhui .pifa-fanhui > i {
  font-size: 0.533333rem;
}

.pifa-orderdetail .order-pifa .order-fanhui .daizhifu {
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: -5.733333rem;
  margin-top: 0.6rem;
}

.pifa-orderdetail .order-pifa .order-fanhui .daizhifu1 {
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: -6.4rem;
  margin-top: 0.6rem;
}

.pifa-orderdetail .order-pifa .red {
  height: 1.133333rem;
  background: #FFF3F3;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  line-height: 1.133333rem;
  color: #FF5555;
  text-align: center;
}

.pifa-orderdetail .order-pifa .jibenxinxi {
  height: 1.133333rem;
  line-height: 1.133333rem;
  margin: 0 0.4rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #E5E5E5;
}

.pifa-orderdetail .order-pifa .jibenxinxi .jibenxinxi-left {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #666666;
}

.pifa-orderdetail .order-pifa .jibenxinxi .jibenxinxi-right {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF5555;
}

.pifa-orderdetail .order-pifa .pifa-news {
  padding: 0.533333rem 0.4rem;
  color: #666666;
}

.pifa-orderdetail .pifa-dingdan {
  margin-bottom: 0.266667rem;
  background-color: #fff;
}

.pifa-orderdetail .pifa-dingdan .yaodan-name {
  height: 1.133333rem;
  line-height: 1.133333rem;
  margin-left: 0.4rem;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #666666;
}

.pifa-orderdetail .pifa-dingdan .xian {
  width: 10rem;
  height: 0;
  border: 0.026667rem solid #E5E5E5;
}

.pifa-orderdetail .pifa-dingdan .all {
  height: 0.666667rem;
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: MicrosoftYaHei;
  color: #353A42;
  display: flex;
  justify-content: flex-end;
}

.pifa-orderdetail .pifa-dingdan .all .heji-price {
  margin-left: 0.266667rem;
}

.pifa-orderdetail .pifa-dingdan .all .heji-price > span {
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #FF5555;
}

.pifa-orderdetail .pifa-fapiao {
  background-color: #fff;
  height: 1.133333rem;
  line-height: 1.133333rem;
  padding-left: 0.4rem;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.pifa-orderdetail .pifa-fapiao > span {
  color: #666;
}

.pifa-orderdetail .dgzz-outer {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.pifa-orderdetail .dgzz-outer .dgzz-img {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 9.2rem;
}

.pifa-orderdetail .dgzz-outer .dgzz-img > span {
  width: 4.4rem;
  height: 4.4rem;
  padding: 0.266667rem 0;
}

.pifa-orderdetail .dgzz-outer .dgzz-img > span > img {
  width: 4.4rem;
  height: 4.4rem;
}

.pifa-orderdetail .bottom {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  height: 1.133333rem;
  display: flex;
  justify-content: space-between;
  line-height: 1.133333rem;
  padding: 0 0.4rem;
}

.pifa-orderdetail .bottom .bottom-left {
  font-family: MicrosoftYaHei;
  color: #777;
}

.pifa-orderdetail .bottom .bottom-right {
  width: 1.6rem;
  height: 0.64rem;
  background: linear-gradient(133deg, #FF8E8E 0%, #EB4B4B 100%);
  border-radius: 0.066667rem;
  margin-top: 0.24rem;
  line-height: 0.666667rem;
  text-align: center;
  color: #FFFFFF;
}

.pifa-orderdetail .bottom .bottom-right0 {
  width: 2.133333rem;
}

.pifa-orderdetail .bottom .bottom-right2 {
  width: 1.6rem;
  height: 0.64rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  border: 0.013333rem solid #DCDCDC;
  margin-top: 0.266667rem;
  line-height: 0.6rem;
  display: inline-block;
  text-align: center;
}

.pifa-orderdetail .bottom .bottom-right1 {
  width: 3.466667rem;
  height: 0.64rem;
  border-radius: 0.066667rem;
  margin-top: 0.24rem;
  line-height: 0.666667rem;
  text-align: center;
  display: flex;
}

.pifa-orderdetail .bottom .bottom-right1 .bottom-right-l {
  width: 1.6rem;
  height: 0.64rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-right: 0.4rem;
  border: 0.013333rem solid #DCDCDC;
  margin-top: 0.066667rem;
  line-height: 0.6rem;
  display: inline-block;
}

.pifa-orderdetail .bottom .bottom-right1 .bottom-right-r {
  width: 1.6rem;
  height: 0.64rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  background: linear-gradient(133deg, #FF8E8E 0%, #EB4B4B 100%);
  border-radius: 0.066667rem;
  margin-top: 0.066667rem;
  line-height: 0.6rem;
  display: inline-block;
}

.upvoucher {
  position: absolute;
  width: 10rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  margin-left: -0.4rem;
}

.upvoucher .upvoucher-in {
  position: absolute;
  width: 8rem;
  min-height: 5.066667rem;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background-color: #fff;
}

.upvoucher .upvoucher-in > p .one {
  width: 2.666667rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
  text-align: center;
  margin-left: 30%;
  border: 0.013333rem #666 solid;
  padding: 0.066667rem 0.133333rem;
}

.upvoucher .upvoucher-in > p .two {
  margin-left: 0.066667rem;
}

.wlbox2 {
  overflow: hidden;
}

.wlbox2 {
  display: block;
}

.wlbox {
  width: 100%;
  height: 100%;
  padding: 0.4rem;
  padding-top: 0.8rem;
  overflow: scroll;
}

.wlbox .wlbox-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.533333rem;
}

.wlbox .wlbox-item p {
  width: 95%;
  margin-left: 0.4rem;
  line-height: 0.506667rem;
  margin-right: 0.133333rem;
}

.wlbox .wlbox-item h3 {
  text-align: right;
  color: gray;
}

.wlbox .wlbox-item img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.del-address {
  position: absolute;
  z-index: 9999;
  width: 6.666667rem;
  height: 3.466667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}