.login-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.login-full-screen .choose-login {
  height: 1.28rem;
  width: 100%;
  padding: 0 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-full-screen .choose-login i {
  font-size: 0.533333rem;
}

.login-full-screen .choose-login a {
  font-size: 0.346667rem;
  color: #FF5555;
}

.login-full-screen .choose-login span {
  color: #FF5555;
  font-size: 0.373333rem;
}

.login-full-screen > p {
  color: #0B231F;
  font-size: 0.48rem;
  padding: 2.08rem 0 0 0.853333rem;
}

.login-full-screen .login-area {
  flex: 1 1;
}

.login-full-screen .login-area .login-account {
  margin: 1.066667rem 1.68rem 0.373333rem 0.933333rem;
}

.login-full-screen .login-area .login-phone {
  margin: 1.066667rem 1.68rem 0.666667rem 0.933333rem;
}

.login-full-screen .login-area .title {
  color: #999999;
  font-size: 0.293333rem;
}

.login-full-screen .login-area .login-account,
.login-full-screen .login-area .login-phone {
  margin-bottom: 0;
  position: relative;
}

.login-full-screen .login-area .login-account .am-list-body::before,
.login-full-screen .login-area .login-phone .am-list-body::before {
  display: none;
}

.login-full-screen .login-area .login-account .am-list-item .am-input-control,
.login-full-screen .login-area .login-phone .am-list-item .am-input-control {
  font-size: 0.4rem;
  flex: 1 1;
}

.login-full-screen .login-area .login-account a,
.login-full-screen .login-area .login-phone a {
  position: absolute;
  display: inline-block;
  height: 0.533333rem;
  font-size: 0.4rem;
  text-decoration: none;
  border: none;
  left: 70%;
  color: #FF5555;
}

.login-full-screen .login-area .bottom {
  margin-bottom: 0.933333rem;
  box-sizing: border-box;
  padding: 0.6rem;
  padding-top: 0.133333rem;
  line-height: 0.666667rem;
  text-align: left;
  color: #FF5555;
}

.login-full-screen .login-area .bottom a {
  color: #FF5555;
}

.login-full-screen .login-area .login-code {
  box-sizing: border-box;
  padding: 0.533333rem;
}

.login-full-screen .login-area .login-code .btn,
.login-full-screen .login-area .login-code .btn1 {
  width: 100%;
  font-size: 0.426667rem;
  line-height: 1.12rem;
  border-radius: 0.56rem;
}

.login-full-screen .login-area .login-code .btn p,
.login-full-screen .login-area .login-code .btn1 p {
  color: #FFFFFF;
}

.login-full-screen .login-area .login-code .btn {
  background-color: #2086FE;
}

.login-full-screen .login-area .login-code .btn1 {
  background-color: #2086FE;
}

.login-full-screen .login-area .login-code .btn3,
.login-full-screen .login-area .login-code .btn4 {
  width: 100%;
  font-size: 0.426667rem;
  line-height: 1.12rem;
  border-radius: 0.56rem;
}

.login-full-screen .login-area .login-code .btn3 p,
.login-full-screen .login-area .login-code .btn4 p {
  color: #FFFFFF;
}

.login-full-screen .login-area .login-code .btn3 {
  background-color: #a6c1Fa;
}

.login-full-screen .login-area .login-code .btn4 {
  background-color: #2086FE;
}

.login-full-screen .login-area .login-code > p {
  text-align: left;
  box-sizing: border-box;
  margin-top: 0.266667rem;
  padding-left: 0.4rem;
  color: #818D8D;
}

.login-full-screen .login-area .login-code > p b {
  color: #0B231F;
}

.login-full-screen .login-area .login-code .am-list-line {
  padding: 0;
  width: 80%;
}

.login-full-screen .login-area .login-code > div {
  height: 1.066667rem;
}

.login-full-screen .login-area .login-code > div > input {
  width: 1.04rem;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.026667rem solid #E5E5E5;
  margin: 0.533333rem 0.133333rem;
  font-size: 0.8rem;
  text-align: center;
}

.login-full-screen .login-area .login-button {
  width: 8.8rem;
  height: 1.173333rem;
  border-radius: 0.586667rem;
  background: #2086FE;
  font-size: 0.426667rem;
  color: #FFFFFF;
  border: none;
  display: block;
  margin: 0 auto;
}

.login-full-screen .login-area .login-button1 {
  width: 8.8rem;
  height: 1.173333rem;
  border-radius: 0.586667rem;
  background: #a6c1Fa;
  font-size: 0.426667rem;
  color: #FFFFFF;
  border: none;
  display: block;
  margin: 0 auto;
}

.login-full-screen .login-area .disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: #CFD1D4;
}

.login-full-screen .login-other {
  height: 4rem;
  width: 100%;
}

.login-full-screen .login-other .login-thirdway {
  color: #818D8D;
  width: 100%;
  padding: 0 1.226667rem;
}

.login-full-screen .login-other .login-thirdway > div {
  font-size: 0.32rem;
}

.login-full-screen .login-other .login-thirdway .huixian {
  width: 2.106667rem;
  height: 0;
  border: 0.026667rem solid #e5e5e5;
}

.login-full-screen .login-other .login-icon img {
  width: 0.8rem;
  height: auto;
  margin: 0.853333rem 1.28rem 0 1.28rem;
}

.login-full-screen .login-error-msg {
  margin-top: 0.266667rem;
  height: 0.533333rem;
  color: #f50 !important;
  padding-left: 0.533333rem;
}

.login-full-screen .agreement {
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.login-full-screen .agreement a {
  color: #2086FE;
}