.order-meditem {
  width: 100%;
  padding: 0.4rem 0 0 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.order-meditem .flexr {
  overflow: hidden;
}

.order-meditem .xian {
  height: 0.026667rem;
  width: 9.333333rem;
  border-bottom: 0.013333rem solid #eee;
}

.order-meditem img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.266667rem;
}

.order-meditem .order-meditem-detail {
  height: 2rem;
  color: #353A42;
}

.order-meditem .order-meditem-detail p {
  width: 6.933333rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.346667rem;
  line-height: 0.4rem;
}

.order-meditem .order-meditem-detail p .name {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #353A42;
  width: 4.266667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-meditem .order-meditem-detail p .name .chufang {
  background-color: #1F87FF;
  color: #fff;
  margin-right: 0.133333rem;
  padding: 0 0.106667rem 0.026667rem 0.106667rem;
}

.order-meditem .order-meditem-detail p .price {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333;
}

.order-meditem .order-meditem-detail p .price > span {
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  margin-right: 0.133333rem;
  text-decoration: line-through;
}

.order-meditem .order-meditem-detail .guige {
  font-size: 0.293333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
  margin-top: 0.133333rem;
}

.order-meditem .order-meditem-detail span {
  font-size: 0.32rem;
  margin-top: 0.266667rem;
}

.pifa-order-meditem {
  width: 100%;
  padding: 0.4rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.pifa-order-meditem .xian {
  height: 0.026667rem;
  width: 9.333333rem;
  border-bottom: 0.013333rem solid #eee;
  margin-top: 0.266667rem;
}

.pifa-order-meditem img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.266667rem;
}

.pifa-order-meditem .order-meditem-pifa {
  height: 2.133333rem;
  width: 6.933333rem;
  color: #353A42;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pifa-order-meditem .order-meditem-pifa .left {
  width: 4.266667rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.346667rem;
  line-height: 0.4rem;
}

.pifa-order-meditem .order-meditem-pifa .left .name {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #353A42;
  width: 4.266667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pifa-order-meditem .order-meditem-pifa .left .name .chufang {
  background-color: #1F87FF;
  color: #fff;
  margin-right: 0.133333rem;
  padding: 0 0.106667rem 0.026667rem 0.106667rem;
}

.pifa-order-meditem .order-meditem-pifa .left .l {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.pifa-order-meditem .order-meditem-pifa .left .l > span {
  color: #353A42;
}

.pifa-order-meditem .order-meditem-pifa .right {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
  margin-top: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pifa-order-meditem .order-meditem-pifa .right .count {
  margin-left: 1.6rem;
  margin-bottom: 0.066667rem;
}

.pifa-order-meditem .order-meditem-pifa .right .price {
  display: flex;
  justify-content: space-between;
}

.pifa-order-meditem .order-meditem-pifa .right .price .originPrice {
  font-size: 0.266667rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  text-decoration: line-through;
}

.pifa-order-meditem .order-meditem-pifa .right .price .money {
  margin-left: 0.133333rem;
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #FF5555;
}