.billbox {
  background-color: #F4F5F7;
}

.billbox .billbox-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
}

.billbox .billbox-top img {
  width: 0.64rem;
  height: 0.64rem;
}

.billbox .billbox-top h2 {
  font-size: 0.48rem;
  width: 85%;
  text-align: center;
}

.billbox .billbox-search {
  text-align: center;
  margin-bottom: 0.266667rem;
  background-color: white;
  padding-bottom: 0.266667rem;
}

.billbox .billbox-search input {
  width: 8.8rem;
  height: 1.12rem;
  border-radius: 0.56rem;
  border: none;
  background-color: #F4F5F7;
  padding-left: 1.333333rem;
  background-image: url("../../../static/img/sousuo.png");
  background-repeat: no-repeat;
  background-position: 0.533333rem center;
  background-size: 0.493333rem;
}

.billbox .billbox-item {
  background-color: white;
  border-radius: 0.133333rem;
}

.billbox .billbox-item div {
  position: relative;
  padding: 0.4rem 0.266667rem;
  border-bottom: 0.013333rem solid #EEF1F5;
}

.billbox .billbox-item div .billbox-item-money {
  position: absolute;
  height: 0.533333rem;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0.4rem;
}

.billbox .billbox-item div .billbox-item-p1 {
  font-size: 0.373333rem;
  margin-bottom: 0.133333rem;
}

.billbox .billbox-item div .billbox-item-p2 {
  font-size: 0.32rem;
  color: #999999;
}