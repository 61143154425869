.inquiry-doctor-card {
  width: 9.306667rem;
  height: 2.773333rem;
  margin: 0.266667rem auto;
  border-radius: 0.4rem;
  background: #FFFFFF;
  padding: 0.48rem 0.426667rem 0 0.426667rem;
  color: #656565;
}

.inquiry-doctor-card > img {
  width: 1.066667rem;
  height: 1.066667rem;
}

.inquiry-doctor-card .inquiry-doctor-card-detail {
  width: 100%;
  padding-left: 0.266667rem;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-name {
  width: 100%;
  height: 0.533333rem;
  display: flex;
  align-items: center;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-name p {
  flex: 0 1 1.2rem;
  color: #0B1523;
  font-weight: bold;
  font-size: 0.4rem;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-name .inquiry-doctor-tag {
  flex: 1 1 auto;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-name span {
  flex: 0 1 0.8rem;
  color: #1F87FF;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-belong {
  line-height: 0.666667rem;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-data {
  color: #C2C5CB;
  scale: 0.91;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-data p {
  color: #1F87FF;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-data i {
  font-size: 0.32rem;
  color: #FFB740;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-brief {
  margin-top: 0.186667rem;
  width: 6.666667rem;
  line-height: 0.48rem;
  color: #7D7E81;
}

.inquiry-doctor-card .inquiry-doctor-card-detail .inquiry-doctor-brief a {
  color: #333333;
}

.inquiry-inputlist {
  width: 9.306667rem;
  height: 11.173333rem;
  margin: 0.266667rem auto;
  border-radius: 0.4rem;
  background: #FFFFFF;
}

.inquiry-inputlist span {
  color: #414141;
  font-size: 0.373333rem;
}

.inquiry-inputlist > div:nth-child(odd) {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.32rem;
}

.inquiry-inputlist > div:nth-child(1) {
  height: 1.706667rem;
  line-height: 1.706667rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inquiry-inputlist > div:nth-child(1) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inquiry-inputlist > div:nth-child(1) > div i {
  color: #F34848;
}

.inquiry-inputlist > div:nth-child(1) > div > div {
  margin-left: 0.4rem;
}

.inquiry-inputlist > div:nth-child(3) {
  height: 4.266667rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inquiry-inputlist > div:nth-child(3) p {
  position: absolute;
  bottom: 0.533333rem;
  right: 0.533333rem;
  color: #A1A5B3;
  font-size: 0.32rem;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.inquiry-inputlist > div:nth-child(3) > span {
  line-height: 1.386667rem;
}

.inquiry-inputlist > div:nth-child(3) .disease-text {
  height: 2.48rem;
  resize: none;
  border: none;
  background-color: #EEF1F5;
  padding: 0.24rem;
  line-height: 0.48rem;
}

.inquiry-inputlist > div:nth-child(5) {
  height: auto;
}

.inquiry-inputlist > div:nth-child(5) > div:first-child {
  line-height: 1.173333rem;
  display: flex;
  flex-direction: row;
}

.inquiry-inputlist > div:nth-child(5) > div:first-child > span {
  line-height: 1.173333rem;
}

.inquiry-inputlist > div:nth-child(5) > div:first-child > span:nth-child(2) {
  display: block;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  font-size: 0.32rem;
}

.upload-img {
  width: 1.92rem;
  height: 1.92rem;
  background-color: #EEF1F5;
}

.upload-img span {
  color: white;
  font-size: 1.066667rem;
  line-height: 1.066667rem;
}

.upload-img i {
  color: #414141;
  font-size: 0.8rem;
}

.agreecheck {
  width: 0.266667rem;
  height: 0.266667rem;
  border: 0.013333rem solid #999999;
}