.refund-top {
  background-color: #fff;
  height: 4.533333rem;
}

.refund-top .pifa-refund {
  height: 1.706667rem;
  display: flex;
}

.refund-top .pifa-refund > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.refund-top .pifa-refund > p {
  line-height: 1.706667rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: 3.333333rem;
}

.refund-top .refund-detail {
  height: 2.826667rem;
}

.refund-top .refund-detail .refund-detail-outer {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem;
}

.refund-top .refund-detail .refund-detail-outer > img {
  width: 2rem;
  height: 2rem;
}

.refund-top .refund-detail .refund-detail-outer .refund-detail-right {
  width: 6.933333rem;
  padding: 0.133333rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.refund-top .refund-detail .refund-detail-outer .refund-detail-right .refund-detail-inner-right {
  display: flex;
  justify-content: space-between;
}

.refund-top .refund-detail .refund-detail-outer .refund-detail-right .refund-detail-inner-right > h2 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #353A42;
}

.refund-top .refund-detail .refund-detail-outer .refund-detail-right .refund-detail-inner-right .company-name {
  font-size: 0.293333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.refund-top .refund-detail .refund-detail-outer .refund-detail-right .refund-detail-inner-right .refund-detail-l {
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #FF5555;
}

.refund-count {
  height: 2.253333rem;
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.refund-count .refund-count-title > span {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
}

.refund-count .refund-count-title .refund-count-title-r {
  font-style: 0.346667rem;
  color: #666666;
}

.refund-count .refund-count-number {
  margin-top: 0.4rem;
  display: flex;
}

.refund-count .refund-count-number > span {
  width: 0.533333rem;
  height: 0.533333rem;
  text-align: center;
  font-size: 0.48rem;
  line-height: 0.4rem;
  border: 0.013333rem solid #999999;
}

.refund-count .refund-count-number .jia {
  line-height: 0.533333rem;
  border-left: none;
}

.refund-count .refund-count-number .jian {
  border-right: none;
}

.refund-count .refund-count-number .number {
  width: 0.8rem;
  line-height: 0.533333rem;
  font-size: 0.346667rem;
}

.selsect-reason {
  height: 1.266667rem;
  padding: 0.4rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.selsect-reason > span {
  font-size: 0.373333rem;
}

.selsect-reason .gt {
  font-size: 0.48rem;
}

.reason-pic {
  height: 6rem;
  background-color: #fff;
  margin-top: 0.266667rem;
  padding: 0.4rem;
}

.reason-pic .shuoming {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-bottom: 0.266667rem;
}

.reason-pic .miaoshu {
  padding-top: 0.266667rem;
  padding-left: 0.266667rem;
  border: none;
  background-color: #F8F8F8;
}

.reason-pic .upvoucher-in {
  height: 4rem;
}

.tijiao {
  margin-top: 0.4rem;
}

.tijiao > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.tijiao > span {
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.333333rem;
}

.select-all-reason {
  width: 100%;
  height: 18.466667rem;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  padding: 0.266667rem 0.4rem;
}

.select-all-reason .popup-list {
  margin-left: -0.4rem;
}

.select-all-reason .sure {
  position: absolute;
  top: 0.666667rem;
  margin-left: 88%;
  background-color: #5555ff;
  color: #fff;
  padding: 0.066667rem 0.133333rem;
  line-height: 0.266667rem;
}