.active-container .second-active-header {
  padding: 0.4rem 0;
  color: white;
  background-color: #FF5555;
}

.active-container .second-active-header .second-active-goback {
  margin-left: 0.133333rem;
}

.active-container .second-active-header .second-active-goback > i {
  font-size: 0.533333rem;
}

.active-container .second-active-header .second-active-title > span:first-child {
  margin-left: 0.266667rem;
  font-size: 0.506667rem;
  font-weight: bold;
}

.active-container .second-active-header .second-active-title > span:last-child {
  margin-left: 0.266667rem;
  font-size: 0.346667rem;
}

.active-container .second-active-banner {
  width: 100%;
}

.active-container .second-active-banner img {
  display: block;
  width: 100%;
}

.active-container .second-active-med {
  padding-bottom: 0.8rem;
  overflow: hidden;
  background: linear-gradient(180deg, #FF5959 0%, #FF6D57 70%, #FF7E29 100%);
}

.active-container .second-active-med .second-active-descript {
  width: 7.426667rem;
  padding: 0.266667rem 0;
  margin: 0 auto;
  word-wrap: break-word;
  color: #333333;
}

.active-container .second-active-med .second-active-med-title {
  width: 4.64rem;
  height: 0.906667rem;
  margin: 0.4rem auto 0 auto;
  justify-content: center;
  align-items: center;
  background: #FFBF68;
  border-radius: 0.613333rem;
}

.active-container .second-active-med .second-active-med-title > div {
  width: 4.293333rem;
  height: 0.64rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FBFF98 0%, #FFCA38 100%);
  border-radius: 0.613333rem;
}

.active-container .second-active-med .second-active-med-title > div > span {
  font-size: 0.32rem;
  font-weight: bold;
  color: #FF5D20;
}

.active-container .second-active-med .second-active-med-list {
  width: 8.8rem;
  margin: 0.4rem auto 0 auto;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item {
  width: 100%;
  height: 4.8rem;
  margin-bottom: 0.4rem;
  border-radius: 0.266667rem;
  background-color: white;
  overflow: hidden;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-pic {
  width: 3.6rem;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-pic > img {
  width: 100%;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail {
  padding: 0.266667rem;
  justify-content: space-between;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-info {
  line-height: 0.64rem;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-info > div:first-child {
  font-size: 0.426667rem;
  font-weight: bold;
  color: #333333;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-info > div:last-child {
  font-size: 0.346667rem;
  color: #999999;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-price {
  justify-content: space-between;
  align-items: center;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-price > div:first-child {
  font-size: 0.426667rem;
  font-weight: bold;
  color: #FF7272;
  text-decoration: line-through;
}

.active-container .second-active-med .second-active-med-list .second-active-med-item .second-active-med-detail .second-active-med-price > div:last-child .am-button {
  width: 1.866667rem;
  height: 0.72rem;
  font-size: 0.32rem;
  line-height: 0.72rem;
  color: white;
  background: #FF5C5C;
}

.active-container .shop-header {
  width: 100%;
  height: 1.733333rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.213333rem;
}

.active-container .shop-header .shop-header-zhanwei {
  width: 0.64rem;
}

.active-container .shop-header .shop-header-zhanwei > i {
  font-size: 0.533333rem;
  width: 0.64rem;
  color: #414141;
}

.active-container .shop-header .shop-search {
  width: 7.573333rem;
  height: 0.853333rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  padding: 0 0.4rem;
  color: #414141;
  align-items: center;
}

.active-container .shop-header .shop-search input {
  width: 100%;
  font-size: 0.373333rem;
  font-weight: normal;
  border: none;
  background: none;
  color: #414141;
  margin-left: 0.24rem;
}

.active-container .shop-header .shop-search i {
  color: #414141;
  font-size: 0.48rem;
}

.active-container .actpage-header {
  position: absolute;
  top: 0.4rem;
  left: 0.106667rem;
  z-index: 9;
}

.active-container .actpage-header .actpage-header-zhanwei {
  width: 0.666667rem;
}

.active-container .actpage-header .actpage-header-zhanwei > i {
  line-height: 0.666667rem;
  font-size: 0.64rem;
  color: #414141;
  opacity: 0;
}

.active-container .actpage-content {
  position: relative;
  top: -0.4rem;
  width: 100%;
  font-size: 0;
}

.active-container .actpage-content img {
  width: 100%;
}