.am-list-content,
.am-list-extra {
  font-size: 0.373333rem !important;
}

.am-list-content {
  color: #353A42 !important;
}

.am-list-extra {
  color: #C2C5CB !important;
}

.am-list {
  height: 40vh;
}

.myset-logout {
  width: 8.8rem;
  height: 1.173333rem;
  position: absolute;
  bottom: 0;
  left: 0.533333rem;
  border-radius: 1.173333rem;
  margin: 1.066667rem auto;
  font-size: 0.426667rem;
  background-color: #FF5555;
  color: #FFFFFF;
}

.am-list .am-list-body {
  width: 10rem;
  margin-left: 0;
  height: 100vh;
}