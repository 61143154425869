.lingshou-shou {
  position: relative;
}

.shou-top {
  height: 1.706667rem;
  display: flex;
  background-color: #fff;
}

.shou-top > img {
  height: 0.613333rem;
  width: 0.613333rem;
  margin-left: 0.213333rem;
  margin-top: 0.613333rem;
}

.shou-top > p {
  line-height: 1.706667rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: 3.333333rem;
}

.pifa-dingdan {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding-top: 0.4rem;
}

.pifa-dingdan .goods-xg {
  padding-left: 0.4rem;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #414141;
  margin-bottom: 0.266667rem;
}

.pifa-dingdan .goods-sh {
  padding-left: 0.4rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  line-height: 0.533333rem;
  color: #666666;
}

.pifa-dingdan > h2 {
  margin-top: 0.4rem;
  padding-left: 0.4rem;
  line-height: 0.666667rem;
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.flexr-med {
  width: 95vw;
  display: flex;
  justify-content: space-between;
}

.flexr-med .order-meditem-lishou {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 0;
}

.flexr-med .order-meditem-lishou .name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*设置方向*/
  -webkit-line-clamp: 1;
}

.flexr-med .order-meditem-lishou .name .chufang {
  background-color: #1F87FF;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  line-height: 0.186667rem;
  color: #FFFFFF;
  padding: 0.026667rem 0.066667rem;
  margin-right: 0.066667rem;
}

.flexr-med .order-meditem-lishou .l,
.flexr-med .order-meditem-lishou .r {
  line-height: 0.666667rem;
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #353A42;
}

.flexr-med .order-meditem-lishou .r {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: 0.266667rem;
}

.flexr-med .count-meditem-lishou {
  width: 12vw;
  display: flex;
  justify-content: space-between;
  margin-top: 0.666667rem;
}

.flexr-med .count-meditem-lishou .jian,
.flexr-med .count-meditem-lishou .jia {
  width: 0.32rem;
  height: 0.32rem;
  background: #1F87FF;
  line-height: 0.32rem;
  text-align: center;
  color: #fff;
  border-radius: 50%;
}

.all-money {
  display: flex;
  justify-content: space-between;
  height: 1.333333rem;
  line-height: 1.333333rem;
  padding: 0 0.4rem;
}

.all-money > p {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
}

.tijiao-shenqing {
  display: flex;
  padding: 0.4rem 0.666667rem;
  margin-top: 0.4rem;
}

.tijiao-shenqing .word {
  position: relative;
}

.tijiao-shenqing .word > img {
  width: 5.333333rem;
  height: 1.133333rem;
}

.tijiao-shenqing .word > p {
  position: relative;
  left: 0.666667rem;
  top: -1.066667rem;
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  line-height: 0.4rem;
  color: #414141;
}

.tijiao-shenqing .img {
  position: relative;
}

.tijiao-shenqing .img > img {
  width: 3.333333rem;
  height: 1.133333rem;
}

.tijiao-shenqing .img > span {
  position: absolute;
  left: 0.666667rem;
  top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.selsect-reason .selsect-reason-left {
  width: 90vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*设置方向*/
  -webkit-line-clamp: 1;
}

.select-all-reasons {
  position: fixed;
  background-color: #fff;
  top: 0;
  height: 100vh;
  z-index: 999;
}

.select-all-reasons .sure {
  position: absolute;
  bottom: 1.333333rem;
  background-color: #1F87FF;
  width: 90vw;
  height: 0.933333rem;
  line-height: 0.933333rem;
  font-size: 0.373333rem;
  color: #fff;
  text-align: center;
  border-radius: 0.533333rem;
  margin-left: 5vw;
}

.select-all-reasons .quxiao {
  position: absolute;
  bottom: 0.266667rem;
  background-color: #aaa;
  width: 90vw;
  height: 0.933333rem;
  line-height: 0.933333rem;
  font-size: 0.373333rem;
  color: #fff;
  text-align: center;
  border-radius: 0.533333rem;
  margin-left: 5vw;
}