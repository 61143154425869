.TX-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.026667rem solid #F3F3F3;
}

.TX-top img {
  width: 0.64rem;
  height: 0.64rem;
  margin-left: -0.133333rem;
}

.TX-top h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.TX-wx {
  width: 100%;
  height: 1.426667rem;
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.373333rem;
  align-items: center;
  border-bottom: 0.026667rem solid #F3F3F3;
  justify-content: space-between;
}

.TX-wx img {
  width: 0.666667rem;
  height: 0.666667rem;
  vertical-align: middle;
}

.TX-money {
  width: 100%;
  height: 2.493333rem;
}

.TX-money p {
  padding-top: 0.4rem;
  margin-bottom: 0.173333rem;
  font-size: 0.373333rem;
}

.TX-money > div {
  display: flex;
}

.TX-money > div > img {
  width: 0.706667rem;
  height: 0.973333rem;
}

.TX-money > div input {
  width: 100%;
  height: 1.4rem;
  border: none;
  font-size: 0.48rem;
  border-bottom: 0.026667rem solid #E5E5E5;
}

.yue {
  padding-top: 0.266667rem;
  font-size: 0.373333rem;
  margin-bottom: 0.8rem;
  border-top: 0.026667rem solid #E5E5E5;
  display: flex;
  justify-content: space-between;
}

.TX-btn {
  width: 100%;
  text-align: center;
}

.TX-btn button {
  width: 4.8rem;
  height: 1.12rem;
  background-color: #FF5555;
  color: #FFFFFF;
  font-size: 0.426667rem;
  border: none;
  border-radius: 0.56rem;
}