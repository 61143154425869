.outer {
  width: 10rem;
  height: 17.786667rem;
  background: #FFFFFF;
}

.outer > header {
  width: 10rem;
  height: 3.013333rem;
  background: #FF5555;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.466667rem 0;
}

.outer > header > img {
  height: 0.4rem;
  position: absolute;
  top: 0.666667rem;
  left: 0.4rem;
}

.outer > header > h2 {
  font-size: 0.506667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}

.outer > header .step {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
}

.outer > header .step > span {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.6;
}

.outer > header .step .cg-news {
  opacity: 1;
}

.outer > header .step .xian {
  width: 0.8rem;
  height: 0;
  border: 0.026667rem solid #FFFFFF;
  opacity: 0.6;
  margin: 0 0.133333rem;
  margin-top: 0.2rem;
}

.outer .content .next {
  margin-top: 4rem;
  height: 1.133333rem;
}

.outer .content .next > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.outer .content .next > span {
  position: absolute;
  left: 4.466667rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.outer .content .tipsuser,
.outer .content .tipsarea,
.outer .content .tipsaddress,
.outer .content .tipsperson,
.outer .content .tipstel {
  position: absolute;
  right: 0.4rem;
  color: red;
  top: 0.533333rem;
  z-index: 99;
}

.outer .content .tipsarea {
  top: 1.6rem;
}

.outer .content .tipsaddress {
  top: 2.8rem;
}

.outer .content .tipsperson {
  top: 4rem;
}

.outer .content .tipstel {
  top: 5.2rem;
}

.region {
  position: absolute;
  z-index: 999;
  left: 2.666667rem;
  top: 1.466667rem;
}

.select-area {
  position: absolute;
  z-index: 999;
  left: 2.666667rem;
  top: 1.466667rem;
}

.select-area .select1 {
  width: 2.4rem;
}

.am-input-control > input {
  color: #333 !important;
}