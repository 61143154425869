.snapshot-box .top-tip {
  padding: 0.133333rem 0;
  font-family: PingFang SC;
  font-size: 0.32rem;
  line-height: 0.426667rem;
  letter-spacing: 0em;
  color: #ED6A0C;
  background: #FFFBE8;
}

.snapshot-box .top-tip p {
  width: 96.3%;
  margin: 0 auto;
}

.snapshot-box .snap-cont {
  margin-top: 0.133333rem;
  background-color: #fff;
  width: 100%;
  padding: 0.48rem 0.4rem;
}

.snapshot-box .snap-cont .com-name {
  font-family: PingFang SC;
  font-size: 0.346667rem;
  font-weight: 600;
  line-height: 0.346667rem;
  letter-spacing: 0em;
  color: #414141;
}

.snapshot-box .snap-cont .med-box {
  display: flex;
  border-bottom: 0.013333rem solid #EEF1F5;
  margin-top: 0.4rem;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.snapshot-box .snap-cont .med-box:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}

.snapshot-box .snap-cont .med-box img {
  height: 2rem;
  width: 2rem;
}

.snapshot-box .snap-cont .med-box .med-info {
  flex: 1 1;
  margin-left: 0.266667rem;
}

.snapshot-box .snap-cont .med-box .med-info .med-name {
  font-family: PingFang SC;
  font-size: 0.346667rem;
  font-weight: 600;
  line-height: 0.346667rem;
  letter-spacing: 0em;
  color: #333333;
}

.snapshot-box .snap-cont .med-box .med-info .med-name span {
  height: 0.4rem;
  width: 0.586667rem;
  display: inline-block;
  font-family: PingFang SC;
  font-size: 0.266667rem;
  line-height: 0.4rem;
  text-align: center;
  letter-spacing: 0em;
  color: #FFFFFF;
  background: #1F87FF;
}

.snapshot-box .snap-cont .med-box .med-info .med-spec {
  margin-top: 0.213333rem;
  font-family: PingFang SC;
  font-size: 0.293333rem;
  line-height: 0.293333rem;
  letter-spacing: 0em;
  color: #333333;
}

.snapshot-box .snap-cont .med-box .med-info .med-num {
  margin-top: 0.373333rem;
}

.snapshot-box .snap-cont .med-box .med-total {
  font-family: PingFang SC;
  font-size: 0.32rem;
  line-height: 0.373333rem;
  text-align: right;
}

.snapshot-box .snap-cont .med-box .med-total .med-snap {
  color: #00C777;
  margin-top: 0.4rem;
  width: 1.813333rem;
  height: 0.746667rem;
  line-height: 0.746667rem;
  border-radius: 0.386667rem;
  opacity: 1;
  border: 0.013333rem solid #00C777;
  text-align: center;
}