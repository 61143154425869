.chatbox {
  width: 100%;
  position: relative;
  height: 100vh;
  padding-bottom: 1.6rem;
}

.chatbox .box-top {
  width: 100%;
  height: 1.706667rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.chatbox .box-top img {
  width: 0.64rem;
  height: 0.64rem;
}

.chatbox .box-top h1 {
  width: 9.333333rem;
  text-align: center;
  font-size: 0.48rem;
  padding-right: 0.266667rem;
}

.chatbox .box-top h1 span {
  font-size: 0.48rem;
  margin-right: 0.333333rem;
}

.chatbox .box-tips {
  width: 100%;
  height: 0.533333rem;
  background-color: white;
  text-align: center;
  font-size: 0.32rem;
}

.chatbox .box-tips .blue {
  color: #4885BD;
}

.chatbox .box-tips .gt {
  margin: 0 0.133333rem;
}

.chatbox .box-bottom {
  position: fixed;
  width: 100%;
  height: 1.333333rem;
  background-color: white;
  left: 0;
  bottom: 0;
  padding: 0.4rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.chatbox .box-bottom input {
  width: 82%;
  height: 0.72rem;
  border-radius: 0.36rem;
  border: none;
  background-color: #e5e2e2;
  padding-left: 0.266667rem;
}

.chatbox .box-bottom button {
  width: 1.2rem;
  height: 0.666667rem;
  background-color: #1F87FF;
  border: none;
  border-radius: 0.333333rem;
  color: white;
}

.chatbox .box-container {
  width: 100%;
  height: 79vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatbox .box-container .container-dan {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  padding: 0.266667rem;
}

.chatbox .box-container .container-dan img {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.4rem;
}

.chatbox .box-container .container-dan .dan-left {
  width: 6.666667rem;
  border-radius: 0.133333rem;
  padding-bottom: 0.266667rem;
  background-color: white;
  border: 0.013333rem solid #dfdfdf;
}

.chatbox .box-container .container-dan .dan-left .left-top {
  width: 100%;
  height: 1.066667rem;
  background-color: #a6cfff;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  line-height: 1.066667rem;
  align-items: center;
}

.chatbox .box-container .container-dan .dan-left .left-top p {
  font-size: 0.4rem;
}

.chatbox .box-container .container-dan .dan-left .itembox {
  padding-left: 0.4rem;
  padding-right: 0.266667rem;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 0.533333rem;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item p {
  text-align: left;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item p:first-child {
  width: 35%;
  color: #999999;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item p:last-child {
  width: 65%;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item .last-bottom {
  display: flex;
  justify-content: space-between;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item .last-bottom span:first-child {
  width: 80%;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item .last-bottom span:last-child {
  width: 20%;
  text-align: right;
  align-items: center;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-item:last-child {
  margin-bottom: 0.266667rem;
}

.chatbox .box-container .container-dan .dan-left .itembox .left-title {
  padding-top: 0.266667rem;
  color: #999999;
  border-top: 0.026667rem solid #dbd8d8;
}

.chatbox .box-container .container-left {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.266667rem;
}

.chatbox .box-container .container-left img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.4rem;
}

.chatbox .box-container .container-left .left-text {
  max-width: 6.666667rem;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
  background-color: white;
  border: 0.013333rem solid #dfdfdf;
}

.chatbox .box-container .container-right {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  padding: 0.266667rem;
}

.chatbox .box-container .container-right img {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.4rem;
}

.chatbox .box-container .container-right .right-img {
  width: 2.666667rem;
  height: auto;
}

.chatbox .box-container .container-right .left-text {
  max-width: 6.666667rem;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
  background-color: white;
  border: 0.013333rem solid #dfdfdf;
}

.chatbox .box-container .container-cfd {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.266667rem;
}

.chatbox .box-container .container-cfd img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.4rem;
}

.chatbox .box-container .container-cfd .dan-left {
  width: 6.666667rem;
  border-radius: 0.133333rem;
  padding-bottom: 0.266667rem;
  background-color: white;
  border: 0.013333rem solid #dfdfdf;
}

.chatbox .box-container .container-cfd .dan-left .left-top {
  width: 100%;
  height: 1.066667rem;
  background-color: #E0FFF4;
  padding-left: 0.666667rem;
  padding-right: 0.4rem;
}

.chatbox .box-container .container-cfd .dan-left .left-top p {
  width: 100%;
  line-height: 1.066667rem;
  font-size: 0.4rem;
  font-weight: 500;
}

.chatbox .box-container .container-cfd .dan-left .itembox {
  padding-left: 0.4rem;
  padding-right: 0.266667rem;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 0.533333rem;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item p {
  text-align: left;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item p:first-child {
  width: 20%;
  color: #999999;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item p:last-child {
  width: 80%;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item p:last-child span {
  margin-right: 0.133333rem;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-item:last-child {
  margin-bottom: 0.266667rem;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-title {
  padding-top: 0.266667rem;
  color: #999999;
  text-align: center;
  border-top: 0.026667rem solid #dbd8d8;
}

.chatbox .box-container .container-cfd .dan-left .itembox .left-title button {
  background-color: white;
  width: 1.733333rem;
  height: 0.6rem;
  border: 0.013333rem solid #f5f2f2;
  border-radius: 0.266667rem;
}

.chatbox .box-container .container-conclusion {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.266667rem;
}

.chatbox .box-container .container-conclusion img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.4rem;
}

.chatbox .box-container .container-conclusion .left-text {
  width: 6.666667rem;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
  background-color: white;
  border: 0.013333rem solid #dfdfdf;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.chatbox .box-container .container-conclusion .left-text img {
  width: 0.533333rem;
  height: 0.533333rem;
}

.chatbox .box-container .container-conclusion .left-text div {
  width: 6.133333rem;
  margin-left: -0.266667rem;
}

.chatbox .box-container .container-conclusion .left-text div p:first-child {
  font-size: 0.373333rem;
  font-weight: 600;
  margin-bottom: 0.066667rem;
}

.chatbox .box-container .container-conclusion .left-text div p:last-child {
  font-size: 0.266667rem;
  color: #999999;
}

.chatbox .box-container .container-evaluate {
  width: 9.2rem;
  height: 2.133333rem;
  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.chatbox .box-container .container-evaluate div {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.333333rem;
}

.chatbox .box-container .container-evaluate div h1 {
  font-size: 0.373333rem;
  margin-bottom: 0.266667rem;
}

.chatbox .box-container .container-evaluate div button {
  width: 5.333333rem;
  height: 0.8rem;
  background-color: #1F87FF;
  border: none;
  border-radius: 0.4rem;
  color: white;
  font-size: 0.4rem;
}

.popup .popup-box {
  padding: 0.4rem;
}

.popup .popup-box .box-top {
  width: 100%;
  height: 1.6rem;
  border-bottom: 0.026667rem solid #F0F0F0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.popup .popup-box .box-top img {
  width: 0.8rem;
  height: 0.8rem;
}

.popup .popup-box .box-top div {
  width: 90%;
}

.popup .popup-box .box-top div h1 {
  font-size: 0.426667rem;
  margin-bottom: 0.2rem;
}

.popup .popup-box .box-top div p span {
  font-size: 0.346667rem;
  color: #666666;
  margin-right: 0.133333rem;
}

.popup .popup-text {
  padding-top: 0.266667rem;
  width: 100%;
  text-align: center;
  margin-bottom: 0.2rem;
}

.popup .popup-text textarea {
  border: none;
  background-color: #F8F8F8;
  padding: 0.066667rem;
}

.popup .popup-btn {
  text-align: center;
}

.popup .popup-btn button {
  width: 4.8rem;
  height: 0.8rem;
  background-color: #1F87FF;
  color: white;
  font-size: 0.4rem;
  border: none;
  border-radius: 0.4rem;
}

.pingfen {
  text-align: center;
  font-size: 0.533333rem;
}