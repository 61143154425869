.active1-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
}

.active1-container .actpage1-header-zhanwei {
  position: absolute;
  top: 0.4rem;
  left: 0.266667rem;
  z-index: 99;
}

.active1-container .actpage1-header-zhanwei > i {
  font-size: 0.64rem;
  color: #fff;
}

.active1-container .actpage1-header-zhanwei > span {
  font-size: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0 0.133333rem;
}

.active1-container .actpage1-header-zhanwei .actpage1-search > i {
  color: #999999;
  font-size: 0.64rem;
  position: absolute;
  left: 32vw;
  top: 0.133333rem;
}

.active1-container .actpage1-header-zhanwei .actpage1-search > input {
  width: 6.306667rem;
  height: 0.8rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  border: none;
  padding-left: 0.933333rem;
  padding-right: 0.4rem;
}

.active1-container .actpage1-content {
  position: fixed;
  top: -0.4rem;
}

.active1-container .actpage1-content > img {
  width: 100vw;
  height: 105%;
}

.active1-container .actpage1-top {
  position: absolute;
  width: 100vw;
  top: 2.666667rem;
  background-color: #fff;
  padding-top: 0.266667rem;
  height: 90vh;
  padding-bottom: 8vh;
  overflow: scroll;
  border-radius: 0.533333rem 0.533333rem 0 0;
}

.active1-container .actpage1-body {
  padding: 0.266667rem 0.4rem;
  border-bottom: 0.013333rem solid #D9D9D9;
}

.active1-container .actpage1-body .actpage1-body-top {
  display: flex;
  justify-content: space-between;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.133333rem;
  overflow: hidden;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-img > img {
  width: 100%;
  height: 100%;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-right {
  width: 74vw;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-right > h2 {
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-right > p {
  line-height: 0.533333rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.active1-container .actpage1-body .actpage1-body-top .actpage1-body-right .actpage1-body-b {
  display: flex;
  justify-content: space-between;
}

.active1-container .actpage1-body-bottom {
  margin-left: 16vw;
  display: flex;
  height: 4rem;
  padding: 0.266667rem 0;
  overflow: hidden;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all {
  margin: 0.066667rem 0.106667rem;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all .actpage1-body-bottom-img {
  height: 2.4rem;
  width: 2.4rem;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all .actpage1-body-bottom-img > img {
  width: 100%;
  height: 100%;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all .actpage1-body-bottom-name {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
  width: 2.266667rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all .actpage1-body-bottom-price > span:first-child {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF3939;
}

.active1-container .actpage1-body-bottom .actpage1-body-bottom-all .actpage1-body-bottom-price > span:last-child {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  color: #999999;
  text-decoration: line-through;
  margin-left: 0.133333rem;
}