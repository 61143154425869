.health-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.health-rmd {
  width: 100%;
  padding: 0.426667rem 0;
  background-color: #FFFFFF;
  margin-top: 0.053333rem;
}

.health-rmd .health-backimg {
  width: 9.2rem;
  height: 3.146667rem;
  margin-bottom: 0.4rem;
  background: #020920;
  border-radius: 0.133333rem;
  background-size: 100%;
  position: relative;
}

.health-rmd .health-backimg .health-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0B0E34;
  opacity: 0.5;
  border-radius: 0.133333rem;
}

.health-rmd .health-backimg .health-title {
  color: #FFFFFF;
  font-size: 0.453333rem;
  font-weight: bold;
  position: absolute;
  bottom: 0.4rem;
  left: 0.4rem;
}

.health-tips {
  width: 100%;
  height: 1.253333rem;
  margin: 0.026667rem 0;
  padding-left: 0.4rem;
  align-items: center;
  background-color: #FFFFFF;
}

.health-tips > img {
  height: 0.533333rem;
  width: auto;
}

.health-tips > span {
  line-height: 0.533333rem;
  font-size: 0.32rem;
  color: #353A42;
  margin-left: 0.133333rem;
}