.classify-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 1.706667rem;
  padding: 0.426667rem;
  background-color: #FFFFFF;
}

.classify-header .iconfont-l {
  font-size: 0.533333rem;
}

.classify-header .classify-search-container {
  width: 7.466667rem;
  height: 0.853333rem;
  border-radius: 0.8rem;
  background: #eef1f5;
  align-items: center;
  color: #C2C5CB;
  padding: 0 0.373333rem;
}

.classify-header .classify-search-container i {
  font-size: 0.533333rem;
  color: #7F7F7F;
}

.classify-header .classify-search-container input {
  font-size: 0.373333rem !important;
  font-weight: normal;
  border: none;
  color: #C2C5CB;
  background: #eef1f5;
}

.classify-header .count {
  width: 0.506667rem;
  height: 0.506667rem;
  line-height: 0.506667rem;
  text-align: center;
  position: absolute;
  right: 0.133333rem;
  margin-top: -0.333333rem;
  background-color: #ff5555;
  color: #fff;
  border-radius: 50%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.classify-body {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.6rem;
  bottom: 1.333333rem;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
}

.classify-body .menu {
  flex: 0 0 2.613333rem;
  width: 2.613333rem;
  background-color: #EEF1F5;
  position: relative;
}

.classify-body .menu .menu-scroll {
  height: 100%;
  padding-bottom: 3.733333rem;
  overflow-y: auto;
  background-color: transparent;
}

.classify-body .menu .menu-scroll .item {
  display: flex;
  box-sizing: border-box;
  height: 1.626667rem;
  padding: 0 0.133333rem 0 0.213333rem;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.classify-body .menu .menu-scroll .item.active {
  background-color: #fff;
  position: relative;
}

.classify-body .menu .menu-scroll .item.active::before {
  content: '' !important;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.106667rem;
  height: 0.533333rem;
  background-color: #1F87FF;
}

.classify-body .menu .menu-scroll .item span {
  font-size: 0.373333rem;
  font-weight: 300;
  color: #333;
}

.classify-body .menu .menu-scroll .item .count {
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.classify-body .detail {
  overflow-y: auto;
  flex: 1 1;
  width: 0;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 3.2rem;
}

.classify-body .detail .item {
  display: flex;
  padding: 0 0.8rem 0 1.066667rem;
  height: 2.666667rem;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.classify-body .detail .item .img {
  flex: 0 0 1.973333rem;
  height: 1.973333rem;
  margin-right: 0.533333rem;
}

.classify-body .detail .item .img img {
  width: 1.973333rem;
  height: 1.973333rem;
  content: none !important;
}

.classify-body .detail .item .title {
  flex: 1 1;
  font-size: 0.373333rem;
  color: #333;
}

.classify-body .detail .item .count {
  flex: 0 0 1.6rem;
  width: 1.6rem;
  text-align: right;
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.classify-body .detail .item.active .title,
.classify-body .detail .item.active .count {
  color: #1F87FF;
}

.classify-body .detail .d-box {
  display: flex;
  flex-wrap: wrap;
}

.classify-body .detail .ill-item {
  width: 33%;
}

.classify-body .detail .ill-item img {
  width: 1.173333rem;
  height: 1.173333rem;
  margin-top: 0.746667rem;
  border-radius: 50%;
}

.classify-body .detail .ill-item span {
  color: #353A42;
  margin-top: 0.186667rem;
  font-size: 0.32rem;
}

.classify-body .other-use {
  width: 100%;
  padding: 0 0.266667rem;
}

.classify-body .other-use ul {
  height: 1.333333rem;
  padding-top: 0.533333rem;
}

.classify-body .other-use ul li {
  float: left;
  text-align: center;
  width: 25%;
  height: 2.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classify-body .other-use ul li img {
  width: 1.173333rem;
  margin-bottom: 0.213333rem;
}

.classify-body .other-use ul li span {
  font-size: 0.373333rem;
}

.classify-body .menu .menu-scroll .item.active::before {
  background-color: #1F87FF;
}