#common-header {
  background-color: #F4F5F7;
}

.order-comment-screen {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 0.4rem;
}

.order-comment-screen .comment-pharmacy {
  font-weight: bolder;
}

.order-comment-screen .comment-pharmacy > span,
.order-comment-screen .comment-pharmacy > i {
  font-size: 0.4rem;
  color: #0B1523;
}

.order-comment-screen .comment-med-list {
  padding: 0.4rem;
  align-items: flex-end;
  justify-content: space-between;
}

.order-comment-screen .comment-med-list .comment-med-img {
  margin-right: 0.266667rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
}

.order-comment-screen .comment-med-list .comment-med-img img {
  width: 100%;
}

.order-comment-screen .comment-med-list .comment-med-detail {
  color: #353A42;
}

.order-comment-screen .comment-med-list .comment-med-detail div {
  margin: 0.133333rem 0;
}

.order-comment-screen .comment-med-list .comment-med-detail div:first-child {
  font-size: 0.346667rem;
}

.order-comment-screen .comment-med-list .comment-med-detail div:nth-child(2) {
  font-size: 0.32rem;
}

.order-comment-screen .comment-med-list .comment-med-detail div:last-child {
  font-size: 0.293333rem;
  color: #C2C5CB;
}

.order-comment-screen .comment-med-list .order-med-line {
  width: 100%;
  background-color: #DDDFE5;
}

.sendcomment-med {
  position: absolute;
  width: 95%;
  height: 9rem;
  top: 3.333333rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: white;
}

.sendcomment-med .sure {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  margin-top: -0.133333rem;
}

.sendcomment-med .sendcomment-img {
  position: absolute;
  top: 1.346667rem;
  width: 100%;
  height: 1.066667rem;
  display: flex;
}

.sendcomment-med .sendcomment-img .yaodian-img {
  width: 1.066667rem;
  height: 1.066667rem;
  background: #EFEFEF;
  margin-right: 0.266667rem;
}

.sendcomment-med .sendcomment-img .yaodian-name {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  line-height: 1.066667rem;
}

.sendcomment-med .sendcomment-detail {
  position: absolute;
  top: 2.8rem;
  color: #353A42;
  display: flex;
}

.sendcomment-med .sendcomment-detail > p {
  line-height: 0.746667rem;
  margin-right: 0.266667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.sendcomment-med .sendcomment-detail > div {
  margin: 0.133333rem 0;
}

.sendcomment-med .sendcomment-detail > div:first-child {
  font-size: 0.346667rem;
}

.sendcomment-med .sendcomment-detail > div:nth-child(2) {
  font-size: 0.32rem;
}

.sendcomment-med .sendcomment-detail .sendcomment-star {
  align-items: center;
}

.sendcomment-med .sendcomment-detail .sendcomment-star > div:first-child {
  width: 2.666667rem;
  align-items: center;
  justify-content: space-between;
}

.sendcomment-med .sendcomment-detail .sendcomment-star > div:first-child i {
  color: #666666;
  font-size: 0.48rem;
}

.sendcomment-med .sendcomment-detail .sendcomment-star > div:first-child .sendcomment-active > i {
  color: #FFB740;
}

.sendcomment-med .sendcomment-detail .sendcomment-star .sendcomment-good {
  margin-left: 0.933333rem;
  font-size: 0.32rem;
}

.sendcomment-med .comment-content {
  position: absolute;
  width: 8.933333rem;
  top: 3.866667rem;
  height: 5.733333rem;
  overflow: hidden;
}

.sendcomment-med .comment-content .sendcomment-area .sendcomment-area-text {
  background-color: #F8F8F8;
}

.sendcomment-med .comment-content .sendcomment-area .sendcomment-area-text .am-textarea-control textarea {
  font-size: 0.373333rem;
}

.sendcomment-med .comment-content .sendcomment-bottom {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  margin: 0.4rem;
  margin-top: 0.666667rem;
}

.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list {
  padding: 0;
}

.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 3.066667rem;
}

.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item {
  background: #F8F8F8 url('../../../static/img/app/addpic.png') no-repeat center;
  background-size: 30%;
  position: absolute;
  z-index: 99;
}

.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input {
  width: 1.6rem;
  height: 1.6rem;
}

.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker-list .am-image-picker-upload-btn:before,
.sendcomment-med .comment-content .sendcomment-bottom .am-image-picker-list .am-image-picker-upload-btn:after {
  display: none;
}

.sendcomment-med .comment-content .sendcomment-bottom > p {
  position: absolute;
  z-index: 100;
  top: 4.266667rem;
  left: 0.533333rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  font-family: MicrosoftYaHei;
  color: #333333;
  height: 0.32rem;
}

.tijiao-top {
  position: absolute;
  right: 0.4rem;
  top: 0.466667rem;
  width: 1.44rem;
  height: 0.693333rem;
  line-height: 0.693333rem;
  text-align: center;
  background: #D8D8D8;
  border-radius: 0.133333rem;
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.medlist-title {
  display: flex;
}

.medlist-title > img {
  width: 1.333333rem;
  margin-bottom: 0.266667rem;
}

.medlist-title .xing {
  margin-left: 0.266667rem;
  line-height: 0.666667rem;
  width: 73%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.medlist-title .xing .med-name {
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}

.medlist-title .xing .xing-right {
  display: flex;
}

.medlist-title > input {
  position: absolute;
  right: 0.4rem;
  margin-top: 0.133333rem;
}

.kuaijie {
  font-size: 0.346667rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  margin-left: 0.133333rem;
}

.kuaijie-biaoqian {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.4rem;
  margin-top: 0.133333rem;
}

.kuaijie-biaoqian > p {
  height: 0.693333rem;
  padding: 0 0.2rem;
  border: 0.013333rem solid #DBDBDB;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.693333rem;
  color: #333333;
  text-align: center;
}

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
  background-color: #1f87ff;
  border-color: #1f87ff;
}