.elebox {
  width: 6rem;
  margin: 0 auto;
  padding: 0.133333rem;
  background-color: #e3e3ec;
}

.elebox .elebox-store {
  width: 100%;
  text-align: center;
  padding-bottom: 0.4rem;
  height: 20rem;
}

.elebox .elebox-store .elebox-store-title h1 {
  font-size: 0.533333rem;
}

.elebox .elebox-store .elebox-store-title .store-title-money {
  font-size: 0.4rem;
}

.elebox .elebox-store .elebox-store-text {
  text-align: left;
}

.elebox .elebox-store .elebox-store-num {
  display: flex;
  flex-wrap: nowrap;
}

.elebox .elebox-store .elebox-store-num p {
  font-size: 0.4rem;
}

.elebox .elebox-store .elebox-store-num p:first-child {
  text-align: left;
  width: 50%;
}

.elebox .elebox-store .elebox-store-info p {
  text-align: left;
}

.elebox .elebox-store .elebox-store-detailed div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.elebox .elebox-store .elebox-store-detailed div p:first-child {
  width: 80%;
  text-align: left;
}

.elebox .elebox-store .elebox-store-detailed div p:last-child {
  width: 20%;
  text-align: right;
}

.elebox .elebox-store .elebox-store-allmoney {
  display: flex;
  justify-content: space-between;
}

.elebox .elebox-store .elebox-store-allmoney p {
  font-size: 0.466667rem;
}

.elebox .elebox-store .elebox-store-allmoney p:first-child {
  width: 40%;
  text-align: left;
}

.elebox .elebox-store .elebox-store-allmoney p:last-child {
  width: 60%;
  text-align: right;
}

.elebox .elebox-store .elebox-store-order p {
  text-align: left;
  font-size: 0.333333rem;
}

.elebox .elebox-store .elebox-store-end {
  padding-top: 0.4rem;
  text-align: center;
  line-height: 0.533333rem;
}

.elebox .elebox-user {
  width: 100%;
  text-align: center;
  padding-bottom: 0.4rem;
  height: 16rem;
}

.elebox .elebox-user .elebox-user-title h1 {
  font-size: 0.533333rem;
}

.elebox .elebox-user .elebox-user-title .user-title-money {
  font-size: 0.4rem;
}

.elebox .elebox-user .elebox-user-text {
  text-align: left;
}

.elebox .elebox-user .elebox-user-num {
  display: flex;
  flex-wrap: nowrap;
}

.elebox .elebox-user .elebox-user-num p {
  font-size: 0.4rem;
}

.elebox .elebox-user .elebox-user-num p:first-child {
  text-align: left;
  width: 50%;
}

.elebox .elebox-user .elebox-user-info p {
  text-align: left;
}

.elebox .elebox-user .elebox-user-detailed div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.elebox .elebox-user .elebox-user-detailed div p:first-child {
  width: 80%;
  text-align: left;
}

.elebox .elebox-user .elebox-user-detailed div p:last-child {
  width: 20%;
  text-align: right;
}

.elebox .elebox-user .elebox-user-allmoney {
  display: flex;
  justify-content: space-between;
}

.elebox .elebox-user .elebox-user-allmoney p {
  font-size: 0.466667rem;
}

.elebox .elebox-user .elebox-user-allmoney p:first-child {
  width: 40%;
  text-align: left;
}

.elebox .elebox-user .elebox-user-allmoney p:last-child {
  width: 60%;
  text-align: right;
}

.elebox .elebox-user .elebox-user-order p {
  text-align: left;
  font-size: 0.333333rem;
}

.elebox .elebox-user .elebox-user-end {
  padding-top: 0.4rem;
  text-align: center;
  line-height: 0.533333rem;
}