.money-box {
  width: 100%;
  height: 7.44rem;
  background-color: #FFFFFF;
}

.moneytop {
  width: 100%;
  height: 1.706667rem;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0.266667rem;
  align-items: center;
  justify-content: space-between;
}

.moneytop p img {
  width: 0.32rem;
  height: 0.32rem;
  vertical-align: middle;
}

.moneytop img {
  width: 0.64rem;
  height: 0.64rem;
}

.moneytop h2 {
  font-size: 0.48rem;
  width: 75%;
  padding-left: 0.666667rem;
  text-align: center;
}

.money-text {
  text-align: center;
  line-height: 1.293333rem;
}

.money-text p:first-child {
  font-size: 0.373333rem;
}

.money-text .allmoney {
  font-size: 0.84rem;
  font-weight: 700;
}

.money-text p:last-child {
  font-size: 0.32rem;
}

.money-btn {
  width: 100%;
  text-align: center;
}

.money-btn button {
  width: 4.8rem;
  height: 1.12rem;
  background-color: #FF5555;
  color: #FFFFFF;
  font-size: 0.426667rem;
  border: none;
  border-radius: 0.56rem;
}