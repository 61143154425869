.top {
  position: relative;
  width: 10rem;
  height: 2.933333rem;
  background: linear-gradient(135deg, #54A3FF 0%, #2086FE 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.top .title {
  display: flex;
}

.top .title > img {
  position: absolute;
  left: 0.4rem;
  top: 0.8rem;
  height: 0.36rem;
}

.top .title > h1 {
  position: absolute;
  top: 0.666667rem;
  right: 3.56rem;
  font-size: 0.48rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
}

.top .tep {
  position: absolute;
  bottom: 0.666667rem;
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.7;
  padding-left: 1.6rem;
}

.top .tep > img {
  height: 0.2rem;
  margin: 0 0.2rem;
}

.tips {
  width: 9.2rem;
  height: 1.946667rem;
  margin-left: 0.4rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  position: absolute;
  margin-top: -0.266667rem;
  text-align: center;
}

.tips > h2 {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  margin-top: 0.4rem;
}

.tips > p {
  -webkit-transform: scale(0.84);
  transform: scale(0.84);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
  opacity: 0.8;
  margin-top: 0.266667rem;
}

.tips > p > span {
  color: #2086FE;
}

.news {
  width: 9.2rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  position: relative;
  margin-top: 1.933333rem;
  margin-left: 0.4rem;
}

.news .am-list-item .am-input-label {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
  position: absolute;
  left: 0;
}

.news .am-list-item .am-input-control > input {
  font-size: 0.373333rem;
  position: absolute;
  left: 3.333333rem;
  right: 0;
  width: 60%;
  bottom: 0.2rem;
}

.news .bing {
  padding: 0.133333rem 0.4rem;
  height: 1.24rem;
  line-height: 1.24rem;
  display: flex;
  justify-content: space-between;
}

.news .bing > span {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
}

.news .bing .a {
  height: 0.493333rem;
  margin-top: 0.4rem;
}

.news .bing .b {
  margin-top: 0.133333rem;
  width: 2.933333rem;
  line-height: 0.866667rem;
  display: flex;
  justify-content: space-between;
}

.news .bing .b > span {
  display: flex;
}

.news .bing .b > span > input {
  margin-top: 0.266667rem;
  margin-right: 0.133333rem;
}

.news .bing .b > span .have {
  margin-right: 1.333333rem;
}

.news > p {
  height: 0.026667rem;
  background: #EEF1F5;
}

.news .ill {
  padding: 0.266667rem 0.4rem;
  overflow: scroll;
  height: 6.533333rem;
}

.news .ill .ill-outer {
  margin-bottom: 0.266667rem;
  padding-bottom: 0.2rem;
  border-bottom: 0.013333rem solid #eee;
}

.news .ill .ill-outer > div {
  display: flex;
}

.news .ill .ill-outer > div > span {
  margin-right: 0.4rem;
  display: flex;
}

.news .ill .ill-outer > div > span > span {
  margin-right: 0.666667rem;
  margin-left: 0.066667rem;
}

.news .ill .word {
  margin-top: 0.2rem;
  height: 0.666667rem;
  width: 8rem;
}

.news1 {
  width: 9.2rem;
  height: 3.666667rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  position: relative;
  margin-top: 1.933333rem;
  margin-left: 0.4rem;
  padding: 0.066667rem 0.4rem;
}

.news1 .select {
  height: 0.493333rem;
  line-height: 0.133333rem;
  color: #353A42;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.news1 .select .select-left {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: -0.4rem;
}

.news1 .select .select-right {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 0.32rem;
}

.news1 .select .select-right > span {
  color: #2086FE;
}

.news1 .add::-webkit-scrollbar {
  display: none;
}

.news1 .add {
  height: 2rem;
  white-space: nowrap;
  overflow: scroll;
}

.news1 .add .add-news {
  margin-right: 0.266667rem;
  width: 3.466667rem;
  height: 1.733333rem;
  display: inline-block;
  box-sizing: border-box;
  background: #F8F8F8;
  border: 0.013333rem solid #707070;
  border-radius: 0.133333rem;
  padding: 0.266667rem 0.4rem;
}

.news1 .add .add-news > div {
  display: flex;
  justify-content: space-between;
}

.news1 .add .add-news > div .name {
  font-size: 0.426667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news1 .add .add-news > div > img {
  width: 0.32rem;
  height: 0.32rem;
}

.news1 .add .add-news > p {
  -webkit-transform: scale(0.84);
  transform: scale(0.84);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 0.32rem;
  margin-left: -0.133333rem;
}

.news1 .add .add-news > p .sex {
  margin-right: 0.4rem;
}

.news1 .add .add-news .del-phone {
  display: flex;
  justify-content: space-between;
}

.news1 .add .add-news .del-phone > span {
  margin-left: -0.266667rem;
}

.news1 .add .add-news .del-phone > img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: -0.266667rem;
}

.disease {
  position: relative;
  margin-top: 0.266667rem;
  width: 9.2rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  margin-left: 0.4rem;
  padding: 0.4rem;
}

.disease > p {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.4rem;
  color: #353A42;
}

.disease .confirmed {
  margin-top: 0.666667rem;
  display: flex;
  flex-wrap: wrap;
}

.disease .confirmed > span {
  margin-bottom: 0.133333rem;
  border: 0.013333rem solid #D8D8D8;
  border-radius: 0.026667rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
  padding: 0.133333rem 0.266667rem;
  margin-right: 0.266667rem;
}

.disease .bgc-popup {
  width: 100vw;
  height: 100vh;
  top: -13.2rem;
  position: absolute;
  z-index: 200;
  margin-left: -0.8rem;
}

.disease .bgc-popup .popup-top {
  width: 100vw;
  height: 9.6rem;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 201;
  top: 2.8rem;
}

.disease .bgc-popup .popup {
  width: 10rem;
  height: 10rem;
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: #fff;
  position: absolute;
  z-index: 201;
  top: 12rem;
  padding: 0.4rem 0.4rem 0 0.4rem;
}

.disease .bgc-popup .popup > h3 {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  text-align: center;
}

.disease .bgc-popup .popup .sousuo {
  display: flex;
  align-items: center;
}

.disease .bgc-popup .popup .sousuo .shuru {
  margin-top: 0.133333rem;
  width: 8rem;
  height: 1.173333rem;
  background: #F8F8F8;
  border-radius: 0.586667rem;
}

.disease .bgc-popup .popup .sousuo .add {
  margin-left: 0.266667rem;
  font-size: 0.4rem;
}

.used {
  width: 9.2rem;
  height: 1.813333rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  position: relative;
  margin-left: 0.4rem;
  margin-top: 0.266667rem;
  padding: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.used .used-left .used-left-top {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #353A42;
}

.used .used-left .used-left-bottom {
  -webkit-transform: scale(0.84);
  transform: scale(0.84);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: -0.4rem;
  margin-top: 0.266667rem;
}

.used .used-right {
  width: 2.933333rem;
  line-height: 0.866667rem;
  display: flex;
  justify-content: space-between;
}

.used .used-right > p {
  display: flex;
}

.used .used-right > p > input {
  margin-top: 0.266667rem;
  margin-right: 0.133333rem;
}

.used .used-right > p .use {
  margin-right: 1.333333rem;
}

.continue {
  width: 10rem;
  height: 2.853333rem;
  background: #FFFFFF;
  border-radius: 0;
  margin-top: 1.066667rem;
  bottom: 0;
  padding: 0.4rem 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.continue > p {
  font-size: 0.293333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.4rem;
}

.continue > p > span {
  color: #2086FE;
}

.continue > div {
  height: 1.133333rem;
  width: 8.8rem;
  margin-left: 0.4rem;
}

.continue > div > img {
  height: 1.133333rem;
  width: 8.8rem;
}

.continue > div > span {
  position: absolute;
  left: 4.666667rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  line-height: 0.4rem;
  color: #FFFFFF;
}

.continue1 {
  width: 10rem;
  height: 1.6rem;
  position: relative;
  border-radius: 0;
  position: fixed;
  margin-top: 1.066667rem;
  bottom: 0.666667rem;
  padding: 0.4rem 0.133333rem;
}

.continue1 > div {
  height: 1.133333rem;
  width: 8.8rem;
  margin-left: 0.4rem;
  padding-top: 0.4rem;
}

.continue1 > div > img {
  height: 1.133333rem;
  width: 8.8rem;
}

.continue1 > div > span {
  position: absolute;
  left: 4rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  line-height: 0.4rem;
  color: #FFFFFF;
}

.continue1 .xiugai > span {
  position: absolute;
  left: 3.333333rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  line-height: 0.4rem;
  color: #FFFFFF;
}

.know {
  width: 7.52rem;
  height: 4.786667rem;
  background: #FFFFFF;
  border: 0.013333rem solid #707070;
  border-radius: 0.266667rem;
  position: absolute;
  top: 40%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.know .word {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei-Bold;
  line-height: 0.693333rem;
  color: #414141;
  padding: 0.666667rem 0.4rem;
  border-bottom: 0.04rem solid #eee;
}

.know .yes {
  text-align: center;
  line-height: 1.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #1F87FF;
}

.del-address {
  position: absolute;
  z-index: 9999;
  width: 6.666667rem;
  height: 3.466667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.am-list-item .am-input-label.am-input-label-5 {
  width: 2.533333rem;
}