.mine-top {
  height: 9.066667rem;
  display: flex;
  flex-direction: column;
}

.mine-top .mine-back {
  width: 100%;
  height: 2.4rem;
  background-color: #2086FE;
  color: #FFFFFF;
  padding: 0.64rem 0.4rem;
}

.mine-top .mine-back .back-left {
  display: flex;
  flex-direction: row;
}

.mine-top .mine-back .back-left i {
  font-size: 1.333333rem;
}

.mine-top .mine-back .back-left .mine-avatar {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  overflow: hidden;
}

.mine-top .mine-back .back-left .mine-avatar img {
  width: 100%;
}

.mine-top .mine-back .back-left .user-info {
  margin-left: 0.266667rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.266667rem 0 0 0;
}

.mine-top .mine-back .back-left .user-info span:nth-child(1) {
  font-size: 0.453333rem;
}

.mine-top .mine-back .back-left .user-info span:nth-child(2) {
  font-size: 0.293333rem;
}

.mine-top .mine-back .back-right {
  display: flex;
  flex-direction: row;
}

.mine-top .mine-back .back-right i {
  font-size: 0.533333rem;
  margin-left: 0.426667rem;
  color: #FFFFFF;
}

.mine-top .mine-back .back-right .count {
  width: 0.506667rem;
  height: 0.506667rem;
  line-height: 0.506667rem;
  text-align: center;
  position: absolute;
  margin-left: -0.2rem;
  margin-top: -0.133333rem;
  background-color: #ff5555;
  border-radius: 50%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.mine-top .mine-vip {
  width: 100%;
  height: 1.866667rem;
  border-radius: 0 0 60% 60%;
  background: #2086FE;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mine-top .mine-vip .vip-card {
  width: 8.186667rem;
  height: 2.4rem;
  border-radius: 0.266667rem;
  background-image: url('../../static/img/vipcard.png');
  background-size: 100%;
  color: #E6C3A0;
  font-size: 0.533333rem;
  padding: 0 0.533333rem 0.533333rem 0.533333rem;
}

.mine-top .mine-vip .vip-card img {
  width: 1.226667rem;
  margin-right: 0.4rem;
}

.mine-top .mine-vip .vip-card span {
  font-size: 0.293333rem;
}

.mine-top .mine-vip .vip-card .open-btn {
  font-size: 0.346667rem !important;
  width: 1.866667rem;
  line-height: 0.506667rem;
  border-radius: 0.293333rem;
  background-color: #E6C3A0;
  color: #3F4246;
}

.mine-top .mine-order-title {
  width: 100%;
  height: 0.533333rem;
  padding: 0 0.4rem;
  margin-top: 0.426667rem;
}

.mine-top .mine-order-title span {
  color: #353A42;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.mine-top .mine-order-title i {
  font-size: 0.373333rem;
}

.mine-top .mine-order {
  width: 100%;
  height: 2.133333rem;
  padding: 0 1.066667rem;
}

.mine-top .mine-order i {
  font-size: 0.8rem;
  color: #87BEFF;
}

.mine-top .mine-order img {
  width: 0.613333rem;
}

.mine-top .mine-order span {
  font-size: 0.293333rem;
  color: #666666;
  margin-top: 0.133333rem;
}

.mine-top .mine-order .order-count {
  position: absolute;
  width: 0.533333rem;
  height: 0.533333rem;
  border-radius: 50%;
  background-color: #FF5555;
  margin-left: 0.666667rem;
  margin-top: -0.266667rem;
  line-height: 0.533333rem;
  text-align: center;
  color: #fff;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.mine-middle {
  background-color: #2086FE;
  height: 1.813333rem;
  padding: 0 1.333333rem;
}

.mine-middle .mine-middle-num {
  font-size: 0.453333rem;
  margin-bottom: 0.133333rem;
}

.mine-middle span {
  font-size: 0.32rem;
  color: #FFFFFF;
}

.mine-middle div {
  text-align: center;
  font-size: 0.32rem;
}

.mine-bottom {
  margin-top: 0.266667rem;
  padding: 0.4rem 0.4rem 0 0.4rem;
  height: 3.306667rem;
  position: relative;
}

.mine-bottom > span {
  color: #0B1523;
  font-size: 0.373333rem;
  font-weight: bold;
  line-height: 0.533333rem;
}

.mine-bottom .mine-pocket {
  width: 100%;
}

.mine-bottom .mine-pocket ul {
  height: 1.333333rem;
  padding-top: 0.4rem;
}

.mine-bottom .mine-pocket ul li {
  float: left;
  text-align: center;
  width: 20%;
  height: 2.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mine-bottom .mine-pocket ul li img {
  width: 1.173333rem;
  margin-bottom: 0.213333rem;
}

.mine-bottom .mine-pocket ul li span {
  font-size: 0.293333rem;
  color: #666666;
}

.mine-top,
.mine-bottom {
  width: 100%;
  background-color: white;
  display: block;
}

.pf-box {
  background-color: #F3F3F3;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0.266667rem;
}

.pf-box .pf-top {
  padding-top: 0.266667rem;
  width: 100%;
  display: flex;
  margin-bottom: 0.533333rem;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.pf-box .pf-top .pf-top-left img {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.133333rem;
}

.pf-box .pf-top .pf-top-left span {
  font-size: 0.4rem;
}

.pf-box .pf-top .pf-top-right img {
  width: 0.586667rem;
  height: 0.586667rem;
}

.pf-box .pf-top .pf-top-right img:last-child {
  margin-left: 0.4rem;
}

.pf-box .pf-store {
  width: 100%;
  height: 3.373333rem;
  margin-bottom: 0.266667rem;
  background-color: #FFFFFF;
  border-radius: 0.133333rem;
}

.pf-box .pf-store .pf-store-top {
  height: 1.293333rem;
  width: 100%;
  line-height: 1.293333rem;
  font-size: 0.373333rem;
  font-weight: 700;
  padding-left: 0.4rem;
  border-bottom: 0.013333rem solid #EEF1F5;
}

.pf-box .pf-store .pf-store-bottom {
  height: 2.053333rem;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.pf-box .pf-store .pf-store-bottom div {
  width: 15%;
  text-align: center;
}

.pf-box .pf-store .pf-store-bottom div img {
  width: 0.573333rem;
  height: 0.573333rem;
  margin-bottom: 0.24rem;
}

.pf-box .pf-dan {
  width: 100%;
  height: 3rem;
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
  background-color: #FFFFFF;
}

.pf-box .pf-dan h2 {
  padding: 0;
  padding-left: 0.4rem;
  margin: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.373333rem;
  color: #0B1523;
}

.pf-box .pf-dan .pf-dan-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.pf-box .pf-dan .pf-dan-item .item-4 {
  border-right: 0.013333rem solid #E5E5E5;
  width: 20%;
}

.pf-box .pf-dan .pf-dan-item div {
  width: 15%;
  text-align: center;
}

.pf-box .pf-dan .pf-dan-item div img {
  width: 0.573333rem;
  height: 0.573333rem;
  margin-bottom: 0.24rem;
}

.pf-box .pf-bottom {
  width: 100%;
  height: 3rem;
  border-radius: 0.133333rem;
  background-color: #FFFFFF;
  margin-bottom: 0.266667rem;
}

.pf-box .pf-bottom h2 {
  padding: 0;
  padding-left: 0.4rem;
  margin: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.373333rem;
  color: #0B1523;
}

.pf-box .pf-bottom .pf-bottom-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.pf-box .pf-bottom .pf-bottom-item .item-4 {
  border-right: 0.013333rem solid #E5E5E5;
  width: 20%;
}

.pf-box .pf-bottom .pf-bottom-item div {
  width: 15%;
  text-align: center;
}

.pf-box .pf-bottom .pf-bottom-item div img {
  width: 0.64rem;
  height: 0.64rem;
  margin-bottom: 0.24rem;
}

.pf-box .pf-bill {
  width: 100%;
  height: 3rem;
  border-radius: 0.133333rem;
  background-color: #FFFFFF;
}

.pf-box .pf-bill h2 {
  padding: 0;
  padding-left: 0.4rem;
  margin: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.373333rem;
  color: #0B1523;
}

.pf-box .pf-bill .pf-bill-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 0.4rem;
}

.pf-box .pf-bill .pf-bill-item div {
  width: 15%;
  text-align: center;
}

.pf-box .pf-bill .pf-bill-item div img {
  width: 0.64rem;
  height: 0.64rem;
  margin-bottom: 0.24rem;
}

.pingtai-zizhi {
  width: 100%;
  position: relative;
  z-index: 99;
  margin-top: 0.666667rem;
  margin-bottom: 0.866667rem;
}

.pingtai-zizhi > h1 {
  text-align: center;
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #B8B8B8;
}

.pingtai-zizhi .pingtai-zizhi-renzheng {
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  justify-content: space-between;
}

.pingtai-zizhi .pingtai-zizhi-renzheng > p {
  margin-top: 0.266667rem;
  display: flex;
}

.pingtai-zizhi .pingtai-zizhi-renzheng > p > img {
  width: 0.4rem;
  margin-right: 0.133333rem;
}

.pingtai-zizhi .pingtai-zizhi-renzheng > p > span {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #B8B8B8;
}

.pingtai-zizhi .pingtai-bottom {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #B8B8B8;
  text-align: center;
  margin-top: 0.2rem;
}