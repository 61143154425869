.my-order .am-tabs-default-bar-tab-active {
  font-weight: bold;
}

.colectitem {
  width: 100%;
  height: 2.933333rem;
  background-color: #FFFFFF;
  padding: 0.32rem 0.4rem;
  display: flex;
  flex-direction: row;
}

.colectitem .colectitem-img > img {
  width: 2.266667rem;
}

.colectitem .colectitem-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.32rem;
  width: 100%;
  margin-left: 0.533333rem;
}

.colectitem .colectitem-text > div > h1 {
  font-size: 0.346667rem;
}

.colectitem .colectitem-text > div > span {
  font-size: 0.24rem;
}

.colectitem .colectitem-text > span {
  font-size: 0.346667rem;
}

.my-order-item {
  width: 100%;
  height: 85vh;
  overflow: scroll;
  background-color: #EEF1F5;
}

.itm {
  width: 100%;
  height: 0.8rem;
  background-color: blue;
}