.all-jifen {
  width: 100vw;
  height: 1.96rem;
  background: #1F87FF;
  position: fixed;
  top: 1.6rem;
  display: flex;
  justify-content: space-between;
  padding: 0.266667rem 0.4rem;
}

.all-jifen > p {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
}

.all-jifen .count {
  font-size: 0.96rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 1.466667rem;
  color: #FFFFFF;
}

.jifen-mingxi {
  position: relative;
  top: 3.733333rem;
  background-color: #fff;
  padding: 0.4rem 0 0.4rem 0.4rem;
}

.jifen-mingxi .jifen-allinner {
  position: relative;
  height: 80vh;
  overflow: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.jifen-mingxi > h1 {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #414141;
  margin-bottom: 0.4rem;
}

.jifen-mingxi .jifen-mingxi-outer {
  padding: 0.266667rem 0;
  display: flex;
  justify-content: space-between;
}

.jifen-mingxi .jifen-mingxi-outer .jifen-mingxi-left > p:first-child {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #414141;
}

.jifen-mingxi .jifen-mingxi-outer .jifen-mingxi-left > p:last-child {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.533333rem;
  color: #999999;
}

.jifen-mingxi .jifen-mingxi-outer .jifen-mingxi-right {
  margin-right: 0.533333rem;
  font-size: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 1.2rem;
  color: #FB4949;
}

.jifen-mingxi .jifen-mingxi-outer .jifen-mingxi-right1 {
  font-size: 0.48rem;
  margin-right: 0.533333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 1.2rem;
  color: #1EB857;
}