.health-header {
  width: 100%;
  height: 1.626667rem;
  background-color: #fff;
  position: relative;
}

.health-header > p {
  font-size: 0.32rem;
  line-height: 0.4rem;
  color: #666666;
  z-index: 2;
}

.health-header > i {
  margin-right: 0.4rem;
  font-size: 0.533333rem;
  z-index: 2;
}

.health-header .health-header-classify {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.health-header .health-header-classify > div {
  width: 25%;
  height: 100%;
  font-size: 0.453333rem;
  color: #414141;
  text-align: center;
}

.health-header .health-header-classify .health-choose-tab {
  height: 0.133333rem;
  position: absolute;
  border-bottom: 0.133333rem solid #1F87FF;
  -webkit-transform: scaleX(0.3);
  transform: scaleX(0.3);
  width: 25%;
  bottom: 0;
  transition: all 0.2s;
}

.health-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 1.28rem;
  background-color: #EEF1F5;
  overflow: scroll;
}

.health-edit {
  width: 1.226667rem;
  height: 1.226667rem;
  border-radius: 0.613333rem;
  background-color: #1F87FF;
  color: #FFFFFF;
  position: fixed;
  right: 0.4rem;
  bottom: 1.6rem;
  z-index: 999;
}

.health-edit > i {
  font-size: 0.533333rem;
}

.health-recommand {
  width: 100%;
  height: 6.72rem;
  background-color: #FFFFFF;
  margin-top: 0.053333rem;
  padding: 0 0.4rem;
}

.health-recommand > p {
  line-height: 1.386667rem;
  color: #414141;
  font-size: 0.453333rem;
}

.health-recommand > img {
  width: 100%;
  display: block;
  margin-bottom: 0.026667rem;
}

.health-recommand > ul {
  text-decoration: none;
  width: 100%;
}

.health-recommand > ul .health-topic {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.health-recommand > ul > li {
  float: left;
  width: 50%;
  margin: 0.186667rem 0;
}

.health-recommand > ul > li > img {
  height: 0.533333rem;
  width: auto;
}

.health-recommand > ul > li > span {
  line-height: 0.533333rem;
  font-size: 0.32rem;
  color: #353A42;
  margin-left: 0.133333rem;
}

.wechat-news {
  width: 100%;
}

.wechat-news .wechat-news-title {
  background-color: #FFFFFF;
  padding: 0.266667rem 0.4rem;
}

.wechat-news .wechat-news-title > span {
  color: #1F87FF;
  font-size: 0.453333rem;
}

.wechat-news .wechat-news-title .wechat-news-order span {
  color: #666666;
}