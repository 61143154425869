#common-header {
  width: 100%;
  height: 1.68rem;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#common-header * {
  color: #414141;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
}

#common-header .back-icon {
  width: 0.666667rem;
  text-align: left;
  margin-left: 0.066667rem;
  align-items: center;
  padding-left: 0.066667rem;
}

#common-header .back-icon i {
  font-size: 0.533333rem;
}

#common-header .middle-header {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#common-header .middle-header > span {
  font-size: 0.426667rem;
}

#common-header .middle-header .search-container {
  background-color: #EEF1F5;
  width: 7.466667rem;
  border-radius: 0.4rem;
  overflow: hidden;
  padding: 0.133333rem;
}

#common-header .middle-header .search-container i {
  font-size: 0.533333rem;
  color: #ccc !important;
}

#common-header .middle-header .search-container input {
  width: 100%;
  font-size: 0.373333rem !important;
  font-weight: normal;
  border: none;
  background: #EEF1F5;
  color: #C2C5CB !important;
}

#common-header .middle-header .search-container input::-webkit-input-placeholder {
  color: #C2C5CB !important;
}

#common-header .middle-header .search-container input:-ms-input-placeholder {
  color: #C2C5CB !important;
}

#common-header .middle-header .search-container input::placeholder {
  color: #C2C5CB !important;
}

#common-header .middle-header .header-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#common-header .middle-header .header-tab > div {
  margin: 0 0.133333rem;
  position: relative;
}

#common-header .middle-header .header-tab > div > span {
  font-size: 0.226667rem  !important;
}

#common-header .middle-header .header-tab > div .active-line {
  position: absolute;
  bottom: -0.213333rem;
  left: 50%;
  width: 0.32rem;
  height: 0.08rem;
  background-color: white;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#common-header .right-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: left;
  margin-right: 0.133333rem;
  font-size: 0.266667rem;
}

#common-header .right-header .right-icon > i {
  font-size: 0.533333rem;
}

#common-header .right-header > div {
  margin-right: 0.133333rem;
  font-size: 0.346667rem;
}

#common-header .right-header > span {
  text-align: right;
  padding-right: 0.266667rem;
}

#common-header .header-zhanwei {
  width: 0.666667rem;
}