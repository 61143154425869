.MX-outer {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  box-sizing: border-box;
  padding-left: 0.266667rem;
  padding-right: 0.266667rem;
  background-color: #FFFFFF;
}

.MX-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.MX-top img {
  width: 0.64rem;
  height: 0.64rem;
}

.MX-top h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.MX-text {
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.MX-text div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 1.146667rem;
  width: 100%;
  align-items: center;
}

.MX-text div:first-child {
  font-size: 0.373333rem;
}

.MX-text div:last-child {
  font-size: 0.32rem;
  color: #999999;
}