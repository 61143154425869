.shoplist_item {
  padding: 0 0.186667rem 0 0.466667rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  color: #666;
  list-style: none;
  font-size: 0.373333rem;
}

.shoplist_item .shoplist_logo {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.shoplist_item .shoplist_logo img {
  width: 100%;
  border-radius: 0.08rem;
}

.shoplist_item .shoplist_logo .shoplist_logo_num {
  position: absolute;
  right: -0.186667rem;
  top: -0.186667rem;
  color: #fff;
  background-image: linear-gradient(-90deg, #ff7416, #ff3c15 98%);
  border-radius: 0.2rem;
  font-size: 0.266667rem;
  font-weight: 700;
  text-align: center;
  min-width: 0.373333rem;
  padding: 0 0.106667rem;
  line-height: 0.373333rem;
}

.shoplist_item .show_new_logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 1.706667rem;
  height: 1.706667rem;
  background-image: linear-gradient(135deg, #26ce61, #26ce61 50%, transparent 0);
  font-size: 0.64rem;
}

.shoplist_item .show_new_logo span {
  position: absolute;
  top: 0.08rem;
  left: 0;
  display: block;
  color: #fff;
  font-weight: 700;
  -webkit-transform: rotate(-45deg) scale(0.8);
  transform: rotate(-45deg) scale(0.8);
}

.shoplist {
  border-bottom: 0.026667rem solid #EEF1F5;
}

.shoplist_main {
  display: flex;
  width: 6.933333rem;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0.333333rem;
}

.shoplist_main .show_line2 {
  margin-top: 0.08rem;
}

.shoplist_main .line {
  width: 100%;
  margin: 0.186667rem 0;
  height: 0.026667rem;
}

.shoplist_main .show_money {
  color: #354242;
}

.shoplist_main .show_money span {
  font-size: 0.32rem;
}

.shoplist_main .show_money span:nth-of-type(2) {
  overflow: hidden;
  max-width: 2.666667rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shoplist_main .show_money span:nth-of-type(2)::before {
  content: "|";
  margin: 0 0.04rem;
  color: #ddd;
}

.shoplist_main .show_line {
  display: flex;
  justify-content: space-between;
  font-size: 0.426667rem;
}

.shoplist_main .show_line h3 {
  display: flex;
  color: #333;
  font-weight: 700;
  max-width: 6.133333rem;
  height: 0.853333rem;
  font-size: 0.426667rem;
  align-items: center;
}

.shoplist_main .show_line h3 span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.373333rem;
}

.shoplist_main .show_line .is_premium::before {
  content: "\54C1\724C";
  margin-right: 0.213333rem;
  padding: 0 0.133333rem;
  border-radius: 0.08rem;
  color: #6f3f15;
  background-image: linear-gradient(-139deg, #fff100, #ffe339);
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  font-size: 0.533333rem;
}

.shoplist_main .show_line .show_time {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  color: #818D8D;
}

.shoplist_main .show_line .show_time span:nth-of-type(2)::before {
  content: "|";
  margin: 0 0.08rem;
  color: #ddd;
}

.shoplist_main .show_line .show_kd {
  display: flex;
  padding: 0 0.053333rem;
  border-radius: 0.106667rem;
  border: 0.026667rem solid #44a5ff;
  background-image: linear-gradient(45deg, #0085ff, #0af);
}

.shoplist_main .show_line .show_kd span {
  color: #fff;
  font-size: 0.373333rem;
  text-align: center;
}

.shoplist_main .show_line .show_rateWrap {
  display: flex;
  align-items: center;
}

.shoplist_main .show_line .show_rateWrap .show_num {
  font-size: 0.373333rem;
  color: #818D8D;
}

.shoplist_main .show_line .show_rateWrap .show_star {
  overflow: hidden;
}

.shoplist_main .show_line .show_rateWrap .show_star svg {
  width: 1.493333rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.shoplist_main .show_supportWrap {
  display: flex;
}

.shoplist_main .show_supportWrap li {
  display: block;
  margin-left: 0.106667rem;
  font-size: 0.373333rem;
  padding: 0.053333rem;
  border-radius: 0.106667rem;
  color: #999;
  border: 0.026667rem solid #ddd;
  text-align: center;
}

.noMore,
.loadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.066667rem;
  background-color: #fff;
  color: #555;
  font-size: 0.346667rem;
}

@-webkit-keyframes loadding {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadding {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadMore svg {
  margin-right: 0.266667rem;
  -webkit-animation: loadding 0.8s linear infinite;
  animation: loadding 0.8s linear infinite;
}

.show_active {
  display: flex;
  flex-direction: row;
  margin-top: 0.453333rem;
}

.show_active .active-view {
  font-size: 0.16rem;
  height: 0.373333rem;
  line-height: 1;
  margin-left: 0.133333rem;
  color: #44a5ff;
  border: 0.026667rem solid #44a5ff;
  border-radius: 0.053333rem;
}