.header {
  width: 100%;
  background-color: #F4F5F7;
}

.header .drawback-header {
  padding-top: 0.2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.header .drawback-header .back-icon {
  position: absolute;
  width: 0.64rem;
  height: 0.64rem;
  margin-left: 0.266667rem;
  align-items: center;
  top: 0.6rem;
}

.header .drawback-header .back-icon i {
  font-size: 0.533333rem;
  color: #666;
}

.header .drawback-header .order-kefu {
  position: relative;
  left: 8.933333rem;
  height: 0.533333rem;
  top: 0.4rem;
}

.header .drawback-header .order-kefu > img {
  height: 0.533333rem;
}

.header .drawback {
  position: relative;
  top: 0.933333rem;
  padding: 0.4rem;
  font-size: 0.533333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0B1523;
}

.header .teshu-drawback {
  position: relative;
  top: 1.066667rem;
  height: 2.386667rem;
  background-color: #fff;
  padding: 0.4rem;
}

.header .teshu-drawback .teshu-drawback-title {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  line-height: 0.533333rem;
}

.header .teshu-drawback .teshu-drawback-explain {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
}

.header .pharmacy {
  position: relative;
  top: 1.4rem;
  width: 10rem;
  padding: 0.4rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
}

.header .pharmacy .phname {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}

.header .pharmacy .pharmacy-name {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
}

.header .pharmacy .pharmacy-name > input {
  margin-top: 0.4rem;
  margin-right: 0.133333rem;
}

.header .pharmacy .pharmacy-name .flexr {
  margin-left: -0.533333rem;
}

.header .pharmacy .pharmacy-name .flexr .flexr-right {
  padding-top: 0.4rem;
  height: 0.666667rem;
  line-height: 0.466667rem;
}

.header .pharmacy .pharmacy-name .flexr .flexr-right > p {
  width: 6rem;
  display: flex;
  justify-content: space-between;
}

.header .pharmacy .pharmacy-name .flexr .flexr-right > p .price {
  font-size: 0.346667rem;
  padding-right: 0.133333rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}

.header .pharmacy .xian {
  height: 0.026667rem;
  background: #EEF1F5;
}

.header .pharmacy .all-select {
  display: flex;
  justify-content: space-between;
  height: 0.933333rem;
  line-height: 1.066667rem;
  padding-top: 0.266667rem;
  padding-right: 0.133333rem;
}

.header .pharmacy .all-select > p {
  display: flex;
  justify-content: start;
  align-items: center;
}

.header .pharmacy .all-select > p > input {
  margin-right: 0.266667rem;
}

.header .pharmacy .all-select > p .total {
  margin-top: -0.133333rem;
  font-size: 0.453333rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}

.header .sendcomment-yuanyin {
  position: relative;
  top: 1.733333rem;
  width: 10rem;
  height: 7.066667rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  padding: 0.4rem;
}

.header .sendcomment-yuanyin .sure {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
}

.header .sendcomment-yuanyin .comment-content {
  position: relative;
  top: 0.4rem;
  width: 9.6rem;
  margin-left: -0.4rem;
}

.header .sendcomment-yuanyin .comment-content .am-textarea-control {
  height: 4.8rem;
  background-color: #F8F8F8;
}

.header .sendcomment-yuanyin .comment-content .am-textarea-control > textarea {
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  color: #CCCCCC;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.header .sendcomment-yuanyin .comment-content .am-textarea-count {
  position: absolute;
  bottom: 0.533333rem;
  right: 0.4rem;
  color: #bbb;
  font-size: 0.186667rem;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  margin: 0.4rem;
  margin-top: 0.666667rem;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list {
  padding: 0;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 2.933333rem;
  margin-left: 0.4rem;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item .am-image-picker-item {
  background: #F8F8F8 url('../../../static/img/app/addpic.png') no-repeat center;
  background-size: 30%;
  position: absolute;
  z-index: 99;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker .am-image-picker-list .am-flexbox .am-flexbox-item input {
  width: 1.6rem;
  height: 1.6rem;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker-list .am-image-picker-upload-btn:before,
.header .sendcomment-yuanyin .comment-content .sendcomment-bottom .am-image-picker-list .am-image-picker-upload-btn:after {
  display: none;
}

.header .sendcomment-yuanyin .comment-content .sendcomment-bottom > p {
  position: absolute;
  z-index: 100;
  top: 4.066667rem;
  left: 0.933333rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  font-family: MicrosoftYaHei;
  color: #333333;
  height: 0.32rem;
}

.tuikuan-tijiao {
  position: absolute;
  margin-top: 2rem;
  height: 1.133333rem;
  display: flex;
  justify-content: center;
  width: 8.8rem;
  margin-left: 0.266667rem;
}

.tuikuan-tijiao .tuikuan-tijiao-left {
  height: 1.133333rem !important;
  height: 5.426667rem;
  margin-left: 0.6rem;
}

.tuikuan-tijiao .tuikuan-money-left {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -0.933333rem;
  left: 0.933333rem;
}

.tuikuan-tijiao .tuikuan-money-left .tuikuan-money-left-top {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: MicrosoftYaHei;
  color: #414141;
}

.tuikuan-tijiao .tuikuan-money-left .tuikuan-money-left-bottom {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  font-family: MicrosoftYaHei;
  color: #414141;
  margin-left: -0.6rem;
}

.tuikuan-tijiao .tuikuan-tijiao-right {
  height: 1.133333rem !important;
  height: 3.373333rem;
}

.tuikuan-tijiao .tuikuan-money-right {
  position: relative;
  top: -0.866667rem;
  left: 1.133333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}