#home-header {
  width: 100%;
  height: 1.946667rem;
  padding: 0.08rem 0.4rem 0 0.4rem;
  color: #FFFFFF;
}

.home-header-left {
  width: 2.133333rem;
  align-items: center;
}

.home-header-left :first-child {
  font-size: 0.373333rem !important;
}

.home-header-middle {
  width: 5.6rem;
}

.home-header-middle .search-container {
  background-color: #FFFFFF;
  border-radius: 0.426667rem;
  overflow: hidden;
  padding: 0.133333rem;
}

.home-header-middle .search-container i {
  font-size: 0.533333rem!important;
  color: #C2C5CB !important;
}

.home-header-middle .search-container input {
  width: 100%;
  font-size: 0.346667rem !important;
  font-weight: normal;
  border: none;
}

.home-header-right {
  width: 2.133333rem;
  height: 1.066667rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.home-header-right i {
  font-size: 0.533333rem !important;
  margin-left: 0.373333rem;
  color: #FFFFFF;
}

.home-header-right .count {
  width: 0.506667rem;
  height: 0.506667rem;
  line-height: 0.506667rem;
  text-align: center;
  position: absolute;
  margin-left: -0.2rem;
  margin-top: -0.133333rem;
  background-color: #ff5555;
  border-radius: 50%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}