.billinfobox {
  width: 100%;
  height: 100vh;
  background-color: white;
}

.billinfobox .billinfobox-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  margin-bottom: 0.4rem;
}

.billinfobox .billinfobox-top img {
  width: 0.64rem;
  height: 0.64rem;
}

.billinfobox .billinfobox-top h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.billinfobox .billinfobox-title {
  text-align: center;
  border-bottom: 0.026667rem solid #EEF1F5;
  padding-bottom: 0.8rem;
}

.billinfobox .billinfobox-title p:first-child {
  color: #333333;
  font-size: 0.8rem;
  margin-bottom: 0.266667rem;
  font-weight: bold;
}

.billinfobox .billinfobox-title p:last-child {
  color: #999999;
  font-size: 0.373333rem;
}

.billinfobox .billinfobox-text {
  padding-left: 0.8rem;
  padding-right: 0.4rem;
  border-bottom: 0.026667rem solid #EEF1F5;
}

.billinfobox .billinfobox-text div {
  display: flex;
  flex-wrap: nowrap;
  min-height: 0.8rem;
  line-height: 0.8rem;
  justify-content: space-between;
  margin-bottom: 0.133333rem;
}

.billinfobox .billinfobox-text div p:first-child {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #999999;
}

.billinfobox .billinfobox-text div p:last-child {
  font-size: 0.373333rem;
  color: #333333;
}

.billinfobox .billinfobox-text .billinfobox-text-img {
  position: relative;
  height: 2rem;
}

.billinfobox .billinfobox-text .billinfobox-text-img img {
  width: 2rem;
  height: 100%;
  border: 0.013333rem dashed gray;
  margin-right: 0.266667rem;
}

.billinfobox .billinfobox-text .billinfobox-text-img .billinfobox-text-img-p {
  height: 100%;
  line-height: 2rem;
}

.billinfobox .billinfobox-doubt {
  height: 1.173333rem;
  padding-left: 0.8rem;
  font-size: 0.373333rem;
  padding-right: 0.4rem;
  border-bottom: 0.026667rem solid #EEF1F5;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}