.base-search-container {
  width: 7.466667rem;
  height: 0.853333rem;
  border-radius: 0.4rem;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999999;
  padding: 0 0.533333rem;
}

.base-search-container i {
  font-size: 0.533333rem;
  color: #999999;
}

.base-search-container input {
  font-size: 0.32rem !important;
  font-weight: normal;
  border: none;
  color: #999999;
  background: #FFFFFF;
  width: 5.333333rem;
}