.row-bottom-button {
  width: 100%;
  height: 1.333333rem;
  position: absolute;
  bottom: 0;
  background-color: #C0C0C0;
  border-radius: 0.133333rem;
  box-shadow: 0 0 0 0.026667rem #e1e1e1;
}

.row-bottom-button > div {
  width: 50%;
  height: 100%;
  text-align: center;
  color: white;
  line-height: 1.333333rem;
  font-size: 0.373333rem;
}

.row-bottom-button > div:nth-child(2) {
  background-color: #666666;
}

.col-bottom-button {
  width: 93%;
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #1F87FF;
  border-radius: 0.133333rem;
  box-shadow: 0 0 0 0.026667rem #e1e1e1;
  flex-direction: column-reverse !important;
  color: white;
}

.col-bottom-button > div {
  height: 1.333333rem;
  width: 100%;
  text-align: center;
  line-height: 1.333333rem;
  font-size: 0.373333rem;
}

.col-bottom-button > div:nth-child(2) {
  background-color: #FFFFFF;
  color: #F34848;
}