.pay-doctor-card {
  width: 9.306667rem;
  height: 2.773333rem;
  margin: 0.266667rem auto;
  border-radius: 0.4rem;
  background: #FFFFFF;
  padding: 0.48rem 0.426667rem 0 0.426667rem;
  color: #656565;
}

.pay-doctor-card > img {
  width: 1.066667rem;
  height: 1.066667rem;
}

.pay-doctor-card .pay-doctor-card-detail {
  width: 100%;
  padding-left: 0.266667rem;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-name {
  width: 100%;
  height: 0.533333rem;
  display: flex;
  align-items: center;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-name p {
  flex: 0 1 1.2rem;
  color: #0B1523;
  font-weight: bold;
  font-size: 0.4rem;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-name .pay-doctor-tag {
  flex: 1 1 auto;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-name span {
  flex: 0 1 0.8rem;
  color: #1F87FF;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-belong {
  line-height: 0.666667rem;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-data {
  color: #C2C5CB;
  scale: 0.91;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-data p {
  color: #1F87FF;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-data i {
  font-size: 0.32rem;
  color: #FFB740;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-brief {
  margin-top: 0.186667rem;
  width: 6.666667rem;
  line-height: 0.48rem;
  color: #7D7E81;
}

.pay-doctor-card .pay-doctor-card-detail .pay-doctor-brief a {
  color: #333333;
}

.pay-choose {
  width: 9.306667rem;
  height: auto;
  margin: 0.266667rem auto;
  border-radius: 0.4rem;
  background: #FFFFFF;
  line-height: 1.226667rem;
}

.pay-choose i {
  margin-left: 0.133333rem;
  font-size: 0.4rem;
}

.pay-choose span {
  color: #656565;
  font-size: 0.32rem;
}

.pay-choose > div:nth-child(odd) {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.4rem;
}

.pay-tips {
  padding: 0.133333rem 0.4rem;
}

.pay-tips p {
  line-height: 0.746667rem;
}

.pay-tips span {
  line-height: 0.453333rem;
}