.loading {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading .desc {
  line-height: 0.533333rem;
  font-size: 0.32rem;
  font-weight: 300;
  color: #666;
  padding-left: 0.213333rem;
}