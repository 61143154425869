.invoiceDetails-alltop {
  width: 100vw;
  height: 2.84rem;
  background-color: #1F87FF;
}

.invoiceDetails-alltop .invoiceDetails-top {
  margin-bottom: 0.133333rem;
}

.invoiceDetails-alltop .invoiceDetails-top img {
  width: 0.333333rem;
  height: 0.466667rem;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}

.invoiceDetails-alltop .already-invoice {
  width: 100vw;
  text-align: center;
  color: #fff;
  padding-bottom: 0.266667rem;
}

.invoiceDetails-alltop .already-invoice > p:first-child {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #FFFFFF;
}

.invoiceDetails-alltop .already-invoice > p:last-child {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #FFFFFF;
}

.invoice-title {
  text-align: center;
  margin-top: 0.4rem;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.4rem;
  color: #414141;
  margin-bottom: 0.4rem;
}

.invoice-between {
  line-height: 0.666667rem;
  margin-left: 0.4rem;
}

.invoice-between > span:first-child {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 0.666667rem;
}

.invoice-between > span:last-child {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.mendian-name {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #414141;
  margin-top: 0.666667rem;
  margin-left: 0.4rem;
  margin-bottom: 0.266667rem;
}

.order-details {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
  margin-bottom: 0.266667rem;
}

.order-details .order-details-img {
  width: 73vw;
  height: 1.6rem;
  overflow: hidden;
}

.order-details .order-details-img > img {
  height: 1.6rem;
  width: auto;
  margin-right: 0.133333rem;
}

.order-details .order-details-price {
  padding: 0.266667rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.look-invoice {
  height: 1.066667rem;
  margin: 0 0.4rem;
  margin-top: 0.666667rem;
  line-height: 1.066667rem;
  background-color: #1F87FF;
  text-align: center;
  color: #fff;
  font-size: 0.426667rem;
}

.invoice-outer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
}

.invoice-outer .close {
  width: 0.533333rem;
  margin-left: 8.933333rem;
  margin-top: 0.4rem;
}

.invoice-outer .pic {
  width: 8rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.invoice-outer .pdf-top {
  margin-top: 0.666667rem;
  height: auto !important;
  width: 100vw !important;
}

.invoice-outer .ele-outer .invoice-outer .am-flexbox {
  position: absolute;
  top: 85vh;
  text-align: center !important;
}