.header2 {
  width: 100%;
  padding-bottom: 2rem;
}

.header2 .drawback-header {
  width: 10rem;
  height: 1.706667rem;
  background-color: #fff;
  padding: 0.666667rem 0.4rem;
  display: flex;
  justify-content: space-between;
}

.header2 .drawback-header .drawback-header-left > span {
  font-size: 0.426667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  margin-left: 0.133333rem;
}

.header2 .drawback-header .drawback-header-right {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
}

.header2 .shoufei {
  width: 10rem;
  height: 1.28rem;
  background: #FFEBDB;
  padding-top: 0.133333rem;
}

.header2 .shoufei > p {
  padding-left: 0.4rem;
  font-family: MicrosoftYaHei;
  color: #353A42;
  line-height: 0.533333rem;
}

.header2 .nowaddress {
  width: 10rem;
  background: #FFFFFF;
  border-radius: 0.266667rem;
  margin-top: 0.4rem;
  padding: 0.4rem;
}

.header2 .nowaddress > h3 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .nowaddress .address {
  font-size: 0.4rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .nowaddress .name-phone > span {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .nowaddress .name-phone .phone {
  margin-left: 0.266667rem;
}

.header2 .no-more-address {
  width: 10rem;
  height: 1.973333rem;
  background: #FFFFFF;
  border-radius: 0.266667rem;
  margin-top: 0.4rem;
  padding: 0.4rem;
}

.header2 .no-more-address .xiugai {
  display: flex;
  justify-content: space-between;
}

.header2 .no-more-address .xiugai > h3 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .no-more-address .xiugai > p {
  display: flex;
  align-items: center;
}

.header2 .no-more-address .xiugai > p > img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.header2 .no-more-address .xiugai > p > span {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #666666;
}

.header2 .no-more-address .no-xiugai {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #999999;
  margin-left: -0.4rem;
}

.header2 .more-address {
  width: 10rem;
  background: #FFFFFF;
  border-radius: 0.266667rem;
  margin-top: 0.4rem;
  padding: 0.4rem;
}

.header2 .more-address .xiugai {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.133333rem;
}

.header2 .more-address .xiugai > h3 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .more-address .xiugai > p {
  display: flex;
  align-items: center;
}

.header2 .more-address .xiugai > p > img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.header2 .more-address .xiugai > p > span {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #666666;
}

.header2 .more-address .all-address1 {
  display: flex;
  align-items: center;
}

.header2 .more-address .all-address1 .all-address-phone1 {
  margin-left: 0.266667rem;
}

.header2 .more-address .all-address1 .all-address-phone1 .address1 {
  font-size: 0.4rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #aaa;
}

.header2 .more-address .all-address1 .all-address-phone1 .name-phone1 {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
}

.header2 .more-address .all-address1 .all-address-phone1 .name-phone1 .phone1 {
  margin-left: 0.266667rem;
}

.header2 .more-address .all-address {
  display: flex;
  align-items: center;
}

.header2 .more-address .all-address .all-address-phone {
  margin-left: 0.266667rem;
}

.header2 .more-address .all-address .all-address-phone .address {
  font-size: 0.4rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .more-address .all-address .all-address-phone .name-phone {
  font-size: 0.346667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #414141;
}

.header2 .more-address .all-address .all-address-phone .name-phone .phone {
  margin-left: 0.266667rem;
}

.header2 .more-address .xian {
  width: 9.44rem;
  height: 0.026667rem;
  background: #EEF1F5;
  margin: 0.133333rem 0;
}

.sure-bottom {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
  position: fixed;
  bottom: 0.666667rem;
}

.sure-bottom > img {
  width: 8.8rem;
  height: 1.133333rem;
}

.sure-bottom > span {
  position: relative;
  left: 3.6rem;
  top: -0.866667rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.del-address {
  position: absolute;
  z-index: 999;
  width: 6.666667rem;
  height: 3.466667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}