.orderDelivery-top {
  width: 100%;
  height: 10vh;
  text-align: center;
  line-height: 10vh;
  font-size: 0.453333rem;
  position: fixed;
  top: 0;
  border-bottom: 0.013333rem solid #EEF1F5;
  background-color: white;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.orderDelivery-top img {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  left: 0.266667rem;
  margin: auto;
  top: 0;
  bottom: 0;
}

.scroller {
  position: fixed;
  top: 10vh;
  bottom: 0;
  width: 100vw;
  height: 90vh;
  background-color: #fff;
  padding: 0.266667rem 4vw;
  overflow: scroll;
}

.scroller .item {
  border-bottom: 0.026667rem solid #EDEDEE;
  background-color: #fff;
  position: static;
}

.scroller .item .orderDelivery-outer {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #EDEDEE;
  margin-bottom: 0.4rem;
}

.scroller .item .orderDelivery-outer .pharmacyName {
  display: flex;
  padding: 0.133333rem 0.4rem;
}

.scroller .item .orderDelivery-outer .pharmacyName > img {
  width: 0.8rem;
  height: 0.8rem;
  background: #E8E8E8;
  border-radius: 50%;
  margin-right: 0.266667rem;
}

.scroller .item .orderDelivery-outer .pharmacyName > span {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  line-height: 0.8rem;
  font-weight: 400;
  color: #333333;
  width: 66vw;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.scroller .item .orderDelivery-outer > span {
  color: #D1D1D1;
  font-size: 0.506667rem;
  line-height: 1.2rem;
  margin-right: 0.4rem;
}

.scroller .item .orderDelivery-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  padding: 0 0.4rem;
}

.scroller .item .orderDelivery-time > p:first-child {
  font-size: 0.426667rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}

.scroller .item .orderDelivery-time > :last-child {
  font-size: 0.293333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #C2C5CB;
  margin-top: 0.133333rem;
}

.scroller .item .item-scroller2 {
  width: 92vw;
}

.scroller .item .item-scroller1 {
  width: 100%;
  display: flex;
}

.scroller .item .content {
  display: flex;
  padding: 0.133333rem 0.4rem;
  width: 100vw;
}

.scroller .item .content > div {
  width: 1.6rem;
  height: 1.6rem;
  background: #FFFFFF;
  margin-right: 0.266667rem;
  overflow: hidden;
}

.scroller .item .content > div > img {
  width: 1.6rem;
}

.scroller .item .content .orderDelivery-outer-bottom1 {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.scroller .item .content .orderDelivery-outer-bottom1 > p {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

.scroller .item .del {
  height: 100%;
  width: 16.5%;
  background: #f00;
  color: #fff;
  line-height: 1.8rem;
  float: right;
  text-align: center;
}