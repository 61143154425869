.login-input {
  height: 1.173333rem;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
}

.login-input .input-line {
  position: relative;
  display: flex;
  flex: 1 1;
  align-self: stretch;
  padding-right: 0.4rem;
  overflow: hidden;
  align-items: center;
}

.login-input .input-line ::after {
  content: '';
  position: absolute;
  background-color: #ddd;
  display: block;
  z-index: 1;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.026667rem;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.login-input .input-line .input-control {
  font-size: 0.4rem;
  flex: 1 1;
}

.login-input .input-line .input-control input {
  outline: none;
  font-size: 0.373333rem !important;
  font-weight: normal;
  border: none;
  width: 100%;
  padding: 0.053333rem 0;
  border: 0;
  background-color: transparent;
  line-height: 1;
  box-sizing: border-box;
}

.login-input .input-line .input-control input::-webkit-input-placeholder {
  color: #c3c3c3;
  font-size: 0.453333rem;
}

.login-input .input-line .input-control input:-ms-input-placeholder {
  color: #c3c3c3;
  font-size: 0.453333rem;
}

.login-input .input-line .input-control input::placeholder {
  color: #c3c3c3;
  font-size: 0.453333rem;
}