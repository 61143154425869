.list-table {
  width: 90vw;
  margin-top: 0.4rem;
  margin-bottom: 0.666667rem;
  margin-left: 5vw;
  border: 0.013333rem solid #707070;
  background-color: #fff;
}

.list-table .table-title {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 0.866667rem;
  color: #333333;
  border: 0.013333rem solid #D6D6D6;
  text-align: center;
}

.list-table .table-title-two {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.013333rem solid #D6D6D6;
}

.list-table .table-title-two > p {
  width: 30vw;
  text-align: center;
  border-right: 0.013333rem solid #D6D6D6;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 1.466667rem;
  color: #333333;
}

.list-table .table-title-two > p:last-child {
  border-right: none;
}

.list-table .table-one {
  display: flex;
  border-bottom: 0.013333rem solid #D6D6D6;
}

.list-table .table-one .table-one-left {
  width: 30vw;
  height: 3.466667rem;
  line-height: 3.466667rem;
  text-align: center;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  border-right: 0.013333rem solid #D6D6D6;
}

.list-table .table-one .table-one-right {
  width: 60vw;
}

.list-table .table-one .table-one-right .table-one-right-top {
  border-bottom: 0.013333rem solid #D6D6D6;
  display: flex;
  height: 1.733333rem;
}

.list-table .table-one .table-one-right .table-one-right-top > p {
  width: 50%;
  padding: 0.266667rem;
  text-align: center;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.list-table .table-one .table-one-right .table-one-right-top > p:first-child {
  border-right: 0.013333rem solid #D6D6D6;
}

.list-table .table-one .table-one-right > p:last-child {
  border-bottom: none;
}

.list-table .table-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.013333rem solid #D6D6D6;
}

.list-table .table-list > p {
  width: 30vw;
  text-align: center;
  border-right: 0.013333rem solid #D6D6D6;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  color: #333333;
  padding: 0.266667rem;
}

.list-table .table-list > p:first-child {
  height: 3.6rem;
  text-align: center;
  padding-top: 1.333333rem;
}

.list-table .table-list > p:last-child {
  border-right: none;
}

.list-table .table-list1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.013333rem solid #D6D6D6;
}

.list-table .table-list1 > p {
  width: 30vw;
  text-align: center;
  border-right: 0.013333rem solid #D6D6D6;
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  color: #333333;
  padding: 0.266667rem;
}

.list-table .table-list1 > p:first-child {
  height: 8.266667rem;
  text-align: center;
  padding-top: 4.266667rem;
}

.list-table .table-list1 > p:last-child {
  border-right: none;
}

.list-table .table-list1 > p:last-child > p {
  font-size: 0.373333rem;
  font-family: Source Han Sans CN;
  color: #333333;
}