.drag-card {
  display: flex;
  font-size: 0.533333rem;
  align-items: center;
  width: 100%;
  padding: 0.266667rem;
  background-color: white;
  box-shadow: grey 0.026667rem 0.053333rem 0.08rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.drag-card img {
  width: 2.666667rem;
  margin-right: 0.533333rem;
}

.draggable {
  padding: 0.266667rem 0;
}