.pbox {
  height: 720pt;
  width: 400pt;
  margin: 0 auto;
  margin-top: 10pt;
  border: 0.5pt solid black;
}

.pbox .box-top {
  height: 80pt;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0.5pt solid black;
}

.pbox .box-top .box-top-left {
  width: 40%;
  height: 100%;
  border-right: 0.5pt solid black;
}

.pbox .box-top .box-top-left .box-top-left-top {
  height: 40pt;
  line-height: 40pt;
  padding-left: 12pt;
  padding-top: 4pt;
}

.pbox .box-top .box-top-left .box-top-left-top img {
  height: 32pt;
}

.pbox .box-top .box-top-left .box-top-left-bottom {
  width: 100%;
  height: 40pt;
  padding-left: 12pt;
}

.pbox .box-top .box-top-left .box-top-left-bottom p {
  line-height: 20pt;
}

.pbox .box-top .box-top-left .box-top-left-bottom p:first-child {
  height: 12pt;
  width: 100%;
  line-height: 12pt;
}

.pbox .box-top .box-top-right {
  width: 59%;
  height: 100%;
  display: flex;
  padding-top: 15pt;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.pbox .box-top .box-top-right div {
  height: 50pt;
  width: 22%;
}

.pbox .box-top .box-top-right div img {
  width: 40pt;
  height: 40pt;
}

.pbox .box-top .box-top-right div:first {
  width: 33%;
}

.pbox .box-top .box-top-right .box-top-right-text {
  background-color: black;
  color: white;
  text-align: center;
}

.pbox .box-ma1 {
  width: 100%;
  height: 68pt;
  text-align: center;
  padding-top: 20pt;
  border-bottom: 0.5pt solid black;
}

.pbox .box-ma1 .box-ma1-top {
  width: 100%;
  height: 40%;
  margin-bottom: 10pt;
}

.pbox .box-num {
  width: 100%;
  height: 40pt;
  font-size: 30pt;
  font-weight: 700;
  text-align: center;
  line-height: 40pt;
  border-bottom: 0.5pt solid black;
}

.pbox .box-shou {
  width: 100%;
  height: 64pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-shou .box-shou-left {
  width: 15%;
  height: 100%;
  line-height: 64pt;
  text-align: center;
}

.pbox .box-shou .box-shou-right {
  padding: 8pt;
  padding-left: 0;
}

.pbox .box-ji {
  width: 100%;
  height: 56pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-ji .box-ji-left {
  width: 15%;
  height: 100%;
  line-height: 56pt;
  text-align: center;
}

.pbox .box-ji .box-ji-right {
  padding: 7pt;
  padding-left: 0;
}

.pbox .box-name {
  width: 100%;
  height: 52pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-name .box-name-left {
  width: 15%;
  text-align: center;
  line-height: 52pt;
  border-right: 0.5pt solid black;
}

.pbox .box-name .box-name-right {
  width: 85%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pbox .box-name .box-name-right p {
  width: 48%;
  line-height: 20pt;
}

.pbox .box-username {
  width: 100%;
  height: 80pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-username .box-username-left {
  width: 40%;
  height: 100%;
  line-height: 80pt;
  text-align: center;
  font-weight: 700;
  font-size: 30pt;
  border-right: 0.5pt solid black;
}

.pbox .box-username .box-username-right {
  padding: 15pt;
  position: relative;
}

.pbox .box-username .box-username-right div {
  height: 50pt;
  line-height: 20pt;
}

.pbox .box-ma2 {
  width: 100%;
  height: 56pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-ma2 .box-ma2-left {
  width: 40%;
  height: 100%;
  text-align: center;
  padding-top: 8pt;
}

.pbox .box-ma2 .box-ma2-left img {
  height: 70%;
}

.pbox .box-ma2 .box-ma2-right {
  width: 60%;
  height: 100%;
}

.pbox .box-ma2 .box-ma2-right div:first-child {
  padding-top: 10pt;
  text-align: center;
  height: 35pt;
  width: 100%;
  margin-bottom: 2pt;
}

.pbox .box-ma2 .box-ma2-right div:last-child {
  text-align: center;
}

.pbox .box-text {
  width: 100%;
  height: 56pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-text .box-text-left {
  width: 15%;
  height: 100%;
  line-height: 56pt;
  text-align: center;
}

.pbox .box-text .box-text-right {
  padding: 7pt;
  padding-left: 0;
}

.pbox .box-name2 {
  width: 100%;
  height: 96pt;
  border-bottom: 0.5pt solid black;
  display: flex;
  flex-wrap: nowrap;
}

.pbox .box-name2 .box-name2-one {
  width: 15%;
  text-align: center;
  border-right: 0.5pt solid black;
  padding: 5pt;
}

.pbox .box-name2 .box-name2-two {
  width: 30%;
  padding: 5pt;
  padding-left: 10pt;
  border-right: 0.5pt solid black;
  line-height: 15pt;
}

.pbox .box-name2 .box-name2-three {
  width: 55%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.pbox .box-name2 .box-name2-three img {
  height: 96%;
  margin-right: 10pt;
}

.pbox .box-name2 .box-name2-three div {
  line-height: 20pt;
}

.pbox .box-bottom {
  width: 100%;
  height: 16pt;
  padding-left: 10pt;
  line-height: 16pt;
}