#common-header {
  background-color: #fff !important;
}

.my-order .am-tabs-default-bar-tab-active {
  font-weight: bold;
}

.colectitem {
  width: 100%;
  height: 2.933333rem;
  background-color: #FFFFFF;
  padding: 0.32rem 0.4rem;
  display: flex;
  flex-direction: row;
}

.colectitem .colectitem-img > img {
  width: 2.266667rem;
}

.colectitem .colectitem-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.32rem;
  width: 100%;
  margin-left: 0.533333rem;
}

.colectitem .colectitem-text > div > h1 {
  font-size: 0.346667rem;
}

.colectitem .colectitem-text > div > span {
  font-size: 0.24rem;
}

.colectitem .colectitem-text > span {
  font-size: 0.346667rem;
}

.my-order-item {
  width: 100%;
  height: 85vh;
  overflow: scroll;
  background-color: #EEF1F5;
}

.itm {
  width: 100%;
  height: 0.8rem;
  background-color: blue;
}

.am-list .am-list-header {
  margin-top: 0 !important;
}

.popup-list {
  margin-top: 0.8rem;
}

.popup-list .am-list-header > div {
  font-size: 0.4rem;
}

.popup-list .am-list-item .am-list-line .am-list-content {
  font-size: 0.4rem !important;
}

.popup-list .am-button span {
  font-size: 0.426667rem;
}

.am-modal-body {
  margin-bottom: 0.533333rem !important;
}

.am-tabs-default-bar-top .am-tabs-default-bar-underline,
.am-tabs-default-bar-bottom .am-tabs-default-bar-underline {
  border: 0.08rem solid #ff5555 !important;
}