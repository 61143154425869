.setbox1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.setbox1 .box-top1 {
  width: 100%;
  height: 1.613333rem;
  text-align: center;
  line-height: 1.613333rem;
  font-size: 0.426667rem;
  position: relative;
  border-bottom: 0.026667rem solid #EEF1F5;
  background-color: white;
}

.setbox1 .box-top1 img {
  position: absolute;
  width: 0.533333rem;
  height: 0.533333rem;
  left: 0.266667rem;
  margin: auto;
  top: 0;
  bottom: 0;
}

.setbox1 .qual-cont {
  width: 96.3%;
  margin: 0 auto;
  margin-top: 1.68rem;
  margin-bottom: 0.213333rem;
}

.setbox1 .qual-cont .qual-info {
  display: flex;
  flex-direction: column;
  padding: 0.16rem;
  grid-gap: 0.106667rem;
  gap: 0.106667rem;
  background: #FFFFFF;
  border: 0.013333rem solid rgba(22, 129, 244, 0.5);
  border-radius: 0.08rem;
  margin-top: 0.133333rem;
  font-family: "苹方-简";
}

.setbox1 .qual-cont .qual-info p {
  font-size: 0.32rem;
  font-weight: 600;
  line-height: 0.32rem;
  letter-spacing: 0;
  color: #141419;
}

.setbox1 .qual-cont .qual-info span {
  font-size: 0.266667rem;
  line-height: 0.266667rem;
  letter-spacing: 0;
  color: #888889;
}

.setbox1 .qual-cont .qual-info img {
  height: 4rem;
}