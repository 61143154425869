.recommend-med-area {
  width: 100%;
  padding: 0 0.266667rem;
  padding-bottom: 0.666667rem;
  flex-wrap: wrap;
  justify-content: space-around;
}

.recommend-med-area:after {
  content: '';
  width: 4.373333rem;
  border: 0.013333rem solid transparent;
}

.recommend-med-area .recommend-meditem {
  width: 4.533333rem;
  height: 6.666667rem !important;
  background-color: #FFFFFF;
  margin-bottom: 0.213333rem;
  border-radius: 0.133333rem;
  position: relative;
}

.recommend-med-area .recommend-meditem .recommend-meditem-img {
  position: absolute;
  right: 0.266667rem;
  bottom: 0.266667rem;
  width: 0.666667rem;
}

.recommend-med-area .recommend-meditem .recommend-img {
  width: 100%;
  height: 4.533333rem;
  border-radius: 0.133333rem;
  overflow: hidden;
}

.recommend-med-area .recommend-meditem .recommend-img > img {
  margin-top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  overflow: hidden;
  zoom: 1;
  background-repeat: no-repeat;
  ckground-size: cover;
  -Webkit-background-size: cover;
  -o-bacackground-size: cover;
  background-position: center 0;
}

.recommend-med-area .recommend-meditem .recommend-img .recommend-img1 {
  position: absolute;
  left: 0;
}

.recommend-med-area .recommend-meditem .recommend-img .recommend-img2 {
  background-color: #fff;
}

.recommend-med-area .recommend-meditem .recommend-info {
  padding: 0.266667rem 0.133333rem;
}

.recommend-med-area .recommend-meditem .recommend-info .yaopin-name {
  min-height: 0.4rem;
}

.recommend-med-area .recommend-meditem .recommend-info .yaopin-name > div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.recommend-med-area .recommend-meditem .recommend-info .yaopin-name > div > span {
  font-weight: bold;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.recommend-med-area .recommend-meditem .recommend-info .yaopin-name > div .chufangyao {
  height: 0.533333rem;
  line-height: 0.533333rem;
  min-width: 1.133333rem;
  font-size: 0.266667rem;
  margin-right: 0.133333rem;
  padding: 0 0.133333rem 0 0.133333rem;
  background-color: #1F87FF;
  color: #FFFFFF;
}

.recommend-med-area .recommend-meditem .recommend-info > p {
  margin-bottom: 0.266667rem;
  width: 100%;
  height: 0.666667rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #354242;
  font-size: 0.373333rem;
}

.recommend-med-area .recommend-meditem .recommend-info > span {
  color: #98A0AD;
  font-size: 0.293333rem;
  flex: 1 1;
}

.recommend-med-area .recommend-meditem .recommend-info .recommend-meditem-row {
  justify-content: start;
  margin-top: 0.666667rem;
}

.recommend-med-area .recommend-meditem .recommend-info .recommend-meditem-row .recommend-price {
  color: #FC4343;
  font-size: 0.56rem;
  position: absolute;
  bottom: 0.266667rem;
  left: 0.133333rem;
}

.recommend-med-area .recommend-meditem .recommend-info .recommend-meditem-row .med-viplevel {
  margin-left: 0.133333rem;
}

.recommend-med-area .recommend-meditem .recommend-info .recommend-meditem-row .recommend-spike {
  margin-left: 0.093333rem;
  margin-top: 0.093333rem;
  color: #9A9A9A;
  font-size: 0.293333rem;
  text-decoration: line-through;
}

.recommend-med-area .recommend-meditem .recommend-info .recommend-meditem-row > img {
  width: 0.666667rem;
  position: absolute;
  margin-left: 3.466667rem;
}