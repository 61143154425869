.swiper {
  position: relative;
  height: 2.506667rem;
  width: 9.333333rem;
  border-radius: 1.253333rem;
  overflow: hidden;
}

.swiper-view {
  width: 9.333333rem;
  overflow: hidden;
  height: 2.506667rem;
}

.swiper-view img {
  width: 9.333333rem;
  overflow: hidden;
  height: 2.506667rem;
}