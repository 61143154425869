.med-table {
  width: 100%;
  border-collapse: collapse;
  border-color: #eee;
  margin-bottom: 0.4rem;
  color: #353A42;
}

.med-table * {
  font-size: 0.32rem;
}

.med-table tbody,
.med-table td,
.med-table th {
  border-color: #eee;
}

.med-table td,
.med-table th {
  height: 0.96rem;
  padding: 0.266667rem 0.4rem;
  line-height: 0.453333rem;
}

.med-table tr {
  display: table-row;
}

.med-table tr > td:first-child {
  width: 30%;
}

.med-table th {
  font-weight: bold;
  color: #353A42;
  text-align: left;
}

.med-table-collapse {
  text-align: center;
  color: #353A42;
  display: flex;
  justify-content: center;
}

.med-table-collapse * {
  font-size: 0.346667rem;
}

.med-table-collapse i {
  font-size: 0.266667rem;
  height: 0.266667rem;
}