.zedittop {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.zedittop img {
  width: 0.64rem;
  height: 0.64rem;
}

.zedittop h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.zeditbox {
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 0.4rem;
  height: calc(100vh - 1.706667rem);
}

.zeditbox .zeditbox-text {
  width: 100%;
}

.zeditbox .zeditbox-text h2 {
  line-height: 1.066667rem;
  padding-left: 0.333333rem;
  font-size: 0.373333rem;
}

.zeditbox .zeditbox-text .zeditbox-text-item {
  width: 100%;
  height: 1.066667rem;
  line-height: 1.066667rem;
  background-color: #F1F1F1;
  color: #999999;
  font-size: 0.373333rem;
  border: none;
  padding-left: 0.333333rem;
}

.moren {
  display: flex;
  margin-top: 0.4rem;
  width: 100%;
  height: 1.066667rem;
  line-height: 1.066667rem;
  margin-bottom: 0.133333rem;
}

.zedit-btn {
  width: 100%;
  height: 1.12rem;
  border: none;
  border-radius: 0.56rem;
  background-color: #FF5555;
  color: #FFFFFF;
  font-size: 0.426667rem;
  margin-bottom: 0.4rem;
}