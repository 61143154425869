.base-card {
  width: 100%;
  background-color: #FFFFFF;
  padding: 0.4rem;
  border-radius: 0.4rem;
  margin-bottom: 0.266667rem;
  display: flex;
  flex-direction: column;
  height: auto;
}

.base-card span {
  color: #666666;
}

.base-card > div {
  width: 100%;
}

.base-card-bottom {
  width: 100%;
  background-color: #FFFFFF;
  padding: 0.4rem;
  border-radius: 0.4rem 0.4rem 0 0;
  display: flex;
  flex-direction: column;
}

.base-card-bottom span {
  color: #666666;
}

.base-card-bottom > div {
  width: 100%;
}

.pure-word {
  padding: 0.4rem 0.4rem 0.133333rem 0.4rem;
}

.pure-word span {
  margin-bottom: 0.266667rem;
  color: #666666;
}