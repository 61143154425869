.am-tabs-default-bar-underline {
  border: 0.026667rem #00B7F5 solid;
}

.limit-kill-nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.limit-kill-nodata .no-data {
  padding-top: 0.533333rem;
  width: 5.626667rem;
  font-size: 0.373333rem;
}