.recordbox1 .box-top1 {
  width: 100%;
  position: fixed;
  height: 1.706667rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-bottom: 0.133333rem;
  z-index: 99;
}

.recordbox1 .box-top1 img {
  width: 0.64rem;
  height: 0.64rem;
}

.recordbox1 .box-top1 h1 {
  width: 9.333333rem;
  text-align: center;
  font-size: 0.48rem;
  padding-right: 0.266667rem;
}

.recordbox1 .box-content1 {
  position: absolute;
  top: 1.733333rem;
  bottom: 1.333333rem;
  margin-top: 0.266667rem;
  overflow: scroll;
}

.recordbox1 .box-content1 .patient-outer {
  border: 0.013333rem solid #aaa;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  margin-bottom: 0.266667rem;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
}

.recordbox1 .box-content1 .patient-outer .patient-outer-left {
  width: 70%;
}

.recordbox1 .box-content1 .patient-outer .patient-outer-left .name {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.recordbox1 .box-content1 .patient-outer .patient-outer-left > p {
  height: 0.666667rem;
  line-height: 0.666667rem;
}

.recordbox1 .box-content1 .patient-outer .patient-outer-right {
  width: 20%;
  display: flex;
  justify-content: space-between;
  margin-left: 0.8rem;
}

.recordbox1 .box-content1 .patient-outer .patient-outer-right > img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-top: 0.4rem;
}

.recordbox1 .xinzeng {
  position: fixed;
  z-index: 100;
  bottom: 0.4rem;
  width: 90vw;
  text-align: center;
  background-color: #0076FF;
  height: 0.933333rem;
  line-height: 0.933333rem;
  border-radius: 0.8rem;
  color: #fff;
  margin-left: 5vw;
  font-size: 0.373333rem;
}

.del-address {
  position: absolute;
  z-index: 999;
  width: 6.666667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}