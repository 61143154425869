.tickettop {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.tickettop img {
  width: 0.64rem;
  height: 0.64rem;
}

.tickettop h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.tickettext {
  display: flex;
  flex-wrap: nowrap;
  height: 2.293333rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.4rem;
}

.tickettext div {
  width: 85%;
  line-height: 0.8rem;
}

.tickettext div span {
  font-size: 0.24rem;
  display: inline-block;
  width: 0.8rem;
  height: 0.36rem;
  line-height: 0.36rem;
  background-color: #FF5555;
  color: #FFFFFF;
  text-align: center;
}

.tickettext span {
  font-size: 0.373333rem;
  color: #FF5555;
}