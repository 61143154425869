.outer2 {
  width: 10rem;
  background: #F0F2F5;
  padding-bottom: 1.066667rem;
}

.outer2 > header {
  width: 10rem;
  height: 3.013333rem;
  background: #FF5555;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.466667rem 0;
}

.outer2 > header > h2 {
  font-size: 0.506667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}

.outer2 > header .step {
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
}

.outer2 > header .step > span {
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.6;
}

.outer2 > header .step .cg-news {
  opacity: 1;
}

.outer2 > header .step .xian {
  width: 0.8rem;
  height: 0;
  border: 0.026667rem solid #FFFFFF;
  opacity: 0.6;
  margin: 0 0.133333rem;
  margin-top: 0.2rem;
}

.outer2 .await-zz {
  width: 10rem;
  height: 2.36rem;
  background: #FFFFFF;
  padding-top: 0.533333rem;
  margin-bottom: 0.266667rem;
}

.outer2 .await-zz > h2 {
  font-size: 0.426667rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
  text-align: center;
  margin-bottom: 0.32rem;
}

.outer2 .await-zz .qualifications-await {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  text-align: center;
}

.outer2 .await-zz1 {
  width: 10rem;
  height: 2.053333rem;
  background: #FFF2E8;
  padding: 0.266667rem 0.4rem;
}

.outer2 .await-zz1 > h3 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF5555;
  margin-bottom: 0.133333rem;
}

.outer2 .await-zz1 .qualifications-await1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.48rem;
  color: #414141;
}

.outer2 .jc-news {
  height: 4.24rem;
  background: #FFFFFF;
  padding: 0.4rem 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
}

.outer2 .jc-news .jc-news-title {
  font-size: 0.373333rem;
}

.outer2 .jc-news .jc-news-title > span {
  font-weight: 900;
  color: #FF5555;
  border-radius: 0.08rem;
  margin-right: 0.4rem;
}

.outer2 .jc-news .cgs-name,
.outer2 .jc-news .fz-person {
  margin-left: 0.48rem;
  line-height: 0.8rem;
}

.outer2 .jc-news .address,
.outer2 .jc-news .phone {
  margin-left: 0.8rem;
  line-height: 0.8rem;
}

.outer2 .zz-news {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding-top: 0.266667rem;
}

.outer2 .zz-news .zz-news-title {
  font-size: 0.373333rem;
}

.outer2 .zz-news .zz-news-title > span {
  font-weight: 900;
  color: #FF5555;
  border-radius: 0.08rem;
  margin-right: 0.4rem;
}

.outer2 .zz-news .zz-pic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.666667rem 1.333333rem;
}

.outer2 .zz-news .zz-pic .pic {
  width: 2.666667rem;
  height: 3.733333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.outer2 .zz-news .zz-pic .pic > img {
  width: 2.666667rem;
  height: 2.666667rem;
}

.outer2 .zz-news .zz-pic .pic .pic-word {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  text-align: center;
  margin-top: 0.133333rem;
}

.outer2 .zz-news .zz-pic .pic .pic-word > span {
  font-size: 0.373333rem;
  color: #FF5555;
  margin-right: 0.133333rem;
}

.outer2 .reason {
  background-color: #fff;
  margin-top: 0.266667rem;
  padding: 0.266667rem 0.4rem;
}

.outer2 .reason > h2 {
  line-height: 0.533333rem;
  color: #FF5555;
  font-size: 0.266667rem;
}

.outer2 .reason > p {
  color: #414141;
  line-height: 0.6rem;
}

.outer2 .kefu {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  margin-bottom: 0.266667rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
}

.outer2 .kefu-phone {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-left: 0.4rem;
}

.outer2 .next {
  margin-top: 1.333333rem;
  height: 1.133333rem;
}

.outer2 .next > img {
  width: 8.8rem;
  height: 1.133333rem;
  margin-left: 0.6rem;
}

.outer2 .next > span {
  position: absolute;
  left: 3.733333rem;
  margin-top: 0.333333rem;
  font-size: 0.426667rem;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}

.outer2 .outer2-btn {
  margin-top: 0.266667rem;
  padding-left: 0.4rem;
}

.outer2 .outer2-btn button {
  height: 0.6rem;
  background-color: #FF5555;
  color: white;
  border: none;
  border-radius: 0.066667rem;
  margin-right: 0.4rem;
  padding-left: 0.266667rem;
  padding-right: 0.266667rem;
}