.ZHtop {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.ZHtop img {
  width: 0.64rem;
  height: 0.64rem;
}

.ZHtop h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
}

.ZHtop-text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 1.293333rem;
  background-color: #FFFFFF;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 0.373333rem;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.ZHtop-text img {
  width: 0.293333rem;
  height: 0.293333rem;
}

.ZHtop-text .ZHtop-text-one {
  width: 80%;
  padding-left: 0.266667rem;
}

.ZHtop-text > span:last-child {
  margin-left: 0.266667rem;
}

.ZHtop-text .tishi {
  color: #FF5555;
}

.ZHtop-text .tishi2 {
  color: #8bee99;
}

.ZHtop-text .tishi3 {
  color: bisque;
}