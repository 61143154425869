.inquiry-doctor-groudimg {
  width: 100%;
  height: 6.346667rem;
  background: #1f87ff;
  border-radius: 0 0 0.533333rem 0.533333rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.inquiry-doctor-back {
  width: 100%;
  height: 1.653333rem;
  position: fixed;
  display: flex;
  align-items: center;
  padding-left: 0.24rem;
  color: #FFFFFF;
}

.inquiry-doctor-content {
  position: fixed;
  top: 1.653333rem;
  bottom: 1.306667rem;
  left: 0;
  right: 0;
  align-items: center;
  color: #FFFFFF;
  z-index: 1;
}

.inquiry-doctor-content .inquiry-doctor-brief {
  padding: 0 0.506667rem 0 0.4rem;
  width: 100%;
  display: flex;
}

.inquiry-doctor-content .inquiry-doctor-brief p {
  color: #FFFFFF;
  font-size: 0.4rem;
  font-weight: bold;
}

.inquiry-doctor-content .inquiry-doctor-brief .item1 {
  flex: 0 0 1.333333rem;
}

.inquiry-doctor-content .inquiry-doctor-brief .item2 {
  flex: 1 1 auto;
  margin-left: 0.4rem;
}

.inquiry-doctor-content .inquiry-doctor-brief .item2 > div:nth-child(1) {
  height: 0.533333rem;
}

.inquiry-doctor-content .inquiry-doctor-brief .item3 {
  flex: 0 0 1.44rem;
}

.inquiry-doctor-content .inquiry-doctor-brief img {
  width: 1.333333rem;
  height: 1.333333rem;
}

.inquiry-doctor-content .inquiry-doctor-data {
  width: 8rem;
  height: 1.413333rem;
  margin-top: 0.373333rem;
  background: rgba(32, 52, 96, 0.1);
}

.inquiry-doctor-content .inquiry-doctor-data span {
  line-height: 0.506667rem;
  font-size: 0.4rem;
}

.inquiry-doctor-content .inquiry-doctor-data div {
  margin: 0 0.96rem;
}

.inquiry-doctor-content .inquiry-doctor-data div :last-child {
  font-size: 0.32rem;
}

.inquiry-doctor-content .inquiry-color-block {
  width: 0.426667rem;
  height: 0.426667rem;
  background: #C5E0FF;
  margin-right: 0.213333rem;
}

.inquiry-doctor-content .inquiry-doctor-card {
  width: 8.933333rem;
  margin-top: 0.533333rem;
  background: #FFFFFF;
  border-radius: 0.08rem;
}

.inquiry-doctor-content .inquiry-doctor-card > div:nth-child(1) {
  height: 0.96rem;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #0B1523;
}

.inquiry-doctor-content .inquiry-doctor-card > div:nth-child(2) {
  width: 100%;
  padding: 0 0.24rem 0.4rem 0.426667rem;
  flex-wrap: wrap;
  color: #353A42;
  font-size: 0.32rem;
  line-height: 0.48rem;
}

.inquiry-doctor-content .inquiry-doctor-way {
  width: 8.933333rem;
  margin-top: 0.4rem;
  background: #FFFFFF;
  border-radius: 0.08rem;
}

.inquiry-doctor-content .inquiry-doctor-way > div:nth-child(1) {
  height: 0.96rem;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #0B1523;
}

.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area {
  width: 100%;
  margin: 0.4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item1,
.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item3 {
  width: 8rem;
}

.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item1 span,
.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item3 span {
  margin-top: 0.266667rem;
  color: #1F87FF;
}

.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item1 img,
.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item3 img {
  width: 0.8rem;
  height: 0.8rem;
}

.inquiry-doctor-content .inquiry-doctor-way .inquiry-choose-area .inquiry-item2 {
  width: 0;
  height: 0.906667rem;
  border: 0.013333rem solid #1f87ff;
}

.pop-close {
  width: 100%;
  height: 0.533333rem;
  margin-top: 0.32rem;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 0.506667rem;
}

.pop-title {
  text-align: center;
  font-size: 0.373333rem;
  color: #414141;
  margin-bottom: 0.533333rem;
}

.pop-tab {
  width: 8.533333rem;
  height: 1.973333rem;
  border-bottom: solid 0.013333rem #D6D9DE;
  padding-left: 0.72rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.pop-tab img {
  width: 1.066667rem;
  height: auto;
}

.pop-tab span {
  color: #414141;
  font-weight: bold;
  margin-left: 0.266667rem;
  font-size: 0.373333rem;
}

.pop-tab i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}