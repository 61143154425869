.introduce-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.introduce-page .introduce-pageitem {
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  align-items: center;
}

.introduce-page .introduce-pageitem img {
  width: 60%;
  height: auto;
  margin-top: 30%;
  margin-bottom: 20%;
}

.introduce-page .introduce-pageitem p {
  font-size: 0.8rem;
  color: #1f87ff;
  margin-bottom: 0.266667rem;
}

.introduce-page .introduce-pageitem span {
  font-size: 0.533333rem;
  color: #777777;
  line-height: 0.8rem;
}

.introduce-page .introduce-page-in {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 2.613333rem;
  height: 0.773333rem;
  background: #1f87ff;
  border-radius: 0.4rem;
  color: #FFFFFF;
  font-size: 0.373333rem;
  z-index: 999;
}