.introduce-swiper-pagination {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.introduce-swiper-pagination ul {
  width: 100%;
  height: auto;
  text-align: center;
}

.introduce-swiper-pagination li {
  list-style: none;
  display: inline-block;
  height: 0.266667rem;
  width: 0.266667rem;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 0.08rem;
}

.introduce-swiper-pagination li.selected {
  background-color: #536DFE;
}