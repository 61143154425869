.am-icon-xxs {
  width: 0.32rem;
  height: 0.32rem;
}

.am-stepper-handler {
  border: 0.026667rem solid #ffffff;
}

.am-stepper-input {
  width: 0.8rem;
  height: 100%;
}

.am-checkbox {
  width: 0.4rem;
  height: 0.4rem;
}

.am-checkbox-inner {
  border: 0.053333rem solid #666666;
  width: 0.4rem;
  height: 0.4rem;
}

.am-checkbox-inner:after {
  top: 0;
  right: 0.08rem;
  width: 0.133333rem;
  height: 0.213333rem;
}

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #1F87FF;
  background: #1F87FF;
}

.shopcart-header {
  width: 100%;
  height: 1.626667rem;
  background-color: #fff;
  padding: 0.24rem 0.373333rem 0 0.373333rem;
  position: relative;
}

.shopcart-header > p {
  font-size: 0.32rem;
  line-height: 0.4rem;
  color: #666666;
  z-index: 2;
}

.shopcart-header .shopcart-scan {
  z-index: 2;
}

.shopcart-header .shopcart-scan i {
  font-size: 0.533333rem;
  z-index: 2;
}

.shopcart-header > p {
  font-size: 0.32rem;
}

.shopcart-header .shopcart-header-classify {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.shopcart-header .shopcart-header-classify > div {
  width: 25%;
  height: 100%;
  font-size: 0.453333rem;
  color: #414141;
  text-align: center;
}

.shopcart-header .shopcart-header-classify .shopcart-choose-tab {
  height: 0.133333rem;
  position: absolute;
  border-bottom: 0.133333rem solid #1F87FF;
  -webkit-transform: scaleX(0.3);
  transform: scaleX(0.3);
  width: 25%;
  bottom: 0;
  transition: all 0.2s;
}

.cart-show-part {
  position: fixed;
  bottom: 2.64rem;
  top: 1.626667rem;
  left: 0;
  right: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.cart-show-part .cart-part {
  margin: 0.133333rem 0.133333rem;
  padding: 0.266667rem 0 0 0;
  background-color: #FFFFFF;
  border-radius: 0.133333rem;
}

.cart-show-part .cart-part .cart-drugstore {
  width: 100%;
}

.cart-show-part .cart-part .cart-drugstore .am-list-line::after {
  background-color: #FFFFFF !important;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity {
  padding-left: 1.2rem;
  width: 100%;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity .drugstore-manjian {
  margin-bottom: 0.4rem;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity .drugstore-manjian > div {
  width: 0.8rem;
  height: 0.346667rem;
  color: #FF5555;
  background: #FFE9E9;
  margin-right: 0.293333rem;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity .drugstore-manjian > span {
  color: #353A42;
  font-weight: bold;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity .drugstore-youhui {
  font-size: 0.346667rem;
  line-height: 0.8rem;
  padding-left: 0.466667rem;
  width: 9.2rem;
  height: 0.8rem;
  position: absolute;
  margin: auto;
  left: 0;
  top: 2.133333rem;
  right: 0;
  background-color: #FFEBDB;
}

.cart-show-part .cart-part .cart-drugstore .cart-drugstore-activity .drugstore-youhui span {
  margin-left: 0.133333rem;
  margin-right: 0.133333rem;
  color: #FF5555;
}

.cart-show-part .cart-part .am-list-item {
  width: 9.333333rem;
}

.cart-show-part .cart-part .other-tishi {
  padding-top: 0.133333rem;
  width: 9.733333rem;
}

.cart-show-part .cart-part .other-tishi .other-tishi-p1 {
  text-align: right;
  display: block;
  padding-right: 0.4rem;
}

.cart-show-part .cart-part .other-tishi .other-tishi-p1 span {
  margin-right: 0.133333rem;
}

.cart-show-part .cart-part .other-tishi > p {
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.466667rem;
  padding-right: 0.133333rem;
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #666666;
  display: flex;
  justify-content: space-between;
}

.cart-show-part .cart-part .other-tishi > p .momey {
  font-size: 0.453333rem;
  color: #353A42;
}

.cart-show-part .cart-part .cart-med {
  width: 100%;
  height: 2rem;
  margin: 0.4rem 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
}

.cart-show-part .cart-part .cart-med .cart-item-select {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.066667rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.cart-show-part .cart-part .cart-med .cart-med-info > img {
  width: 2rem;
  max-height: 2rem;
  margin-right: 0.293333rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail {
  width: 4.266667rem;
  height: 100%;
  justify-content: space-between;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail > p {
  color: #F34848;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail > div p {
  color: #354242;
  font-size: 0.346667rem;
  line-height: 0.4rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail > div p .cfy {
  width: 0.8rem;
  text-align: center;
  background: #1F87FF;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  display: inline-block;
  margin-right: 0.106667rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail > div p .cfy1 {
  width: 0.8rem;
  text-align: center;
  background: #1F87FF;
  opacity: 0.4;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  display: inline-block;
  margin-right: 0.106667rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail > div span {
  color: #666666;
  margin-top: 0.133333rem;
}

.cart-show-part .cart-part .cart-med .cart-med-info .cart-med-detail .med-name-cart {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.cart-show-part .cart-part .cart-med .changnum {
  position: absolute;
  right: 0.266667rem;
  bottom: 0;
}

.tishi-jiesuan {
  width: 9.2rem;
  height: 0.786667rem;
  line-height: 0.786667rem;
  margin-left: 0.4rem;
  background: #999;
  border-radius: 0.066667rem;
  position: fixed;
  z-index: 99;
  bottom: 2.933333rem;
  text-align: center;
  color: #FFFFFF;
}

.xiaosanjiao {
  position: fixed;
  z-index: 99;
  bottom: 2.4rem;
  width: 0;
  height: 0;
  border-width: 0.266667rem;
  margin-left: 7.466667rem;
  border-style: solid;
  border-color: #999 transparent transparent transparent;
}

.cart-sum {
  position: fixed;
  bottom: 1.306667rem;
  left: 0;
  right: 0;
  height: 1.333333rem;
  background-color: #fff;
}

.cart-sum .edit-cart {
  width: 45%;
  justify-content: flex-end;
  padding-right: 0.266667rem;
}

.cart-sum .edit-cart > div {
  padding: 0.213333rem 0.4rem;
  font-size: 0.373333rem;
  color: #F34848;
  border: 0.026667rem solid #F34848;
  border-radius: 0.426667rem;
}

.cart-sum .cart-to-order {
  width: 72%;
  height: 1.333333rem;
}

.cart-sum .cart-to-order .manjian-outer {
  display: flex;
  flex-direction: column;
}

.cart-sum .cart-to-order .manjian-outer .manjian-inner {
  display: flex;
  justify-content: space-around;
}

.cart-sum .cart-to-order .manjian-outer .manjian-bottom {
  text-align: center;
  margin-top: 0.066667rem;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.cart-sum .cart-to-order .cart-count {
  width: 4rem;
  height: 100%;
  background-color: #1F87FF;
  color: #FFFFFF;
  font-size: 0.373333rem !important;
}

.cart-shop {
  width: 100%;
  position: fixed;
  top: 1.866667rem;
  bottom: 0;
  overflow: scroll;
}

.cart-nodata {
  width: 100%;
  height: 10.8rem;
}

.P-body {
  width: 100%;
  height: 100%;
  padding: 0.266667rem;
  border-top-left-radius: 0.133333rem;
  border-top-right-radius: 0.133333rem;
}

.P-body .P-body-top {
  text-align: right;
}

.P-body .P-body-top img {
  width: 0.4rem;
  height: 0.4rem;
}

.P-body .P-body-title {
  text-align: center;
  font-size: 0.373333rem;
  font-weight: bolder;
}

.P-body .P-body-text {
  width: 100%;
  height: 1.866667rem;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1.6rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.013333rem solid #d1cccc;
}

.P-body .P-body-text div:first-child {
  line-height: 0.533333rem;
}

.P-body .P-body-text button {
  width: 1.6rem;
  height: 0.64rem;
  border: none;
  border-radius: 0.026667rem;
  color: white;
  background-image: linear-gradient(to right, #FF8E8E, #EB4B4B);
}

.P-body .P-body-tishi {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background-color: #FFEBDB;
}

.P-body .cart-sum {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.333333rem;
  background-color: #fff;
}

.P-body .cart-sum .edit-cart {
  width: 45%;
  justify-content: flex-end;
  padding-right: 0.266667rem;
}

.P-body .cart-sum .edit-cart > div {
  padding: 0.213333rem 0.4rem;
  font-size: 0.373333rem;
  color: #F34848;
  border: 0.026667rem solid #F34848;
  border-radius: 0.426667rem;
}

.P-body .cart-sum .cart-to-order {
  width: 72%;
  height: 1.333333rem;
}

.P-body .cart-sum .cart-to-order .cart-count {
  width: 4rem;
  height: 100%;
  background-color: #1F87FF;
  color: #FFFFFF;
  font-size: 0.373333rem !important;
}

.P-body2 {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.133333rem;
  border-top-right-radius: 0.133333rem;
}

.P-body2 .P-body2-top {
  padding: 0.266667rem;
  text-align: right;
}

.P-body2 .P-body2-top img {
  width: 0.4rem;
  height: 0.4rem;
}

.P-body2 .P-body2-title {
  text-align: center;
  font-size: 0.373333rem;
  font-weight: bolder;
  margin-bottom: 0.52rem;
}

.P-body2 .P-body2-name {
  width: 100%;
  height: 0.666667rem;
  background-color: #F5F5F5;
  line-height: 0.666667rem;
  padding-left: 0.4rem;
}

.P-body2 .P-body2-text {
  width: 100%;
}

.P-body2 .P-body2-text div {
  width: 100%;
  height: 1.066667rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  line-height: 1.066667rem;
}

.P-body2 .P-body2-text div .P-body2-text-left {
  float: left;
}

.P-body2 .P-body2-text div .P-body2-text-right {
  float: right;
}

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #F34848;
  background: #F34848;
}

.del-address {
  position: absolute;
  z-index: 9999;
  width: 6.666667rem;
  height: 3.466667rem;
  border: 0.013333rem solid #ddd;
  background-color: #fff;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.del-address .del-sure {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  text-align: center;
}

.del-address .del-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 0.013333rem solid #ddd;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.del-address .del-bottom > span {
  width: 50%;
  text-align: center;
  font-size: 0.373333rem;
  color: #1F87FF;
}

.del-address .del-bottom > span:first-child {
  border-right: 0.013333rem solid #ddd;
  color: #000;
}

.manjian-outer {
  width: 34vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.manjian-outer .manjian {
  margin-right: 0.133333rem;
}