.inquiry-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  background-color: #EEF1F5;
  overflow: scroll;
}

.inquiry-search {
  width: 100%;
  height: 3.253333rem;
  background: #1f87ff;
  color: #FFFFFF;
}

.inquiry-search > p {
  font-size: 0.453333rem;
  font-weight: bold;
  margin-bottom: 0.586667rem;
}

.inquiry-choose {
  width: 100%;
  height: 2.8rem;
  padding: 0.4rem;
}

.inquiry-choose div:nth-child(1) {
  margin-right: 0.266667rem;
}

.inquiry-choose .inquiry-way {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}

.inquiry-choose .inquiry-way img {
  width: 1.333333rem;
  margin-right: 0.426667rem;
}

.inquiry-choose .inquiry-way div :first-child {
  font-size: 0.48rem !important;
  font-weight: bold;
  line-height: 0.8rem;
}

.inquiry-choose .inquiry-way div :last-child {
  font-size: 0.346667rem !important;
  color: #C2C5CB;
}

.doctor-recommend {
  width: 100%;
  background: #FFFFFF;
  margin-bottom: 0.266667rem;
}

.doctor-recommend > p {
  color: #666666;
  font-size: 0.373333rem;
  line-height: 1.2rem;
  margin-left: 0.4rem;
  font-weight: bold;
}

.doctor-recommend .doctor-recommend-tabs {
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding-left: 0.426667rem;
}