.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.extend-click {
  position: relative;
}

.extend-click:before {
  content: '' !important;
  position: absolute;
  top: -0.133333rem;
  left: -0.133333rem;
  right: -0.133333rem;
  bottom: -0.133333rem;
}

.clearfix {
  display: inline-block;
}

.clearfix:after {
  display: block;
  content: '' !important;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}

.position-full {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #FFFFFF;
}

.list-wrapper .scroll-content {
  position: relative;
  z-index: 10;
}

.list-wrapper .pullup-wrapper {
  width: 100%;
  padding-top: 0.533333rem;
  padding-bottom: 0.533333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-wrapper .after-trigger,
.list-wrapper .before-trigger,
.list-wrapper .refresh-txt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-wrapper .after-trigger span,
.list-wrapper .before-trigger span,
.list-wrapper .refresh-txt span {
  padding: 0.133333rem 0;
  font-size: 0.373333rem;
  font-weight: 300;
  color: #666;
}

.list-wrapper .pulldown-wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;
}