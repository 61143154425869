.wechat-list .wechatlist-line {
  width: 100%;
  height: 0.026667rem;
  background: #EEF1F5;
}

.wechat-list .wechat-card {
  width: 100%;
  padding: 0.48rem 0.32rem 0 0.32rem;
  color: #656565;
  background-color: #FFFFFF;
}

.wechat-list .wechat-card .wechat-card-detail {
  width: 100%;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name {
  width: 100%;
  height: 1.066667rem;
  display: flex;
  align-items: center;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name .wechat-head {
  flex: 0 1 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name .wechat-person {
  flex: 1 1 auto;
  margin-left: 0.266667rem;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name .wechat-person > p {
  color: #353A42;
  font-size: 0.346667rem;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name .wechat-person > span {
  color: #C2C5CB;
  font-size: 0.266667rem;
  margin-top: 0.133333rem;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-name .wechat-follow {
  width: 1.44rem;
  line-height: 0.506667rem;
  font-size: 0.346667rem;
  text-align: center;
  border: 0.013333rem solid #1F87FF;
  border-radius: 0.386667rem;
  color: #1F87FF;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-brief {
  margin-top: 0.186667rem;
  width: 100%;
  line-height: 0.48rem;
  color: #353A42;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-tag {
  margin-top: 0.186667rem;
  width: 100%;
  line-height: 0.48rem;
  color: #1F87FF;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-img {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-img img {
  width: 2.933333rem;
  margin-top: 0.266667rem;
}

.wechat-list .wechat-card .wechat-card-detail .wechat-img::after {
  content: '';
  width: 100%;
  border: 0.013333rem solid transparent;
}

.wechat-list .wechat-card .wechat-icon {
  width: 100%;
  height: 1.066667rem;
  padding: 0 2.4rem;
  color: #C2C5CB;
}

.wechat-list .wechat-card .wechat-icon i {
  font-size: 0.373333rem;
}