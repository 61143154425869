.searchhis {
  padding-left: 0.4rem;
}

.searchhis span {
  display: inline-block;
  padding: 0.066667rem 0.133333rem;
  border: 0.013333rem solid #e4e1e1;
  border-radius: 0.066667rem;
  margin-right: 0.133333rem;
  margin-bottom: 0.133333rem;
}