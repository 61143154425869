.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}

.wrapper.list-fade-enter {
  opacity: 0;
}

.wrapper.list-fade-enter-active {
  opacity: 1;
  transition: all 0.3s;
}

.wrapper.list-fade-exit {
  opacity: 1;
}

.wrapper.list-fade-exit-active {
  opacity: 0;
  transition: all 0.3s;
}

.wrapper .list_wrapper {
  position: absolute;
  left: 0.426667rem;
  right: 0.426667rem;
  bottom: 0;
  opacity: 1;
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: #fff;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.wrapper .list_wrapper .list_close {
  text-align: center;
  line-height: 1.333333rem;
  background: #f2f3f4;
  font-size: 0.426667rem;
  color: #2E3030;
}

.wrapper .list_wrapper .zhanwei {
  width: 100%;
  min-height: 5.76rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}