.zbox {
  width: 400pt;
  height: 720pt;
  box-sizing: border-box;
  border: 0.04rem solid black;
  font-weight: bolder;
  margin: 0 auto;
}

.zbox > div {
  border-bottom: 0.013333rem solid black;
  box-sizing: border-box;
}

.zbox .zbox-top {
  height: 36pt;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0.133333rem;
  padding-right: 0.266667rem;
  justify-content: space-between;
  align-items: center;
}

.zbox .zbox-top img {
  height: 30pt;
}

.zbox .zbox-top h2 {
  margin: 0;
  padding: 0;
  font-size: 15pt;
  font-weight: bolder;
}

.zbox .zbox-num {
  height: 60pt;
  font-size: 50pt;
  text-align: center;
}

.zbox .zbox-title {
  height: 40pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.zbox .zbox-title div:first-child {
  font-weight: bolder;
  font-size: 20pt;
  line-height: 40pt;
  width: 80%;
  border-right: 0.013333rem solid black;
  padding-left: 0.266667rem;
}

.zbox .zbox-title div:last-child {
  width: 20%;
  text-align: center;
  font-weight: bolder;
}

.zbox .zbox-shou {
  height: 72pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.zbox .zbox-shou div:first-child {
  width: 10%;
  height: 100%;
  text-align: center;
  padding-top: 15pt;
  line-height: 20pt;
  border-right: 0.013333rem solid black;
}

.zbox .zbox-shou div:last-child {
  width: 90%;
  height: 100%;
  text-align: left;
  padding: 1%;
}

.zbox .zbox-ji {
  height: 48pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.zbox .zbox-ji div:first-child {
  width: 10%;
  height: 100%;
  text-align: center;
  padding-top: 4pt;
  line-height: 20pt;
  border-right: 0.013333rem solid black;
}

.zbox .zbox-ji div:last-child {
  width: 90%;
  height: 100%;
  text-align: left;
  padding: 1%;
}

.zbox .zbox-ma1 {
  height: 104pt;
}

.zbox .zbox-ma1 .box-ma1-top {
  text-align: center;
  padding-top: 0.133333rem;
  margin-bottom: 0.133333rem;
}

.zbox .zbox-ma1 p {
  text-align: center;
  font-size: 20pt;
  letter-spacing: 0.266667rem;
}

.zbox .zbox-qian {
  height: 76pt;
  border-bottom: 0.04rem solid black;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.zbox .zbox-qian div:first-child {
  width: 40%;
  padding: 8pt;
  height: 100%;
  font-size: 12pt;
  border-right: 0.013333rem solid black;
}

.zbox .zbox-qian div:last-child {
  width: 60%;
  padding: 8pt;
  height: 100%;
}

.zbox .zbox-qian div:last-child p:first-child {
  margin-bottom: 30pt;
}

.zbox .zbox-qian div:last-child p:last-child .qian-span {
  float: right;
}

.zbox .zbox-ma2 {
  height: 56pt;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.zbox .zbox-ma2 .zbox-ma2-left {
  width: 40%;
  height: 100%;
  padding-top: 8pt;
  padding-left: 0.266667rem;
}

.zbox .zbox-ma2 .zbox-ma2-left img {
  height: 40pt;
}

.zbox .zbox-ma2 .zbox-ma2-right {
  padding: 0.133333rem;
}

.zbox .zbox-ma2 .zbox-ma2-right p {
  letter-spacing: 0.133333rem;
  text-align: center;
}

.zbox .zbox-bottom {
  height: 104pt;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.zbox .zbox-bottom .zbox-bottom-left {
  width: 10%;
  height: 100%;
  border-right: 0.013333rem solid black;
  text-align: center;
  line-height: 40pt;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  letter-spacing: 0.266667rem;
}

.zbox .zbox-bottom .zbox-bottom-right {
  width: 90%;
  height: 100%;
  padding: 0.133333rem;
  line-height: 25pt;
}