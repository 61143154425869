#home-header2 {
  width: 100%;
  height: 4.613333rem;
  position: relative;
  padding: 0.4rem 0.4rem 0.266667rem 0.4rem;
  display: flex;
  flex-wrap: wrap;
  color: #FFFFFF;
}

.top-title {
  font-size: 0.506667rem;
  color: #FFFFFF;
}

.top-swiper {
  width: 100%;
  height: 2.666667rem;
  background-color: bisque;
}

.home-header-left {
  width: 2.133333rem;
}

.home-header-left :first-child {
  font-size: 0.373333rem !important;
}

.home-header-middle2 {
  width: 5.6rem;
  position: absolute;
  z-index: 5;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -0.666667rem;
}

.home-header-middle2 .search-container {
  background-color: #FFFFFF;
  border-radius: 0.426667rem;
  overflow: hidden;
  padding: 0.133333rem;
}

.home-header-middle2 .search-container i {
  font-size: 0.533333rem!important;
  color: #C2C5CB !important;
}

.home-header-middle2 .search-container input {
  width: 100%;
  font-size: 0.346667rem !important;
  font-weight: normal;
  border: none;
}

.home-header-right {
  width: 2.133333rem;
  height: 1.066667rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.home-header-right i {
  font-size: 0.533333rem !important;
  margin-left: 0.373333rem;
  color: #FFFFFF;
}