#container {
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 1.733333rem;
  height: 8rem;
  z-index: 5;
}

#myPage {
  position: absolute;
  width: 100%;
  z-index: 8;
  top: 2.133333rem;
  text-align: center;
  left: 0;
}

#myPage input {
  height: 1.173333rem;
  width: 80%;
  border-radius: 0.586667rem;
  border: none;
  background-image: url("../../static/img/icon/search2.png");
  background-repeat: no-repeat;
  background-size: 0.4rem;
  background-position: 0.4rem center;
  padding-left: 1.066667rem;
}