.bulletinboard-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  height: 1.706667rem;
  background-color: #FFFFFF;
  border-bottom: 0.013333rem solid #F3F3F3;
}

.bulletinboard-top img {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.133333rem;
}

.bulletinboard-top h2 {
  font-size: 0.48rem;
  width: 90%;
  text-align: center;
  margin-left: -0.133333rem;
}

.bulletinboard-body {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  border-top: 0.026667rem solid #E5E5E5;
}

.bulletinboard-body > div {
  height: 1.333333rem;
  line-height: 1.333333rem;
  border-bottom: 0.026667rem solid #E5E5E5;
  padding: 0 0.4rem;
  display: flex;
  justify-content: space-between;
}

.bulletinboard-body > div > img {
  height: 0.266667rem;
  margin-top: 0.533333rem;
}

.bulletinboard-body > div > span {
  font-size: 0.373333rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  width: 85vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}