.med-list {
  box-sizing: border-box;
  width: 80vw;
}

.med-list .med-card {
  width: 100%;
  padding: 0.293333rem 0.466667rem 0.24rem 0.4rem;
  background: #FFFFFF;
}

.med-list .med-card .med-img {
  width: 3.106667rem;
  height: 3.106667rem;
  overflow: hidden;
  position: relative;
}

.med-list .med-card .med-img > img {
  width: 3.106667rem;
  margin-top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  overflow: hidden;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
}

.med-list .med-card .med-img .shouqing {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #00B7F5;
}

.med-list .med-card .med-card-detail {
  width: 100%;
  padding-left: 0.266667rem;
}

.med-list .med-card .med-card-detail .med-name {
  color: #666666;
}

.med-list .med-card .med-card-detail .med-name span {
  font-size: 0.373333rem;
}

.med-list .med-card .med-card-detail .med-name .recipel-tag {
  font-size: 0.32rem;
  padding: 0 0.133333rem;
  line-height: 0.48rem;
  text-align: center;
  border-radius: 0.24rem;
  background: #00B7F5;
  color: #FFFFFF;
  margin-right: 0.133333rem;
}

.med-list .med-card .med-card-detail .med-mark {
  color: #818D8D;
  font-size: 0.32rem;
  line-height: 0.64rem;
}

.med-list .med-card .med-card-detail .med-mark1 {
  color: #818D8D;
  font-size: 0.32rem;
  line-height: 0.64rem;
  margin-bottom: 0.266667rem;
}

.med-list .med-card .med-card-detail .med-tag span {
  padding: 0 0.053333rem;
}

.med-list .med-card .med-card-detail .meditem-price {
  align-items: center;
  height: 0.64rem;
}

.med-list .med-card .med-card-detail .meditem-price > span {
  color: #F34848;
  font-size: 0.373333rem;
  margin-right: 0.133333rem;
}

.med-list .med-card .med-card-detail .meditem-data {
  padding-top: 0.066667rem;
  padding-bottom: 0.066667rem;
  padding-right: 0.48rem;
  align-items: center;
  white-space: nowrap;
  color: #B8B8B8;
  position: relative;
}

.med-list .med-card .med-card-detail .meditem-data * {
  font-size: 0.266667rem;
}

.med-list .med-card .med-card-detail .meditem-data .meditem-data-img {
  width: 0.8rem;
  position: absolute;
  right: 0;
}

.med-list .med-card .med-card-detail .meditem-data p {
  margin-right: 0.266667rem;
}

.med-list .med-card .med-card-detail .meditem-data .meditem-pharmacy-name {
  display: inline-block;
  width: 5.333333rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.med-list .med-card .med-card-detail .meditem-data .icon-xiangyou {
  font-size: 0.32rem;
}