.showmed-area {
  width: 100%;
  overflow: hidden;
  justify-content: space-around;
}

.showmed-area .meditem {
  margin-top: 0.333333rem;
  width: 33%;
  height: 100%;
}

.showmed-area .meditem > div {
  width: 2.666667rem;
  height: 2.8rem;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0.266667rem;
}

.showmed-area .meditem > div img {
  width: 100%;
}

.showmed-area .meditem > p {
  min-height: 0.8rem;
  color: #818D8D;
  font-size: 0.346667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0.133333rem;
}

.showmed-area .meditem > span {
  text-decoration: line-through;
  color: #C2C5CB;
  font-size: 0.32rem;
}

.showmed-area .meditem > b {
  color: #F34848;
  font-size: 0.373333rem;
}