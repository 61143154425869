.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data img {
  width: 50%;
}

.no-data p {
  text-align: center;
  font-size: 0.373333rem;
  color: #666666;
  margin: 0.72rem 0 0.4rem 0;
}

.no-data div {
  width: 2.613333rem;
  height: 0.773333rem;
  background: #1f87ff;
  border-radius: 0.4rem;
  color: #FFFFFF;
  font-size: 0.373333rem;
}