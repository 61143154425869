.disease-classify-body {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 1.333333rem;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
}

.disease-classify-body .menu {
  flex: 0 0 2.613333rem;
  width: 2.613333rem;
  background-color: #EEF1F5;
  position: relative;
}

.disease-classify-body .menu .menu-scroll {
  background-color: transparent;
}

.disease-classify-body .menu .menu-scroll .item {
  display: flex;
  box-sizing: border-box;
  height: 1.28rem;
  padding: 0 0.133333rem 0 0.346667rem;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.disease-classify-body .menu .menu-scroll .item.active {
  background-color: #fff;
  color: #1F87FF;
  position: relative;
}

.disease-classify-body .menu .menu-scroll .item.active::before {
  content: '' !important;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.106667rem;
  height: 0.533333rem;
  background-color: #1F87FF;
}

.disease-classify-body .menu .menu-scroll .item span {
  font-size: 0.426667rem;
  font-weight: bold;
}

.disease-classify-body .menu .menu-scroll .item .count {
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.disease-classify-body .detail {
  flex: 1 1;
  width: 0;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.disease-classify-body .detail .tabs {
  width: 100%;
  padding: 0 0.666667rem 0 0.4rem;
  font-size: 0.32rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.disease-classify-body .detail .tabs div {
  margin-bottom: 0.266667rem;
}

.disease-classify-body .detail .total {
  color: #C2C5CB;
  font-size: 0.32rem;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  padding-left: 0.4rem;
}

.disease-classify-body .detail .item {
  display: flex;
  margin: 0 0.4rem 0 0.4rem;
  padding: 0 0.4rem 0 0.4rem;
  border-bottom: solid 0.013333rem #EEF1F5;
  height: 1.226667rem;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.disease-classify-body .detail .item .img {
  flex: 0 0 1.973333rem;
  height: 1.973333rem;
  margin-right: 0.533333rem;
}

.disease-classify-body .detail .item .img img {
  width: 1.973333rem;
  height: 1.973333rem;
  content: none !important;
}

.disease-classify-body .detail .item .title {
  flex: 1 1;
  font-size: 0.373333rem;
  color: #333;
}

.disease-classify-body .detail .item .count {
  flex: 0 0 1.6rem;
  width: 1.6rem;
  text-align: right;
  font-size: 0.32rem;
  color: #666;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.disease-classify-body .detail .item.active .title,
.disease-classify-body .detail .item.active .count {
  color: #1F87FF;
}

.disease-classify-body .other-use {
  width: 100%;
  padding: 0 0.266667rem;
}

.disease-classify-body .other-use ul {
  height: 1.333333rem;
  padding-top: 0.533333rem;
}

.disease-classify-body .other-use ul li {
  float: left;
  text-align: center;
  width: 25%;
  height: 2.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disease-classify-body .other-use ul li img {
  width: 1.173333rem;
  margin-bottom: 0.213333rem;
}

.disease-classify-body .other-use ul li span {
  font-size: 0.373333rem;
}