.inquiry-list-note {
  line-height: 0.986667rem;
  font-size: 0.293333rem;
  padding-left: 0.4rem;
  color: #656565;
}

.inquiry-list-item {
  width: 100%;
  background: #FFFFFF;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.inquiry-list-item span {
  color: #414141;
  font-size: 0.4rem;
}

.inquiry-list-item input {
  border: none;
  width: 2.133333rem;
}

.inquiry-list-item input::-webkit-input-placeholder {
  color: #C1C1C1;
  text-align: right;
}

.re-reserve {
  font-size: 0.346667rem;
  color: #1F87FF !important;
}

.inquiry-selecttime {
  display: flex;
  flex-direction: column;
}

.inquiry-selecttime p {
  color: #656565;
  font-size: 0.293333rem;
}

.inquiry-inputlist2 {
  width: 100%;
  height: 7.706667rem;
  padding: 0 0.4rem 0 0.48rem;
  margin-top: 0.266667rem;
  background: #FFFFFF;
}

.inquiry-inputlist2 span {
  color: #414141;
  font-size: 0.4rem;
}

.inquiry-inputlist2 > div:nth-child(1) {
  height: 3.68rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inquiry-inputlist2 > div:nth-child(1) p {
  position: absolute;
  bottom: 0.533333rem;
  right: 0.533333rem;
  color: #A1A5B3;
  font-size: 0.32rem;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.inquiry-inputlist2 > div:nth-child(1) > span {
  line-height: 1.333333rem;
}

.inquiry-inputlist2 > div:nth-child(1) .disease-text {
  height: 1.92rem;
  resize: none;
  border: none;
  background-color: #EEF1F5;
  padding: 0.24rem;
  line-height: 0.48rem;
}

.inquiry-inputlist2 > div:nth-child(3) {
  height: auto;
}

.inquiry-inputlist2 > div:nth-child(3) > div:first-child {
  line-height: 1.173333rem;
  display: flex;
  flex-direction: row;
}

.inquiry-inputlist2 > div:nth-child(3) > div:first-child > span {
  line-height: 1.173333rem;
}

.inquiry-inputlist2 > div:nth-child(3) > div:first-child > span:nth-child(2) {
  display: block;
  font-size: 0.32rem;
  margin-left: 0.266667rem;
}

.inquiry-inputlist2 > div:nth-child(3) .upload-img {
  width: 1.92rem;
  height: 1.92rem;
  background-color: #EEF1F5;
}

.inquiry-inputlist2 > div:nth-child(3) .upload-img span {
  color: white;
  font-size: 1.066667rem;
  line-height: 1.066667rem;
}

.inquiry-inputlist2 > div:nth-child(3) .upload-img i {
  color: #414141;
  font-size: 0.8rem;
}