.seckill-screen-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.68rem;
  bottom: 0;
  overflow: scroll;
}

.seckill-content {
  padding: 0.746667rem 0 0;
  height: 100%;
  box-sizing: border-box;
}

.seckill-content .seckill-timetab {
  width: 100%;
  padding: 0.2rem 0;
  position: absolute;
  left: 0;
  top: 0;
  background: #EEF1F5;
}

.seckill-content .seckill-timetab .seckill-timetab-item {
  width: 2.4rem;
  height: 100%;
}

.seckill-content .seckill-timetab .seckill-timetab-item p:first-child {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.4rem;
}

.seckill-content .seckill-timetab .seckill-timetab-item p:last-child {
  font-size: 0.32rem;
  line-height: 0.4rem;
}

.seckill-content .seckill-timetab .activetime {
  color: #1F87FF;
}

.seckill-content .seckill-timetab .waittime {
  color: #5F6062;
}

.seckill-content .seckill-list {
  width: 100%;
  height: 100%;
  padding: 0.4rem;
  overflow: scroll;
}

.seckill-content .seckill-list .menu-scroll {
  overflow-y: auto;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail {
  width: 100%;
  height: 2.88rem;
  background: #FFFFFF;
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
  position: relative;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail > img {
  width: 2.613333rem;
  height: 2.613333rem;
  margin-left: 0.266667rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail {
  width: 100%;
  height: 100%;
  padding: 0.133333rem 0.266667rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .med-name {
  color: #666666;
  font-size: 0.32rem;
  line-height: 0.48rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .med-mark {
  color: #C2C5CB;
  font-size: 0.32rem;
  line-height: 0.64rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .med-tag > div {
  padding: 0.026667rem;
  background: #1F87FF;
  border-radius: 0.026667rem;
  font-size: 0.32rem;
  color: white;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .meditem-price {
  align-items: center;
  height: 0.64rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .meditem-price > span {
  color: #F34848;
  font-size: 0.373333rem;
  margin-right: 0.133333rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .med-card-detail .meditem-price > s {
  color: #C2C5CB;
  font-size: 0.32rem;
  margin-right: 0.133333rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .buyseckill {
  width: 1.546667rem;
  height: 0.96rem;
  background: #1F87FF;
  border-radius: 0.133333rem;
  position: absolute;
  bottom: 0.266667rem;
  right: 0.266667rem;
  color: #FFFFFF;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .buyseckill > span {
  font-size: 0.346667rem;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .buyseckill .progress {
  width: 0.666667rem;
  height: 0.053333rem;
  border-radius: 0.026667rem;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
}

.seckill-content .seckill-list .menu-scroll .seckill-list-detail .buyseckill .progress .progress-bar {
  height: 0.053333rem;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}