.Announcementdetails-top {
  margin-bottom: 0.133333rem;
}

.Announcementdetails-top img {
  width: 0.8rem;
  height: 0.866667rem;
  margin-left: 0.266667rem;
  margin-top: 0.266667rem;
}

.Announcementdetails-outer {
  width: 100vw;
}

.Announcementdetails-outer .pdf-top {
  height: 84vh !important;
  width: 100% !important;
  height: auto;
}