.controlInvoices-top {
  width: 100%;
  height: 1.613333rem;
  text-align: center;
  line-height: 1.613333rem;
  font-size: 0.453333rem;
  position: relative;
  border-bottom: 0.026667rem solid #EEF1F5;
  background-color: white;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.controlInvoices-top img {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  left: 0.266667rem;
  margin: auto;
  top: 0;
  bottom: 0;
}

.myheaad-invoices {
  background-color: #fff;
  overflow: scroll;
  padding: 0.133333rem 0.4rem;
  height: 85vh;
}

.myheaad-invoices > p:first-child {
  display: flex;
  justify-content: start;
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0.4rem;
  color: #414141;
}

.myheaad-invoices > p:first-child > span {
  font-size: 0.533333rem;
  font-weight: bold;
  color: #666;
  margin-top: -0.04rem;
  margin-right: 0.133333rem;
}

.myheaad-invoices > p {
  display: flex;
  justify-content: space-between;
  padding: 0.333333rem 0;
  border-bottom: 0.026667rem solid #E5E5E5;
}

.myheaad-invoices > p .myheaad-invoices-user > span:first-child {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #414141;
  margin-right: 0.133333rem;
}

.myheaad-invoices > p .myheaad-invoices-user .myheaad-invoices-user-moren {
  -webkit-transform: translate(0.7);
  transform: translate(0.7);
  font-family: Source Han Sans CN;
  color: #1F87FF;
  border: 0.013333rem solid #2086FE;
  padding: 0 0.066667rem;
}

.myheaad-invoices > p > span {
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1F87FF;
}

.invoices-jilu {
  background-color: #fff;
  overflow: scroll;
  padding: 0.133333rem 0.4rem;
  height: 85vh;
}

.invoices-jilu .invoices-jilu-inner {
  padding: 0.266667rem 0;
  border-bottom: 0.026667rem solid #e1e1e1;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-inner-top {
  display: flex;
  justify-content: space-between;
  font-size: 0.346667rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #414141;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-inner-goods {
  display: flex;
  justify-content: space-between;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-inner-goods > p:first-child {
  height: 1.6rem;
  margin: 0.266667rem 0.066667rem;
  overflow: hidden;
  width: 75vw;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-inner-goods > p:first-child > img {
  height: 100%;
  width: auto;
  margin-right: 0.133333rem;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-inner-goods > p:last-child {
  line-height: 1.866667rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-putong {
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0.266667rem;
}

.invoices-jilu .invoices-jilu-inner .invoices-jilu-price {
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}