.stepbutton-row {
  position: fixed;
  width: 100%;
  height: 1.28rem;
  line-height: 1.28rem;
  bottom: 0;
  left: 0;
  color: #414141;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  overflow: visible;
  padding-left: 0.133333rem;
}

.stepbutton-row span {
  font-size: 0.4rem;
  padding-left: 0.613333rem;
}

.stepbutton-row .step-maincolor-button {
  width: 3.76rem;
  height: 1.333333rem;
  background: #1F87FF;
  color: #FFFFFF;
  font-size: 0.426667rem;
  border: none;
}