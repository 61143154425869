.ele-outer {
  margin-top: 0.266667rem;
  background-color: #fff;
  padding: 0.266667rem 0.4rem;
}

.ele-outer .ele-top {
  display: flex;
  height: 0.666667rem;
  margin-top: 0.133333rem;
}

.ele-outer .ele-top > h2 {
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.ele-outer .ele-top .ele-s {
  width: 0.04rem;
  height: 0.4rem;
  background: #999999;
  opacity: 1;
  border-radius: 0;
  margin: 0 0.133333rem;
}

.ele-outer .ele-top .ele-time {
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #999999;
}

.ele-outer .ele-pic {
  display: flex;
  flex-wrap: wrap;
  width: 9.2rem;
}

.ele-outer .ele-pic .ele-img {
  display: flex;
  flex-direction: column;
  margin-right: 0.4rem;
  width: 1.333333rem;
  height: 1.333333rem;
}

.ele-outer .ele-pic .ele-img > img {
  width: 1.333333rem;
  height: 0.933333rem;
}

.ele-outer .ele-pic .ele-img > span {
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  color: #414141;
  text-align: right;
  margin-top: 0.066667rem;
}

.ele-outer .ele-sj {
  font-size: 0.293333rem;
  font-family: MicrosoftYaHei;
  line-height: 0.8rem;
  color: #333333;
}

.ele-outer .ele-xian {
  width: 8.933333rem;
  height: 0;
  border: 0.026667rem solid #E5E5E5;
  margin: 0.266667rem 0;
}

.ele-outer .ele-bottom {
  height: 0.8rem;
}

.ele-outer .ele-bottom .ele-ck {
  width: 2.4rem;
  height: 0.64rem;
  background: linear-gradient(133deg, #FF8E8E 0%, #EB4B4B 100%);
  border-radius: 0.066667rem;
  text-align: center;
  line-height: 0.64rem;
  font-size: 0.32rem;
  font-family: MicrosoftYaHei;
  color: #fff;
  margin-left: 6.533333rem;
}

.ele-outer .invoice-outer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  position: fixed;
  top: 0;
  margin-left: -0.4rem;
}

.ele-outer .invoice-outer .close {
  width: 0.533333rem;
  margin-left: 8.933333rem;
  margin-top: 0.4rem;
}

.ele-outer .invoice-outer .pic {
  width: 8rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}